
import React,{useState } from 'react';
import { Link, withRouter } from 'react-router-dom'

import { sidebarMenuItems } from '../common/menuItems';
import { getTranslatedText } from '../common/utilities';
import DraggableList from "react-draggable-lists";
import Sortable from "./Sortable";
import { useEffect } from 'react';


const listItems = [
    "Entertainment",
    "Private Time",
    "Rest",
    "Meal",
    "Exercise",
    "Work",
    "Home Projects",
    "Family"
  ];
  
function Aside(props) {
    
    let menu =  localStorage.getItem('menuseq');
    let  initialMenu = localStorage.getItem('menuseq')?JSON.parse(localStorage.getItem('menuseq')):sidebarMenuItems.map(item=>{return item.title})
    const defSeq = sidebarMenuItems.map(item=>{return item.identity});
    console.log("defSeq",defSeq)
    
     let userDetails = localStorage.getItem('userLoginDetails')?JSON.parse(localStorage.getItem('userLoginDetails')):[]
    const [ids, setIds] = useState([]) ;
    const license = props.license


    useEffect(()=>{
          
        //   let dashboard = props.permission.webapp_health_management_page?props.permission.webapp_health_management_page:"False"
        let removeMenu =[];
          let dashboard = props.pages.filter(el=>el.page_name == "Health Management" ).length> 0?"True":"False"
          let site = props.pages.filter(el=>el.page_name == "Site Management" ).length> 0?"True":"False"
          let user = props.pages.filter(el=>el.page_name == "Manpower Management" ).length> 0?"True":"False"
          let cycle = props.pages.filter(el=>el.page_name == "Cycle Count" ).length> 0?"True":"False"
          let policy = props.pages.filter(el=>el.page_name == "Policy Compliance" ).length> 0?"True":"False"
          let visitor = props.pages.filter(el=>el.page_name == "visitor management" ).length> 0?"True":"False"                               
          let productive = props.pages.filter(el=>el.page_name == "Productivity Management" ).length> 0?"True":"False"                               
          let dashboardOverview = props.pages.filter(el=>el.page_name == "Dashboard" ).length> 0?"True":"False"
          let geomap = props.pages.filter(el=>el.page_name == "Geo Map Management" ).length> 0?"True":"False"
          let report = props.pages.filter(el=>el.page_name == "Report" ).length> 0?"True":"False"
          let cost = props.pages.filter(el=>el.page_name == "Cost Management" ).length> 0?"True":"False"
          let device = props.pages.filter(el=>el.page_name == "Device Management" ).length> 0?"True":"False"
          let manpowerplanining = props.pages.filter(el=>el.page_name == "Manpower Planning" ).length> 0?"True":"False"
          let facilityMap = props.pages.filter(el=>el.page_name=="Facility Map").length> 0?"True":"False"
          let shift = props.pages.filter(el=>el.page_name=="Shift Management").length> 0?"True":"False"
          let pleton =props.pages.filter(el=>el.page_name=="Facility Map Pleton").length> 0?"True":"False" 
          let geoMapv2 =props.pages.filter(el=>el.sub_page_name=="Employee Tracker Mapbox").length> 0?"True":"False" 
          let facilityMapBox =props.pages.filter(el=>el.page_name=="Facility Mapbox").length> 0?"True":"False" 
          console.log("initialMenu props.pages",initialMenu,props.pages,dashboard);
          

          for (let i = 0; i < defSeq.length; i++) {
            
            if( 
            (defSeq[i] =='Dashboard' && dashboardOverview =="True") ||
            (defSeq[i] =='Health Management' && dashboard =="True") ||
            (defSeq[i] =='Manpower Management' && user =="True") ||
            (defSeq[i] =='Site Management' && site =="True") ||
            (defSeq[i] =='Policy Management' && policy =="True") ||
            (defSeq[i] =='Visitor Management' && visitor =="True") ||
            (defSeq[i] =='Productivity Management' && productive =="True") ||
            (defSeq[i] =='Geo Map Management' && geomap =="True") ||
            (defSeq[i] =='Reports' && report =="True") ||
            (defSeq[i] =='Cycle Count' && cycle=='True' ) ||
            (defSeq[i] =='Device Management' && device=='True' ) ||
            (defSeq[i] =='Cost Management' && cost=='True' ) ||
            (defSeq[i] =='Facility Map' && facilityMap=='True' )  ||
            (defSeq[i] =='Manpower Planning' && manpowerplanining=='True' ) ||
            (defSeq[i] =='Shift Management' && shift=='True' ) ||
            (defSeq[i] =='Geo Map Outdoor' && geoMapv2=='True' ) ||
            (defSeq[i] =='Facility Map Pleton' && pleton=='True' ) ||
            (defSeq[i] =='Facility Geo Map' && facilityMapBox =='True' )
            
            ){
            console.log("page condition satisfy",defSeq[i])
              let reqMenu =   initialMenu.indexOf(defSeq[i])


            if(reqMenu == -1){
                    initialMenu.push(defSeq[i]);    
    
                }
            }  else{
                console.log("page condition not satisfy",defSeq[i],(defSeq[i] =='Health Management' && dashboard =="True"))                
                removeMenu.push(defSeq[i]);
                let nonreqMenu =   initialMenu.indexOf(defSeq[i])
                if(defSeq[i] ==='Geo Map Management'){
                    console.log("is it splice",initialMenu,nonreqMenu)
                }
                if(nonreqMenu > -1){
                    initialMenu.splice(nonreqMenu, 1)


                }
            }             
          }
          
         const finalMenu =    initialMenu.filter(item => !removeMenu.includes(item));
          
          const uniqueArray = [...new Set(finalMenu)];
console.log("page condition initialMenu",initialMenu,uniqueArray,removeMenu,finalMenu)

          setIds(uniqueArray)
  

    },[props.permission,props.pages])


    const handleSort = (ids) => {
      
        localStorage.setItem('menuseq',JSON.stringify(ids));
        setIds(ids);
    };
  
    const items = {
      8: "Hello 8",
      3: "Hello 3",
      4: "Hello 4",
      5: "Hello 5",
      6: "Hello 6"
    };
    const  getActiveTab = (eachMenuItem) => {
        let className = ''
        console.log("props.location.pathname",props.location.pathname)
        if(props.location.pathname.includes("/facility-map")){
            if(props.location.pathname === eachMenuItem.paths){
                className = 'activeTab'
            }
        }
        else if (props.location.pathname.includes(eachMenuItem.paths)  ) {
            className = 'activeTab'
        }

        return className
    }


    

        return (
            <aside>

                <ul style={{ width: 200, margin: "0 auto" }}>
                {/* <DraggableList width={200} height={50} rowSize={1} className="ov-hidden">
                            {this.showMenuItems()}
          </DraggableList>
                     */}

                            <Sortable
                                wrapperElement="tr"
                                ids={ids}
                                items={sidebarMenuItems}
                                onSort={handleSort}
                                renderActive={(item, id, index) => <div>{item.title}</div>}
                                >
                                {ids?.map((id,index) => {
                                    let element = sidebarMenuItems.find(item=> item.identity=== id);
                                    let arr;
                                    if( element  ) {
                                        let content = null
                        
                                        if(element.status=='active'){
                                        content = <Link to={element.paths} onClick={()=>props.closeMenu()} >
                                            <img src={getActiveTab(element) !== '' ? element.activeIcon : element.defaultIcon} alt="" title={element.title} />
                                            <span className={getActiveTab(element)}>
                                                {getTranslatedText(element.title)}
                                            </span>
                                        </Link>
                                        }else{
                                            content =<a href="#" className="disabled-anchor">
                                                            <img src={element.defaultIcon} alt="" title={element.title} />
                                                            <span class="">{element.title}</span></a>
                                        }
                        
                                        arr= <div className={element.status=='inactive'?"disabled-li":getActiveTab(element)} key={"menu"+index}>{content}</div>
                                        
                                    }
                    

                                    const row = items[id];


                                    return (
                                    <Sortable.Item as="li" key={"sortableItem"+id} id={id} >
                                        {arr}
                                    </Sortable.Item>
                                    );
                                })}
                                </Sortable>
                </ul>
            
            </aside>
        )
    
}

export default withRouter(Aside)