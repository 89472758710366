import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import {  Select, Slider} from 'antd';
import {getCategoryList} from '../../../realtimeTracker/actionMethods/actionMethods';



export default function LocationTimeFilter (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : moment().subtract(1,'day').toDate();
    date = date > moment().subtract(1,'days').set({hour:0,minute:0,second:0})?moment().subtract(1,'days').toDate():date
    
    const [dates, setDate] = useState({
        start:date,
        end: date,
    });



    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);

    
    
    // const [filterSlide,SetFilterSlide]=useState(100);
    const [filterFOP,SetFilterFOP] =  useState(false);
    
    const [filter2,SetFilter2] = useState(10);
    
    const [status,SetStatus] = useState(["Short-time","Over-time",'complied','Non Checkout']);
    const [locationList,SetLocationList] = useState([]);
    const [selectedLocation,SetSelectedLocation]= useState([]);
    const [slug,SetSlug]= useState('single')

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;


    
    let workday =props.workday;
    // const [volume,SetVolume]= useState('');    



    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

      useEffect(()=>{
        let requestBody ={}
        requestBody.session =userSession;
        requestBody.org_id= org_id
        getCategoryList(requestBody).then(res=>{
          console.log(res);
          if(res.data){
            let locList = [];
            for(let i= 0;i<res.data.length ;i++){
              locList.push(...res.data[i].location)
            }
            SetLocationList(locList);
          }
        })
      },[])
        const marks= {
    1: '1',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
  };

    function filterResultHandler(){
        SetFilterFOP(true);
       
        let requestBody={
          date:moment(dates.start).format('YYYY-MM-DD') ,
          start_date:moment(dates.start).format('YYYY-MM-DD') ,
          end_date:moment(dates.start).format('YYYY-MM-DD') ,
          location:selectedLocation,
          presence:filter2,
          locationAr: locationList.filter(el=>  selectedLocation.indexOf(el.tag_serial)> -1),
          slug: slug    
        }


        props.filterResultHandler(requestBody,true);
    }

    function exportToExcel(){
        props.exportEnable(true);
    }
    
    useEffect(()=>{
      return()=>{
        props.filterResultHandler({},false);
      }
    },[])
    useEffect(()=>{

let date = props.date > moment().subtract(1,'days').set({hour:0,minute:0,second:0})?moment().subtract(1,'days').toDate():props.date
      
      

      setDate((prev)=>({end:date,start:date}) )
    },[props.date])

    function onChangeLocationSelect(val){
      console.log("val",val);
  //val=[val] 
    let loc = [...locationList]
  if(val.indexOf('all')> -1){
    let value =[];
    for(let i=0;i<loc.length;i++){
      value.push(loc[i].tag_serial)
    }
    if((val.length -1) == value.length ){
      SetSelectedLocation([]);  
      SetSlug('single');
    }else{
      SetSelectedLocation(value);
      SetSlug('all');
    }
}else{
  if(val.length > 1){
    SetSlug('multiple');
  }else{
    SetSlug('single');
  }
  SetSelectedLocation(val);
}

}


useEffect(()=>{
  if(moment(dates.start).format('YYYY-MM-DD') != moment(dates.start).format('YYYY-MM-DD') && selectedLocation.length > 0){
    SetSelectedLocation([]);
  }
},[dates,selectedLocation])
    
    return (
<Row className="">

                  <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        value={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);props.filterResultHandler({},false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={dates.end}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>
                   
                  <Col lg={4} className="p-r-0 report-filter report-filter2">
                      <label>
                        {getTranslatedText("Select Location")}
                      </label>
                        <Select
                          onChange={(e)=>onChangeLocationSelect(e)}
                          style={{width:"100%"}}
                          value={selectedLocation}
                          mode="multiple"
                        >
                          <Select.Option value="all">Select All</Select.Option>
                          {locationList.map(el=>{
                            return  <Select.Option value={el.tag_serial}>{el.location_name}</Select.Option>
                          })}
                        </Select>
                  </Col>
                  <Col lg={4} className="p-r-0 report-filter report-filter2">
                      <label>
                        {getTranslatedText("Time Spent (%)")}
                      </label>
  <div className="mgSpace" style={{padding:2}}>
  <Slider   value={filter2} tooltipVisible={true} tooltipPlacement="bottom"  onChange={(val)=>{SetFilter2(val);}}  />
  </div>


                  </Col>
<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={dates.start==''|| dates.end=="" || selectedLocation.length===0 || filter2===""  }>Submit</button>
                <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel} disabled={!filterFOP} >Export</button>
                </div>
</Col>


                </Row>
                )

}