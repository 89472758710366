import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import Highcharts from 'highcharts';
import ReactHighcharts from "react-highcharts";
import spinnerLoader from "../../../assets/images/loader.svg";

export default function PletonWorker(props) {
    let date = localStorage.getItem("selectedDate")
        ? new Date(localStorage.getItem("selectedDate"))
        : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });

    const [selectedDate, updateSelectedDate] = useState(date);
    const [loader, SetLoader] = useState(true);
    const [options, setOptions] = useState({});
    const [activeHour, setActiveHour] = useState(null); // Track active hour
    const [activeEmployees, setActiveEmployees] = useState([]); // Store employee details
    const [filterStillLoading,SetFilterStillLoading] = useState(true);

// {name:e.point.series.name,data:e.point.tooltip,time:e.point.category,date:e.point.date,timeNumeric:e.point.timeNumeric}
    function formatDataClick(name,data,time){

    }

    useEffect(() => {
        async function filterResultHandler(data, pletonName) {
            try {
                if (props.filterActive) {
                    const pletonData = data[pletonName].hourly_statistics;
                    const workerData = data[pletonName].workers;

                    const categories = [];
                    const togatherData = [];
                    const outOfRangeData = [];
                    const notTogatherData = [];

                    // Process the hourly statistics
                    Object.keys(pletonData).forEach((timestamp) => {
                        const entry = pletonData[timestamp];
                        if (typeof entry === 'object') {
                            categories.push(moment(timestamp).format('hh A'));
                            togatherData.push({y:entry.togather || 0,employee:workerData,timestamp:timestamp});
                            outOfRangeData.push({y:entry.out_of_range || 0,employee:workerData,timestamp:timestamp});
                            notTogatherData.push({y:entry.not_togather || 0,employee:workerData,timestamp:timestamp});
                        } else {
                            categories.push(moment(timestamp).format('hh A'));
                            togatherData.push({y:0,employee:[],timestamp:timestamp});
                            outOfRangeData.push({y:0,employee:[],timestamp:timestamp});
                            notTogatherData.push({y:0,employee:[],timestamp:timestamp});
                        }
                    });
                    // Update Highcharts options with click event handler
                    let empFunc= workerData.map(el=>{
                        let func = [...props.employee].find(elm=>elm.worker_id ===el.worker_id)
                        let empRecord= {...el};

                        if(func){
                          empRecord.function  = func.sub_department_name;
                          empRecord.check_in  = func.check_in	;
                          empRecord.check_out  = func.check_out	;

                        }

                      return empRecord
                    }) 

                    setActiveEmployees(empFunc)
                    setOptions({
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: ``
                        },
                        xAxis: {
                            categories: categories,
                            crosshair: true,
                            tickPositioner: function () {
                                let positions = [];
                                let tick = 6;
                                positions = [0, 2, 5, 8, 11, 14, 17, 20, 23];
                                return positions;
                            },
                        },
                        yAxis: {
                            min: 0,
                            max: 100,
                            title: {
                                text: ''
                            }
                        },
                        tooltip: {
                            shared: true
                        },
                        plotOptions: {
                            column: {
                                stacking: 'normal'
                            },
                            
                        },
                        series: [
                            {
                                name: 'Out of Range',
                                data: outOfRangeData,
                                color: "#dedee3",
                                allowPointSelect: true,
                          
                            },
                            {
                                name: 'Not Togather',
                                data: notTogatherData,
                                color: "#fc0001",
                            
                            },
                            {
                                name: 'Togather',
                                data: togatherData,
                                color: "#90cf4e",
                          
                            }
                        ]
                    });
                    SetLoader(false);
                }
            } catch (error) {
                console.log("error", error);
            }
        }

        filterResultHandler(props.filterPassData.data, props.filterPassData.name);

    }, [props.filterPassData, props.filterFOP]);

    return (
        props.filterActive ?
            loader ?
                <div className="text-center m-t-lg">
                    <img src={spinnerLoader} className="m-t-lg" alt="Loading" />
                </div> :
                <div className="App">
                    <ToastContainer />
                    <Row>
                        <Col lg="12"><h4>Pleton:  <span style={{ color: "#ef5e8c" }}>{props.filterPassData.name}</span></h4></Col>
                    </Row>
                    <Row>
                        <Col lg={7}>
                            <ReactHighcharts
                                config={options}
                                options={options}
                            />
                        </Col>
                        <Col lg={5}>
                            <h5>Employee Details</h5>
                            <table className="tableStyle2">
                              <thead>
                                 <tr>
                                 <th>Name</th>
                                 <th>Check In</th>
                                 <th>Check Out</th>
                                 <th>Function</th>
                                 </tr>
                              </thead>
                              <tbody>
                    {activeEmployees.map((employee, index) => (
                                        <tr key={index} className="">
                                            <td>{employee.name}</td>
                                            <td>{moment(employee.check_in.replaceAll(" GMT","")).format('DD MMM hh:mm A')}</td>
                                            <td>{employee.check_out?moment(employee.check_out.replaceAll(" GMT","")).format('DD MMM hh:mm A'):"-"}</td>
                                            <td>{employee.function}</td>
                                        </tr>

                                    ))}
                            </tbody>

                            </table>
                        </Col>
                    </Row>
                </div> : ""
    );
}
