import React, { useState, useEffect,useRef ,useCallback, useMemo} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import dropdownIcon from "../../assets/images/down-arrow.png";
import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from "../../assets/images/loader.svg";
import {ReactComponent as DoubleArrow} from '../../assets/images/angles-right.svg'
import {ReactComponent as SingleArrow} from '../../assets/images/angle-right.svg'
import {ReactComponent as Plus} from '../../assets/images/caret-right.svg'
import {ReactComponent as Minus} from '../../assets/images/caret-down.svg'
import moment from "moment";
import { getTranslatedText } from "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import { Slider } from 'antd';

import { Link } from 'react-router-dom'

import { getDepartment,getSubDepartment } from "../../productivity/actionMethods/actionMethods";
import { getSiteLocations } from "../../siteManagement/actionMethods/actionMethods";
import { getPproductivitylist,getPproductivitylistDetail } from "../../productivity/actionMethods/actionMethods";
import { getPproductivityDetailOverview } from "../../productivity/actionMethods/actionMethods";
import BreakTimeAnalysis from "./BreakTimeAnalysis";
import ReactModal from "react-modal";
import { Checkbox, Select   } from 'antd';
import '../style/style.scss';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import ExcelJs from "exceljs";
import ChartReport from "./ChartReport";
import { ifElse } from "ramda";
import CycleCount from "./CycleCount";
import Performance from "./Performance";
import Governance from "./Governance";
import { getOnBoardEmp } from "../../costManagement/actionMethods/actionMethods";
import { getOnBoardDetail } from "../actionMethods/actionMethods";
import Attendance from "./Attendance";
import {ReactComponent as Image404 } from  '../../assets/images/11132-ai.svg'
import AutomatedFOPFilter from "./automatedFOP/filter";
import AutomatedFOP from "./automatedFOP";
import PlanActualReport from "./planActual";
import PlanActualReportFilter from "./planActual/filter";
import MovementPlanActual from "./movementPlanActual";
import MovementPlanActualFilter from "./movementPlanActual/filter";
import axios from 'axios';
import AttendanceTimeFilter from "./attendanceTime/filter";
import AttendanceTime from "./attendanceTime";
import ReportByUseCase from "./useCases/index";
import UseCaseFilter from "./useCases/filter";
import LocationTimeFilter from "./locationTime/filter";
import LocationTime from "./locationTime";
import PletonWorker from "./pletonWorker/index";
import PletonWorkerFilter from "./pletonWorker/filter";



const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};


function ReportExport(props) {
  const { Option } = Select;
//    OneSignal.init({
//     appId: '53501bf2-83a6-45d2-b47a-66b9f949a914',
// });
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();


  const [selectedDate, updateSelectedDate] = useState(date);

  // const [dates, setDate] = useState({
  //   //start: moment(date).subtract(1, "days").toDate(),
  //   start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  //   end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  // });
  const [dates, setDate] = useState({
    //start: moment(date).subtract(1, "days").toDate(),
    start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
  });

  const [selectedLangValue, updateSelectedLangValue] = useState("en");


  const [selectedTab, updatedSelectedTab] = useState("employees");
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);

  const [widthContainer,setWidthContainer]=useState(0);
  const [department,SetDeparment] =useState([]);
  const [departmentDaily,SetDeparmentDaily] =useState([]);
  const [department_id,SetDepartmentID] =useState([]);
  const [department_idv2,SetDepartmentIDv2] =useState([]);
  //this is the data

  const [sub_department,SetSubDeparment] =useState([]);
  const [sub_department_id,SetSubDepartmentID] =useState([]);

  const [location,SetLocation]=useState([]);
  const[location_id,SetLocationID] = useState([]);

  const [employeeAll,SetEmployeeAll]=useState([]);
  const [employeeDefault,SetEmployeeDefault]=useState([]);

  const [employeeAllDaily,SetEmployeeAllDaily]=useState([]);
  const [employeeDefaultDaily,SetEmployeeDefaultDaily]=useState([]);
  const [onBoardDetail,SetOnBoardDetail] =useState([]);
  const [showData,SetShowData]=useState([])
  const [showDataDefault,SetShowDataDefault]=useState([])

  const [isLoading,SetisLoading] = useState(true)
  const [isLoading2,SetisLoading2] = useState(false)
  const [isLoading3,SetisLoading3] = useState(false)

  const [count, SetCount]= useState(0)
  const [totalcount, SetTotalCount]= useState(0)
  const [dateCount,SetDateCount]=useState(0);

  const [parentAccord ,SetParentAccord]=useState([])
  const elementRef = useRef(null);   
  const [loopRequest,SetLoopRequest] =useState(0);
  const [filterData,SetFilterData] =useState([]);
  const [filterLocation,SetFilterLocation] =useState([]);
  const [exportEnable,SetExportEnable] =useState(false);
  // const [filterActive,SetFilterActive] =useState('working');
  const [filterActive,SetFilterActive] =useState('governance');
  // const [filterActive,SetFilterActive] =useState('planactual');
  const [filterby,SetFilterby] =useState([]);
  const [filter_min,SetFilterMin] =useState(0);
  const [filter_max,SetFilterMax] =useState(100);
  const [report_by,SetReportBy]=useState(['Percentage'])
  const [filterDataReport2,SetFilterDataReport2]=useState([]);
  const [filterbyCoumn,SetFIlterByCoulmn]=useState([]);
  const plainOptions=['Percentage','Time']
  const [filterSlide,SetFilterSLide] =useState([1,60])
  const [chart1,SetChart1] =useState(false)
  const [chart2,SetChart2] =useState(false)
  const [chartAllData,SetChartAllData]= useState({series:[],categories:[]})
  const [chartAllData2,SetChartAllData2]= useState({series:[],categories:[]})
  const [activeChart,SetActiveChart]= useState('');
  const [activeChart2,SetActiveChart2]= useState('');
  const [filterBtn,SetFilterBtn] =useState([]);
  const [errorMessage,SetErrorMessage]=useState('')



  const [chartData,SetChartData] = useState({series:[],categories:[]})
  const [chartData2,SetChartData2] = useState({series:[],categories:[]})
  const [chartLoader,SetChartLoader] =useState(false);
  const [chartLoader2,SetChartLoader2] =useState(false);
  const [filterTime,SetFilterTime]=useState(0);

  const [filterEnableCycle,SetFilterEnableCycle]=useState(false);
  const [exportEnableCycle,SetExportEnableCycle]=useState(false);
  const [cycleFilterSlug,SetCycleFilterSlug] =useState('five_min');
  const [cycleFilter,SetCycleFilter] =useState(0);

  const [breaktTimeExport,SetBreakTimeExport] =useState(false);
  const [breakTimeFilter,SetBreakTimeFilter] =useState(false); 
  const [type,SetType]=useState(''); 
  const [typeGovernance,SetTypeGovernance]=useState(['employee','daily_worker']); 
  const [legendEnable,SetLegendEnable] =useState(true);  
  const [filterPerformance,SetFilterPerformance] =useState(false);  
  const [filterWorking,SetFilterWorking] =useState(false);  
  const [filterPerformanceExport,SetFilterPerformanceExport] =useState(false);  
  const [reportPerformance,SetReportPerformance] =useState('month');
  const [agencyList,SetAgencyList] = useState([]);
  const [agencyRW,SetAgencyRW]= useState([])
  const [agencyDW,SetAgencyDW]= useState([])
  const [agency,SetAgency] = useState([]);
  const [buttonReady,SetButtonReady]= useState(false);
  const [pletonLoad,SetPletonLoad] = useState(false);
  
  const filterByOption =[
    {name:"Working Hour",value:"working_hour"},
    {name:"Wasted Hour",value:"wasted_hour"},
    {name:"Productive hour",value:"productive"},
    {name:"Less Productive hour",value:"less_productive"},
    {name:"Non Productive hour",value:"non_productive"},
    {name:"Out of Range",value:"undefined"}
  ]

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  const [inc,SetInc] =useState(1);
  const [activeTab,SetActiveTab]=useState('report');
  const [dataLoad,SetDataLoad] = useState(false);
  const [emailModal,SetEmailModal] = useState(false);
  const [filterFOP,SetFIlterFOP]= useState(false);
  const [filterPlanActual,SetFilterPlanActual] = useState(false);
  const [filterPassData,SetFilterPassData]= useState({});
  const [apiRequests, setApiRequests] = useState([]);
  const indonesiaTimeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';  
  let workday =props.workday;
  let prefixURL = process.env.REACT_APP_URL

  let lastReqId = null
  
  const gridRef = useRef();

  const marks2= {
    0: '1',
    1: '5',
    2: '10',
    3: '15',
    4: '20',
    5: '25',
    6: '30',
    7: '35',
    8: '40',
    9: '45',
    10: '50',
    11: '55',
    12: '60',
  };

  const marks= {
    1: '1',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
  };

  const cat3= [
    7,8,9,10,11,12,13,
    14,15,16,17,18,19,20,
    21,22,23,0,1,2,3,4,5,6
]
function closeEmailModal(){
  SetEmailModal(false);
}



  const isWeekday = (date) => {
      
        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
        //here sunday
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return true     
        }else{
          //here normal days
        return false ;
        }        
  };



  useEffect(() => {
    if(permission && permission.length  > 0){
      setWidthContainer(elementRef.current.getBoundingClientRect().width);
    }
    }, [elementRef,permission]);

    useEffect(()=>{
if(permission && permission.length  > 0){
    

    let requestBody={}
    requestBody.date= getDateFormat(date);
    getSiteLocations(requestBody,userSession,org_id,sub_org_id).then(res=>{
      if(res.status==200){
        SetLocation(res.data.location_details);
      }
    })

  }
},[permission])

  function handleDateSelect(date) {
    updateSelectedDate(date);

    // let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    setDate({ start: endDate, end: endDate });

    SetExportEnableCycle(false);
    SetDataLoad(false);
    SetFilterPlanActual(false);

    // filterEnableDisablePerformance()
    SetFilterPerformance(false);
    //SetFilterEnableCycle(false)

  }
  function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function isInt(value) {
    return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
  }

useEffect(() => {
    // let perm= props.role.health_management?JSON.parse(props.role.health_management):null;
    let perm = props.pages.filter(el=>el.sub_page_name=="Report Download"); 
    
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);


useEffect(()=>{
// if(filterActive=="governance" || filterActive=="attendance"){
if(filterActive==="governance" || filterActive==="attendance" || filterActive==="lcoation_time"  ){
  SetisLoading(false)
}
else{
  if(!dataLoad){

  let arr=[];
  let requestBody={}

  requestBody.startdate=getDateFormat(dates.start)
  requestBody.enddate=getDateFormat(dates.end)
  requestBody.slug='employee'
  let dept=[]
  let catIndex=0;
  let empList= [];
  let agencyLs=[]
  const departmentInfo = [];
  const departmentInfoDaily = [];
  SetisLoading(true);
    
  getOnBoardEmp(userSession,org_id,getDateFormat(dates.start),getDateFormat(dates.end),sub_org_id).then(res=>{
    SetDataLoad(true);
    if(res.status==200){
      let regular = res.data.regular?res.data.regular:[];
      let daily = res.data.daily?res.data.daily:[];  
    
      let regMap = [];
      let dayMap = [];
      let uniqueRegWorker=[]
      
// Regular Mapping Start
    regular.forEach((el) => {
      
         let agency = el.agency?el.agency:"";
          let single= {
            "access_policy_breach": 0,
            "agency_name":agency ,
            "emp_name": el.name,
            "less_productive": 0,
            "non_productive": 0,
            "productive": 0,
            "tag_serial": el.tag_serial,
            "undefined": 0,
            "wasted_time": 0,
            "worker_id": el.worker_id,
            "department": el.department_name,
            "sub_department": el.sub_department_name,
            ...el
          }
          const dept = single.department;
         const subDept = single.sub_department;
          if(regMap.find(el=> el.worker_id == single.worker_id)){
            
          }else{
            
              regMap.push(single); 
          }
         
        const department = single.department;
        const subDepartment = single.sub_department;

          // Check if the department is already in the array
          const existingDepartment = departmentInfo.find((dept) => dept.name === department);

          if (existingDepartment) {
            // Check if the sub_department is not already in the array for this department
            if (!existingDepartment.sub_department.includes(subDepartment)) {
              existingDepartment.sub_department.push(subDepartment);
            }
          } else {
            // Create a new entry for the department
            departmentInfo.push({
              name: department,
              sub_department: [subDepartment],
            });
          }
      }); 
    SetEmployeeAll(regMap);
    SetEmployeeDefault(regMap);
    SetDeparment(departmentInfo);

    SetisLoading(false);
   
    SetAgencyRW([...new Set(regMap.map(item=>item.agency_name))])

// Daily Mapping Start
      

     daily.forEach((el) => {
          
        let agency = el.agency?el.agency:"";

        let single= {
            "access_policy_breach": 0,
            "agency_name": agency,
            "emp_name": el.name,
            "less_productive": 0,
            "non_productive": 0,
            "productive": 0,
            "tag_serial": el.tag_serial,
            "undefined": 0,
            "wasted_time": 0,
            "worker_id": el.worker_id,
            "department": el.department_name,
            "sub_department": el.sub_department_name,
            ...el
          }
          const dept = single.department;
         const subDept = single.sub_department;
          if(dayMap.find(el=> el.worker_id == single.worker_id)){
      
          }else{
      
              dayMap.push(single); 
          }
         
        const department = single.department;
        const subDepartment = single.sub_department;

          // Check if the department is already in the array
          const existingDepartment = departmentInfoDaily.find((dept) => dept.name === department);

          if (existingDepartment) {
            // Check if the sub_department is not already in the array for this department
            if (!existingDepartment.sub_department.includes(subDepartment)) {
              existingDepartment.sub_department.push(subDepartment);
            }
          } else {
            // Create a new entry for the department
            departmentInfoDaily.push({
              name: department,
              sub_department: [subDepartment],
            });
          }
             
      }); 


    SetEmployeeAllDaily(dayMap)
    SetEmployeeDefaultDaily(dayMap)
    SetDeparmentDaily(departmentInfoDaily)
    SetisLoading(false);
    SetAgencyDW([...new Set(dayMap.map(item=>item.agency_name))])
    SetButtonReady(true);
    }

    
  })

  }
}
},[permission,dates,dataLoad,filterActive])


useEffect(()=>{
if(type=='employee'){
  SetAgencyList(agencyRW)
}else if (type=="daily_worker"){
    SetAgencyList(agencyDW)
}
},[type])

useEffect(()=>{

  if(permission.length > 0){
    let sub=[];  
    let subDept=[];
    SetSubDeparment([]);

    let dept = type=="employee"?department:departmentDaily
    for(let i =0;i<department_id.length;i++){
      let sub2= dept.filter(item=>item.name.toString().toLowerCase() === department_id[i].toLowerCase() )
      for (let j=0;j<sub2.length;j++){
        subDept = subDept.concat(sub2[j].sub_department);
      }  
    }

  if(subDept.length > 0){
    SetSubDeparment(subDept);  
  }

   
  }
},[permission,department_id])


useEffect(()=>{
  SetChartLoader(true)
  if(permission.length > 0){
      SetActiveChart('') 
  }
},[permission,chartAllData])

useEffect(()=>{
  // SetChartLoader(true)
  if(permission.length > 0){
    let fpOutput =[]  
    if(activeChart==""){
          fpOutput =chartAllData.series.filter(item=> item.acSeries.includes(activeChart));
      }else{
        fpOutput =chartAllData.series.filter(item=> item.acSeries == activeChart);
      }
       
      SetChartData({categories:chartAllData.categories,series:fpOutput}) 
    
  }
  // setTimeout(function(){
  //   SetChartLoader(false);
  // },300) 
},[permission,activeChart])

useEffect(()=>{
  // SetChartLoader2(true)
  if(permission.length > 0){
    
      let fpOutput =[];
      if(activeChart2==""){
fpOutput = chartAllData2.series.filter(item=>item.department.includes(activeChart2));
      }else{
fpOutput = chartAllData2.series.filter(item=>item.department.trim() === activeChart2.trim());
      } 
  
      SetChartData2({categories:chartAllData2.categories,series:fpOutput})    
  }
  // setTimeout(function(){
  //   SetChartLoader2(false);
  // },300) 
},[permission,activeChart2])

  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);


  const handleDate = (date, type) => {
    SetDeparmentDaily([])
    SetDeparment([])
    SetDataLoad(false);
    setDate((prev) => ({ ...prev, [`${type}`]: date }));
    SetDataLoad(false);
  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  });

  function GetRandomDigit(min=0,max=10){
    return  Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function handleClickCard(worker_id,date){
    let dt =date.split('-');
    localStorage.setItem('ProductiveStart',new Date(dt[2], dt[1] - 1, dt[0]));
    localStorage.setItem('ProductiveEnd',new Date(dt[2], dt[1] - 1, dt[0]));
    localStorage.setItem("selectedDate",new Date(dt[2], dt[1] - 1, dt[0]))
    if(type=='employee' ||type=='regular' ){
    window.open(
      `/productivity/employee/:${worker_id}`, "_blank");
    }else{
          window.open(
      `/productivity/daily_worker/:${worker_id}`, "_blank");
    }

  }


  function AGTable(data,key,selectedLocation=location_id){

    key = data.length +key +filterLocation.length;
    let loc = selectedLocation;
    let arr;
    let arr2=[];
   let lengthSel =  Object.keys(data[0]).length 
   
    if(data){  
    let widthCal= key=='allEmployeee'?(widthContainer - 60)/ lengthSel:(widthContainer - 90)/ lengthSel+1;
    
   
      
    arr2.push( <AgGridColumn
      field="date"
      headerName={"Date"}
      key={"date"}
     /> 
    )
      arr2.push(  
        <AgGridColumn
            field="name"
            headerName={"Name"}
            valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
            cellClass = {"ag-cell--textUppercase"}
            key={"name"}
        />
      )
    arr2.push( 
      
    <AgGridColumn
      field="tag_serial"
      headerName={"Tag Serial"}
      key={"tag_serial"}
    /> )
 
    arr2.push(  
      <AgGridColumn
          field="worker_id"
          headerName={"Emp ID"}
          valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
          cellClass = {"ag-cell--textUppercase"}
          key={"worker_id"}
      />
    )

      arr2.push(  
      <AgGridColumn
          field="agency_name"
          headerName={"Agency Name"}
          valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
          cellClass = {"ag-cell--textUppercase"}
          key={"agency_name"}
      />
    )

    arr2.push( 
      
      <AgGridColumn
        field="shift"
        headerName={"Shift"}
        key={"shift"}
      /> )
  
 
    arr2.push( 
      
      <AgGridColumn
        field="department"
        headerName={"Department"}
        key={"department"}
      /> )

    arr2.push( 
      
      <AgGridColumn
        field="sub_department"
        headerName={"Sub Department"}
        key={"sub_department"}
      /> )
  
    for(let i = 0; i<selectedLocation.length;i++){
      let locationName=  location.find(item=>item.loc_tag==selectedLocation[i] )
     
      if(locationName){
        arr2.push( <AgGridColumn
          field={selectedLocation[i]}
          headerName={locationName.name}
          comparator={timeComparator}
          key={"loc"+selectedLocation[i]}
        />);
      }else{
        arr2.push(
           <AgGridColumn
              field={selectedLocation[i]}
              comparator={timeComparator}
                        key={"loc"+selectedLocation[i]}
              //headerName={"Tag Serial " +i}
            />
        );
      }

    }


    arr =<div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0?data.length > 500?500:data.length:1)*34.8) , width: "calc(100% - 1px)"}}>     
    <AgGridReact
            rowHeight={35}
            ref={gridRef}
            copyHeadersToClipboard= {true}
            enableRangeSelection= {true}
            onCellClicked={ (event) => handleClickCard(event.data.worker_id,event.data.date)}
            autoGroupColumnDef={{
                headerName: 'Name',minWidth: 200,field: 'name',
    
            }}
            headerHeight={35}
         defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal  ,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
         pagination= {data.length>500}
         paginationPageSize= {500}             
            rowData={data}
            key={key}
            overlayNoRowsTemplate={
                AGGridEMptyMEssage('')
              }        
         >    
    {arr2}
    </AgGridReact>
    </div></div>
    
      
            }
        SetShowDataDefault(arr);    


  }

  function EdiButtonRender(params){
        

    var op =document.createElement('div');

    var eGui = document.createElement('div');
  
    eGui.addEventListener('click', event => {
      if(event.target.getAttribute("data-action")=='edit'){
        handleClickCard(params.data.worker_id,params.data.date)
      }
    });    
        eGui.innerHTML=`<div class="element-icon ant-table-cell">
                            <button class="btn btn-edit btn-smaller btn-icon" data-action="edit" data-el="${params.value}" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg> View </button>
                        </div>`;
            


  
     return eGui;



}


useEffect(()=>{
  SetFIlterFOP(false);
  SetExportEnable(false);

},[filterActive])
  
  function AGTable2(data,key,selectedLocation=filterby,loopInner=0){
    
    key = data.length +key + selectedLocation.length;
    let loc = selectedLocation;
    let arr;
    let arr2=[];
  //  let lengthSel =  11
    if(!data){

      return
    }else{

    }
   let lengthSel =  7  + filterby.length;

    
    if(data){  
    let widthCal= (widthContainer - 200)/ lengthSel;
    
     key = data.length +key + selectedLocation.length;
      
    data = data.sort((a,b) => {
      let dt =a.date.split('-'); 
      let dt2 =b.date.split('-');

      let aMs = new Date(dt[2], dt[1] - 1, dt[0])
      let bMs = new Date(dt2[2], dt2[1] - 1, dt2[0])
      return  aMs - bMs
    })


    arr2.push( <AgGridColumn
      field="date"
      headerName={"Date"}
     /> 
    )
      arr2.push(  
        <AgGridColumn
            field="emp_name"
            headerName={"Name"}
            valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
            cellClass = {"ag-cell--textUppercase"}
        />
      )
      
      arr2.push(  
        <AgGridColumn
            field="worker_id"
            headerName={"Emp ID"}
            valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
            cellClass = {"ag-cell--textUppercase"}
        />
      )
    arr2.push( 
      
    <AgGridColumn
      field="tag_serial"
      headerName={"Tag Serial"}
    /> )

    arr2.push( 
      <AgGridColumn
        field="agency_name"
        headerName={"Agency Name"}
      />
     )

    // arr2.push( 
      
    //   <AgGridColumn
    //     field="shift"
    //     headerName={"Shift"}
    //   /> )
  
 
    arr2.push( 
      
      <AgGridColumn
        field="department"
        headerName={"Department"}
        valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
        cellClass = {"ag-cell--textUppercase"}
      /> )

    arr2.push( 

      <AgGridColumn
        field="sub_department"
        headerName={"Sub Department"}
        valueFormatter={(params)=>capitalizeFirstLetter(params.value)}
        cellClass = {"ag-cell--textUppercase"}
      />      
      )
   
      if(selectedLocation.indexOf('working_hour') > -1){
   
        arr2.push( 
        <AgGridColumn
              field="working_hour"
              headerName={"Working Hour"}
              valueFormatter={(params)=>params.value+"%"}
            />
          ) 
      }
      if(selectedLocation.indexOf('wasted_hour') > -1){
        arr2.push( <AgGridColumn
          field="wasted_hour"
          headerName={"Wasted Hour"}
          valueFormatter={(params)=>params.value+"%"}
        />)
      }
      if(selectedLocation.indexOf('productive') > -1){
        arr2.push(   <AgGridColumn
          field="productive"
          headerName={"Producivite Hour"}
          valueFormatter={(params)=>params.value+"%"}
        />)
      }

      if(selectedLocation.indexOf('less_productive') > -1){
        arr2.push(    <AgGridColumn
          field="less_productive"
          headerName={"Less Producivite Hour"}
          valueFormatter={(params)=>params.value+"%"}
        />)
      }

      if(selectedLocation.indexOf('non_productive') > -1){
        arr2.push( <AgGridColumn
          field="non_productive"
          headerName={"Non Producivite Hour"}
          valueFormatter={(params)=>params.value+"%"}
        />)
      }

      if(selectedLocation.indexOf('undefined') > -1){
        arr2.push(   <AgGridColumn
          field="undefined"
          headerName={"Out of Range Hour"}
          valueFormatter={(params)=>params.value+"%"}
        />)
      }
      arr2.push(   <AgGridColumn
        field="worker_id"
        headerName={"Action"}
        cellRenderer= {(params)=>EdiButtonRender(params)}
        width="100px"
      />)
    arr = <div className={"textAlignLeft "+ key}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0?data.length > 500?500:data.length:1)*34.8) , width: "calc(100% - 1px)"}}> 
    
    <AgGridReact
            rowHeight={35}
            ref={gridRef}
            copyHeadersToClipboard= {true}
            enableRangeSelection= {true}
            //onCellClicked={ (event) => handleClickCard(event.data.tag_serial,event.data.date)}
            autoGroupColumnDef={{
                headerName: 'Name',minWidth: 200,field: 'name',
    
            }}
            headerHeight={35}

         defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal  ,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
         pagination= {data.length>500}
         paginationPageSize= {500}             
            rowData={data}
            
            key={"AgFilterv2 Report"+key}
            overlayNoRowsTemplate={
                AGGridEMptyMEssage('')
              }        
         >    
    {arr2}


    </AgGridReact>
    </div></div>
    
      
            }
        SetShowDataDefault(arr);    


  }

  function capitalizeFirstLetter(string) {
    if(string){
      return string.toLowerCase();
    }
  }

  

  const timeComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {



    let aMs = moment(valueA, 'HH:mm:ss').format('x')
    let bMs = moment(valueB, 'HH:mm:ss').format('x')
        if(valueA=='na' || valueB=='na')return 0;
        if (valueA == valueB) return 0;
        return (aMs > bMs) ? 1 : -1;       
 };

 function filterResultHandler(depID,subdepID){

  SetShowDataDefault([]);

  SetisLoading2(true);
  SetisLoading3(true);


  let empfilter=[]
  let employeeList =type=='daily_worker'?[...employeeDefaultDaily]:[...employeeDefault];

  let filterDep=[];
    let selectedFilter = [...depID];
    if(subdepID.indexOf('all')){
      selectedFilter.push(...sub_department)
    }else{
      for(let i=0;i<subdepID.length;i++){
        selectedFilter.push(subdepID[i]);
      }
    }

    for(let i=0; i<depID.length;i++){
      let filterList =   employeeList.filter(item=> item.department.toString().toLowerCase().includes(depID[i]=='all'?'':depID[i].toString().toLowerCase())  )
      filterDep.push(...filterList);
    }

    for(let i=0; i<subdepID.length;i++){
      let filterList =   filterDep.filter(item=> item.sub_department.toString().toLowerCase().includes(subdepID[i] == 'all'?'':subdepID[i].toString().toLowerCase()) )
      empfilter.push(...filterList);
    }

    if(subdepID.length ==0){
      empfilter= filterDep;
    }



    // for(let i = 0; i<selectedFilter.length;i++){
    //   let filterList =   employeeDefault.filter(item=> item.department.toString().toLowerCase().includes(selectedFilter[i].toString().toLowerCase()) && item.sub_department.toString().toLowerCase().includes(selectedFilter[i].toString().toLowerCase()) )
    //   empfilter.push(...filterList);
    // }

  let arr= [];
  let emp=[];
  let startDate = moment(dates.start)
  let endDate =   moment(dates.end)
  let loopCount = 0;
  // var a = moment([2007, 0, 29]);
  // var b = moment([2007, 0, 28]);
  let difference = endDate.diff(startDate, 'days'); 
  
  SetFilterLocation(location_id);


  SetTotalCount(empfilter.length);
  
  
  let totalLoop = empfilter.length * difference
  let loopActual =0;
  for(let j= 0;j <= difference;j++ ) {
    SetCount(0);      
    let dateprod =moment(dates.start, "YYYY-MM-DD").add(j, 'days')
    let time=[];
    let shift=[];
    let loopInner=0 ;
    for (let i= 0;i<empfilter.length;i++) {
      

      let requestBody={}
      let empdetail={};
      requestBody.startdate = moment(dateprod).format('YYYY-MM-DD');
      requestBody.enddate = moment(dateprod).format('YYYY-MM-DD');
      
      requestBody.slug = type
      requestBody.tag_serial = empfilter[i].tag_serial;
      requestBody.worker_id = empfilter[i].worker_id;
      
      loopActual++;
      getPproductivityDetailOverview(requestBody,userSession,org_id,sub_org_id).then(res=>{
        
        if(res.status== 200){
         
          loopInner++
          loopCount++;
          SetCount(loopInner)
          let datetest = Math.ceil(loopCount/empfilter.length) - 1;
          SetDateCount(moment(dates.start, "YYYY-MM-DD").add(datetest, 'days').format('MMM DD')) ;
          
          
          empdetail.name=empfilter[i].emp_name
          empdetail.worker_id=empfilter[i].worker_id
          empdetail.tag_serial=empfilter[i].tag_serial
          empdetail.date=moment(dateprod).format('DD-MM-YYYY');
          empdetail.department=empfilter[i].department
          empdetail.sub_department=empfilter[i].sub_department            
          //empdetail.data = res.data
          let shiftHour =[]
          let prodloc=[];
          let shift = res.shift && typeof res.shift == "object"?res.shift:res.shift?JSON.parse(res.shift):[]
          let startSft= res.shift?shift.start_time.split(':'):[0,0,0]
          let endSft= res.shift?shift.end_time.split(':'):[0,0,0]
          empdetail['shift'] = res.shift?startSft[0]+":"+startSft[1]+" - "+ endSft[0]+":"+endSft[1]: "- ";
          empdetail.productive=[];

          let st = parseInt(startSft && startSft[0]?parseInt(startSft[1]) > 40 && parseInt(startSft[0])!=23 ?parseInt(startSft[0]) + 1:parseInt(startSft[0]):0);
          let et = parseInt(endSft && endSft[0]?parseInt(endSft[1]) > 40 && parseInt(endSft[0])!=23 ?parseInt(endSft[0]) + 1:parseInt(endSft[0]):0);
          let smaller=st>et?et:st;
          let bigger = st>et?st:et;
          for(let i=0;i<location_id.length;i++){
              
            
                empdetail[location_id[i]]= "00:00:00"        
          
         }

          

          if(res.shift && res.data){

         //Pushing shift hour 
          for(let i=0;i < cat3.length;i++){
            if(st > et){
              if( (smaller <= cat3[i]) && (bigger > cat3[i]) ){
              }else{

                  shiftHour.push(cat3[i]);

              }

          }else{
              if( (smaller <= cat3[i]) && (bigger > cat3[i]) ){

                  
                  shiftHour.push(cat3[i]);   

              }
              
          }
        }

        for(let i=0 ; i< shiftHour.length;i++){


            if(res.data[shiftHour[i]]){
              let productive = res.data[shiftHour[i]].productive;
              let nonproductive = res.data[shiftHour[i]].non_productive;
              let lessproductive = res.data[shiftHour[i]].less_productive;

              Object.keys(productive).forEach((key,i)=>{
                let elm = productive[key];
                
                let locExist =  location_id.find(item=>item== elm.location_serial)
                if(locExist){ 
            
                let index = prodloc.findIndex(item=>item.name==key);
            
                
                if(index > -1){
                    
                    let ti = prodloc[index].time + Math.round(moment.duration(elm.time).asMilliseconds())                            
                    
                    prodloc[index]={name:key,time:ti,tag_serial:elm.location_serial};

                }else{
                    
                    prodloc.push({name:key,time:Math.round(moment.duration(elm.time).asMilliseconds()),tag_serial:elm.location_serial })
                    
                }    
                
              }
             })
             Object.keys(lessproductive).forEach((key,i)=>{
              let elm = lessproductive[key];
              
              let locExist =  location_id.find(item=>item== elm.location_serial)
              if(locExist){ 
          
              let index = prodloc.findIndex(item=>item.name==key);
          
              
              if(index > -1){
                  
                  let ti = prodloc[index].time + Math.round(moment.duration(elm.time).asMilliseconds())                            
                  
                  prodloc[index]={name:key,time:ti,tag_serial:elm.location_serial};

              }else{
                  
                  prodloc.push({name:key,time:Math.round(moment.duration(elm.time).asMilliseconds()),tag_serial:elm.location_serial })
                  
              }    
              
            }
           })
           Object.keys(nonproductive).forEach((key,i)=>{
            let elm = nonproductive[key];
            
            let locExist =  location_id.find(item=>item== elm.location_serial)
            if(locExist){ 
        
            let index = prodloc.findIndex(item=>item.name==key);
        
            
            if(index > -1){
                
                let ti = prodloc[index].time + Math.round(moment.duration(elm.time).asMilliseconds())                            
                
                prodloc[index]={name:key,time:ti,tag_serial:elm.location_serial};

            }else{
                
                prodloc.push({name:key,time:Math.round(moment.duration(elm.time).asMilliseconds()),tag_serial:elm.location_serial })
                
            }    
            
          }
         })

             for(let i=0;i<location_id.length;i++){
              
                if(prodloc){
                  let loc =  prodloc.filter((item)=> item.tag_serial == location_id[i])
                  if(loc.length > 0){
                   
                    empdetail[location_id[i]]=converttoTime(loc[0].time)
                  }else{
                    empdetail[location_id[i]]= "00:00:00"        
                  }
                  
                }else{
                  empdetail[location_id[i]]= "00:00:00"      
                }
             }

            empdetail.productive=prodloc;

            }
                     
        }

      }else{
        for(let i=0;i<location_id.length;i++){                
              empdetail[location_id[i]]= "00:00:00"        
       }

      empdetail.productive=[];

      
      }
      emp.push(empdetail)
        if(emp.length==loopCount){
          
          emp.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.date) - new Date(a.date);
          });
          SetFilterData(emp);
          AGTable(emp,'filter Data',location_id)
          SetExportEnable(true);
          //SetisLoading(false);
          SetisLoading2(false);

          if(emp.length == 0){
            SetErrorMessage(<h2>Filter Result Empty Data</h2>);
          }else{
            SetErrorMessage('');
          }
        }

      }
      if(emp.length==loopActual){
        SetisLoading3(false);
      }else{
       // SetisLoading3(true);
      }

      })

    }
    



 }
}

function getDaysCount(start,end){
     
     let startDate =moment(start).toDate();
     let endDate = moment(end).toDate();
     let totalDays = moment(end).diff(start, 'days') + 1     
     let incHol= 0;
      for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
        if(!isWeekday(startDate)){
          incHol++;
        }
      }     
      return totalDays - incHol;
}


function filterResultHandler2(){
  SetFilterWorking(true)
}
useEffect(() => {
  const cancelTokenSource = axios.CancelToken.source();

  apiRequests.forEach((request) => {
    
    request.cancel();
  });
  let req=[];
  setApiRequests([]);

  if (filterWorking) {
    SetisLoading3(true);
    SetChart1(false);
    SetShowDataDefault([]);

    let empfilter = [];
    let filterDep = [];
    let selectedFilter = [...department_id];
    
    if (sub_department_id == 'all') {
      selectedFilter.push(...sub_department)
    } else {
      for (let i = 0; i < sub_department_id.length; i++) {
        selectedFilter.push(sub_department_id[i]);
      }
    }

    let employeeList = type == 'daily_worker' ? [...employeeDefaultDaily] : [...employeeDefault];

    for (let i = 0; i < department_id.length; i++) {
      let filterList = employeeList.filter(item => item.department.toString().toLowerCase().includes(department_id[i].toString().toLowerCase()))
      filterDep.push(...filterList);
    }

    for (let i = 0; i < sub_department_id.length; i++) {
      let filterList = filterDep.filter(item => item.sub_department.toString().toLowerCase().includes(sub_department_id == 'all' ? '' : sub_department_id[i].toString().toLowerCase()))
      empfilter.push(...filterList);
    }

    SetFIlterByCoulmn(filterby);
    let arr = [];
    let emp = [];
    let startDate = moment(dates.start);
    let endDate = moment(dates.end);
    let difference = endDate.diff(startDate, 'days') - getDaysCount(startDate, endDate);
    SetTotalCount(empfilter.length);
    let totalLoop = empfilter.length * difference;
    let loopActual = 0;
    let empList = [];
    let loopInner = 0;
    let dateInc = 0;
    
    // SetFilterWorking(true);

    const processDate = (j, dateprod,cancelTokenSource) => {
      let dt = dateprod
      SetCount(0);
      let time = [];
      let shift = [];
      let requestBody = {};
      let empdetail = {};
      requestBody.startdate = moment(dateprod).format('YYYY-MM-DD');
      requestBody.enddate = moment(dateprod).format('YYYY-MM-DD');
      requestBody.slug = type;
      
      // const cancelToken = axios.CancelToken.source();

      setApiRequests((prevRequests) => [...prevRequests, cancelTokenSource]);

      return axios.get(`${prefixURL}/get_productivity_list?session=${userSession}&org_id=${org_id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&sub_org_id=${userDetails.sub_org_id}`, {
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {

          let res = response.data;
          if (res.status === 200) {
            Object.keys(res.data).forEach((key) => {
              let element = res.data[key];
              Object.keys(element).forEach((key2) => {
                if (isInt(key2)) {
                } else {
                  if ((department_id.length === 0 || department_id.includes(key)) && (sub_department_id.includes('all') || sub_department_id.includes(key2) || sub_department_id.length === 0)) {
                    for (let c = 0; c < element[key2].length; c++) {
                      element[key2][c].department = key;
                      element[key2][c].sub_department = key2;
                      delete element[key2][c]['working_hour'];
                      delete element[key2][c]['wasted_time'];
                      let sumHour = element[key2][c].productive + element[key2][c].less_productive;
                      let nonSumHour = element[key2][c].undefined + element[key2][c].non_productive;
                      element[key2][c].date = moment(requestBody.startdate).format('DD-MM-YYYY');

                      if (report_by.includes('Percentage')) {
                        let minVal = filter_min !== '' ? parseInt(filter_min) : 0;
                        let maxVal = filter_max !== '' ? parseInt(filter_max) : 100;
                        if (filterby === 'all' || filterby.includes('working_hour')) {
                          element[key2][c].working_hour = sumHour > 100 ? 100 : sumHour.toFixed(2);
                        }
                        if (filterby === 'all' || filterby.includes('wasted_hour')) {
                          element[key2][c].wasted_hour = sumHour >= 100 ? 0 : nonSumHour > 100 ? 100 : nonSumHour.toFixed(2);
                        }
                        delete element[key2][c]['access_policy_breach'];
                        let lp = 0;
                        for (let i = 0; i < filterby.length; i++) {
                          if (element[key2][c][filterby[i]] >= parseInt(filterSlide[0]) && element[key2][c][filterby[i]] <= parseInt(filterSlide[1])) {
                            lp++;
                          }
                          if (lp === filterby.length) {
                            loopInner++;
                            empList.push(element[key2][c]);
                          }
                        }
                      }
                    }
                  }
                }
              });
            });
          }

          if (empList.length > 0) {
            if (loopInner === empList.length) {
              dateInc++;
              SetDateCount(moment(dates.start, 'YYYY-MM-DD').add(dateInc, 'days').format('MMM DD'));
            }
            if (difference === 0) {
              if (empList.length === 0) {
                SetErrorMessage(<h2>Filter Result Empty Data</h2>);
              } else {
                SetErrorMessage('');
              }
              empList = empList.filter((item) => agency.includes(item.agency_name));
              SetFilterData(empList);
              let loopAr = [];
              let loopAr2 = [];
              let series = [];
              let categories = [];
              if (department_id === 'all') {
                loopAr = department.map((item) => item.name);
              } else if (department_id.length > 1) {
                loopAr = department_id;
              } else if (sub_department_id.includes('all') || sub_department_id.length === 0) {
                loopAr = sub_department;
              } else {
                loopAr = sub_department_id;
              }
              for (let currentDate2 = new Date(dates.start); currentDate2 <= new Date(dates.end); currentDate2.setDate(currentDate2.getDate() + 1)) {
                let dateprod2 = currentDate2
                let innerSeries = [];
                let filter = empList.filter((item) => item.date === moment(dateprod2).format('DD-MM-YYYY'));
                categories.push(moment(dateprod2).format('DD MMM'));
                for (let i = 0; i < loopAr.length; i++) {
                  let filterDep = filter.filter((item) => item.department === loopAr[i] || item.sub_department === loopAr[i]);
                  let zeroLength = filterDep.filter((item) => item.less_productive === 0 && item.productive === 0 && item.non_productive === 0&& item.undefined === 0);
                  for (let z = 0; z < filterby.length; z++) {
                    let result =
                      filterDep.reduce((sum, current) => sum + parseFloat(current[filterby[z]]), 0) /
                      (filterDep.length - zeroLength.length);
                    let findItem = series.find((item) => item.name === loopAr[i] && item.acSeries === filterby[z]);
                    if (findItem) {
                      let ar = findItem.data;
                      ar.push(result);
                      findItem.data = ar;
                    } else {
                      series.push({ name: loopAr[i], data: [result], date: moment(dateprod2).format('DD MMM'), acSeries: filterby[z] });
                    }
                  }
                }
              }
              let firstActive = series.filter((item) => item.acSeries === filterby[0]);
              SetChartData({ series: firstActive, categories: categories });
              SetChartAllData({ series: series, categories: categories });
              let filterBtn = [];
              let categories2 = [];
              let series2 = [];
              SetFilterBtn(loopAr);
              for (let currentDate3 = new Date(dates.start); currentDate3 <= new Date(dates.end); currentDate3.setDate(currentDate3.getDate() + 1)) {
                if (!isWeekday(currentDate3)) {
                  let dateprod2 = currentDate3;
                  let innerSeries = [];
                  categories2.push(moment(dateprod2).format('DD MMM'));
                  for (let j = 0; j < loopAr.length; j++) {
                    let filter = empList.filter((item) => (item.department === loopAr[j] || item.sub_department === loopAr[j]) && item.date === moment(dateprod2).format('DD-MM-YYYY'));
                    let zeroLength = filter.filter((item) => item.less_productive === 0 && item.productive === 0 && item.non_productive === 0 && item.undefined === 0);
                    for (let z = 0; z < filterby.length; z++) {
                      let Sname = filterByOption.find((item) => item.value === filterby[z]).name;
                      let result =
                        filter.reduce((sum, current) => sum + parseFloat(current[filterby[z]]), 0) /
                        (filter.length - zeroLength.length);
                      let findItem = series2.find((item) => item.name === filterby[z] && item.department === loopAr[j]);
                      if (findItem) {
                        let ar = findItem.data;
                        ar.push(result);
                        findItem.data = ar;
                      } else {
                        series2.push({ name: filterby[z], data: [result], date: moment(dateprod2).format('DD MMM'), department: loopAr[j] });
                      }
                    }
                  }
                }
              }
              SetChartAllData2({ series: series2, categories: categories2 });
              let firstDisp = series2.filter((item) => item.department === loopAr[0]);
              SetChartData2({ series: firstDisp, categories: categories2 });
              SetisLoading3(false);
              empList = empList.sort((a, b) => new Date(a.date) - new Date(b.date))
              AGTable2(empList, 'testtReport2', filterby, loopInner);
            } else {
              if (empList.length === 0) {
                SetErrorMessage(<h2>Filter Result Empty Data</h2>);
              } else {
                SetErrorMessage('');
              }
              SetFilterData(empList);
              let loopAr = [];
              let loopAr2 = [];
              let series = [];
              let categories = [];
              if (department_id === 'all') {
                loopAr = department.map((item) => item.name);
              } else if (department_id.length > 1) {
                loopAr = department_id;
              } else if (sub_department_id.includes('all') || sub_department_id.length === 0) {
                loopAr = sub_department;
              } else {
                loopAr = sub_department_id;
              }
              for (let currentDate1 = new Date(dates.start); currentDate1 <= new Date(dates.end); currentDate1.setDate(currentDate1.getDate() + 1)) {
                if (!isWeekday(currentDate1)) {
                  let dateprod2 = currentDate1;
                  let innerSeries = [];
                  let filter = empList.filter((item) => item.date === moment(dateprod2).format('DD-MM-YYYY'));
                  categories.push(moment(dateprod2).format('DD MMM'));
                  for (let i = 0; i < loopAr.length; i++) {
                    let filterDep = filter.filter((item) => item.department === loopAr[i] || item.sub_department === loopAr[i]);
                    let zeroLength = filterDep.filter((item) => item.less_productive === 0 && item.productive === 0 && item.non_productive === 0 && item.undefined === 0);
                    for (let z = 0; z < filterby.length; z++) {
                      let result =
                        filterDep.reduce((sum, current) => sum + parseFloat(current[filterby[z]]), 0) /
                        (filterDep.length - zeroLength.length);
                      let findItem = series.find((item) => item.name === loopAr[i] && item.acSeries === filterby[z]);
                      if (findItem) {
                        let ar = findItem.data;
                        ar.push(result);
                        findItem.data = ar;
                      } else {
                        series.push({ name: loopAr[i], data: [result], date: moment(dateprod2).format('DD MMM'), acSeries: filterby[z] });
                      }
                    }
                  }
                }
              }
              let firstActive = series.filter((item) => item.acSeries.includes(''));
              SetChartData({ series: firstActive, categories: categories });
              SetChartAllData({ series: series, categories: categories });
              let filterBtn = [];
              let categories2 = [];
              let series2 = [];
              SetFilterBtn(loopAr);
              for (let currentDate = new Date(startDate); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
                if (!isWeekday(currentDate)) {
                  let dateprod2 = currentDate
                  let innerSeries = [];
                  categories2.push(moment(dateprod2).format('DD MMM'));
                  for (let j = 0; j < loopAr.length; j++) {
                    let filter = empList.filter((item) => (item.department === loopAr[j] || item.sub_department === loopAr[j]) && item.date === moment(dateprod2).format('DD-MM-YYYY'));
                    let zeroLength = filter.filter((item) => item.less_productive === 0 && item.productive === 0 && item.non_productive === 0 && item.undefined === 0);
                    for (let z = 0; z < filterby.length; z++) {
                      let Sname = filterByOption.find((item) => item.value === filterby[z]).name;
                      let result =
                        filter.reduce((sum, current) => sum + parseFloat(current[filterby[z]]), 0) /
                        (filter.length - zeroLength.length);
                      let findItem = series2.find((item) => item.name === filterby[z] && item.department === loopAr[j]);
					  if (findItem) {
                        let ar = findItem.data;
                        ar.push(result);
                        findItem.data = ar;
                      } else {
                        series2.push({ name: filterby[z], data: [result], date: moment(dateprod2).format('DD MMM'), department: loopAr[j] });
                      }
                    }
                  }
                }
              }
              SetChartAllData2({ series: series2, categories: categories2 });
              let firstDisp = series2.filter((item) => item.department.includes(''));
              SetChartData2({ series: firstDisp, categories: categories2 });
              SetisLoading3(false);
              empList = empList.sort((a, b) => new Date(a.date) - new Date(b.date))
              AGTable2(empList, 'testtReport2', filterby, loopInner);
            }
          }
        })
        .catch((e) =>{
        if (axios.isCancel(e)) {
          console.error(e.message);
        } else {
              console.error(e)
        }
              });
    };

    let dayLoop = 0
    const iterateAPILoop = async()=>{
      for (let currentDate = new Date(startDate); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
        const CancelToken = axios.CancelToken
        const cancelTokenSource = CancelToken.source()
        req.push(cancelTokenSource)

      }
    }
    const iterateDateRange = async () => {
      SetisLoading3(true);


      for (let currentDate = new Date(startDate); currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
        if (!isWeekday(currentDate)) {
          await processDate(dayLoop, currentDate,req[0]);
          SetisLoading3(false);
        }
        dayLoop++;
        if (moment(currentDate).format('YYYY-MM-DD') == moment(endDate).format('YYYY-MM-DD')) {
          SetExportEnable(true);
        }
      }
    };
    iterateAPILoop();
    iterateDateRange();
  }
 return () => {
    req.forEach(token => token.cancel("Operation canceled by the user."));
  };
}, [filterWorking]);

useEffect(()=>{
  if(filterActive==="working"){
    // SetFilterWorking(true);
  }else{
    SetFilterWorking(false);
  }
},[filterActive])

  function exportToExcelReport2(){
    let dp = department_id.length>1?"Multiple":department_id[0];
  
    let sheetName = userDetails.empName+"-"+moment(dates.start).format("MMM DD")+"-"+moment(dates.end).format("MMM DD")+"-"+dp+".xlsx";

   // let sheetName = userDetails.empName+"-"+moment(dates.start).format("MMM DD")+"-"+moment(dates.end).format("MMM DD")+"-"+department_id+".xlsx";
    let headerName= "Report"
    const filename = userDetails.empName+"-"+moment(dates.start).format("MMM DD")+"-"+moment(dates.end).format("MMM DD")+"-"+department_id+".xlsx";
    const workbook = new ExcelJs.Workbook();
  
    let sheet = workbook.addWorksheet(sub_department_id.length>0 && sub_department_id.length==1?sub_department_id[0]:"All", {
      views: [{ showGridLines: true }]
    });

    let colHeader = []
    colHeader.push({name: 'Date', header: 'Date', key: 'date'}) 
    colHeader.push({name: 'Name', header: 'emp_name', key: 'emp_name'}) 
    colHeader.push({name: 'Emp ID', header: 'worker_id', key: 'worker_id'}) 
    colHeader.push({name: 'Tag Serial', header: 'tag_serial', key: 'tag_serial'}) 
    colHeader.push({name: 'Agency Name', header: 'agency_name', key: 'agency_name'}) 
    // colHeader.push({name: 'Shift', header: 'shift', key: 'shift'}) 
    colHeader.push({name: 'Department', header: 'department', key: 'department'}) 
    colHeader.push({name: 'Sub Department', header: 'sub_department', key: 'sub_department'}) 

    
  
    for(let i=0;i< filterbyCoumn.length;i++){

   
   
      if(filterbyCoumn[i]=='working_hour'){
        colHeader.push({name: 'Working Hour', header: 'Working Hour', key: filterbyCoumn[i]}) 
      
      }
      if(filterbyCoumn[i]=='wasted_hour'){
        colHeader.push({name: 'Wasted Hour', header: 'Wasted Hour', key: filterbyCoumn[i]}) 
      
      }
      if(filterbyCoumn[i]=='productive'){
        colHeader.push({name: 'Productive Hour', header: 'Productive Hour', key: filterbyCoumn[i]}) 
      
      }

      if(filterbyCoumn[i]=='non_productive'){
        colHeader.push({name: 'Non Productive Hour', header: 'Non Productive Hour', key: filterbyCoumn[i]}) 
      
      }

      if(filterbyCoumn[i]=='less_productive'){
        colHeader.push({name: 'Less Productive Hour', header: 'Less Productive Hour', key: filterbyCoumn[i]}) 
      }

      if(filterbyCoumn[i]=='undefined'){
        colHeader.push({name: 'Out of Range Hour', header: 'Out of Range Hour', key: filterbyCoumn[i]}) 
      }
    }

  let  data = [...filterData]
  data= data.filter(item=>agency.indexOf(item.agency_name)> -1) ; 
  data = data.sort((a,b) => {
      let dt =a.date.split('-'); 
      let dt2 =b.date.split('-');

      let aMs = new Date(dt[2], dt[1] - 1, dt[0])
      let bMs = new Date(dt2[2], dt2[1] - 1, dt2[0])
      return  aMs - bMs
    })


  
    sheet.addTable({
      name: 'Report',
      ref: "A1", 
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        width: 200
      },
      columns : colHeader,
      //rows: [filterData]
      rows: data.map((e) => {
        let loc=[]
        let arr = [e.date ,e.emp_name,e.worker_id,e.tag_serial,e.agency_name,e.department,e.sub_department];      
        for (let i =0;i<filterbyCoumn.length;i++){
          arr.push(e[filterbyCoumn[i]]+"%")
        } 
        

        return arr;
      })
    })
  

  
  
  
    const table = sheet.getTable(headerName);
    for (let i = 0; i < table.table.columns.length; i++) {
      sheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 12 };
      sheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "c5d9f1" }
      };
  
      for (let j = 0; j < table.table.rows.length; j++) {
        let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 6}`);
        rowCell.alignment = { wrapText: true };
        rowCell.border = {
          bottom: {
            style: "thin",
            color: { argb: "a6a6a6" }
          }
        };
      }
    }
    sheet.columns.forEach(column => {
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength+2;
    });
    table.commit();
  
    const writeFile = (fileName, content) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;"
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };
  
    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(sheetName, buffer);
    });
    
  }

  function SetMin(val){
    if(val==''){
      SetFilterMin(val)    
    }
    val=parseInt(val);
    if(val >= 0 && val<= filter_max){
      SetFilterMin(val)    
  
    }
  }

  function SetMax(val){
    if(val==''){
      SetFilterMax(val)    
    }
    val= parseInt(val);
    if(val > 0 && val >=  filter_min){
      SetFilterMax(val)    
  }else{

  }

  }
  const exportToExcel = () => {
    let dp = department_id.length>1?"Multiple":department_id[0];
    
    let sheetName = userDetails.empName+"-"+moment(dates.start).format("MMM DD")+"-"+moment(dates.end).format("MMM DD")+"-"+dp+".xlsx";
    let headerName= "Report"
    const filename = userDetails.empName+"-"+moment(dates.start).format("MMM DD")+"-"+moment(dates.end).format("MMM DD")+"-"+department_id.length>0?"Multiple":department_id[0]+".xlsx";
    const workbook = new ExcelJs.Workbook();
  
    let sheet = workbook.addWorksheet("SubAll", {
      views: [{ showGridLines: true }]
    });

    let colHeader = []
    colHeader.push({name: 'Date', header: 'Date', key: 'date'}) 
    colHeader.push({name: 'Name', header: 'name', key: 'name'}) 
    colHeader.push({name: 'Emp ID', header: 'worker_id', key: 'worker_id'}) 
    colHeader.push({name: 'Tag Serial', header: 'tag_serial', key: 'tag_serial'}) 
    colHeader.push({name: 'Shift', header: 'shift', key: 'shift'}) 
    colHeader.push({name: 'Department', header: 'department', key: 'department'}) 
    colHeader.push({name: 'Sub Department', header: 'sub_department', key: 'sub_department'}) 

    
  
    for(let i=0;i< filterLocation.length;i++){

    let locationName=  location.find(item=>item.loc_tag==filterLocation[i] )
 
      if(locationName){
        colHeader.push({name: locationName.name, header: locationName.name, key: filterLocation[i]}) 
      
      }else{
        colHeader.push({name: filterLocation[i], header: filterLocation[i], key: filterLocation[i]}) 
       }
    }

    sheet.addTable({
      name: 'Report',
      ref: "A1", 
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium2",
        showRowStripes: false,
        width: 200
      },
      columns : colHeader,
      //rows: [filterData]
      rows: filterData.map((e) => {
        let loc=[]
        let arr = [e.date ,e.name,e.worker_id,e.tag_serial,e.shift,e.department,e.sub_department];      
        for (let i =0;i<filterLocation.length;i++){
          arr.push(e[filterLocation[i]])
        } 
        
        return arr;
      })
    })
  

  
  
  
    const table = sheet.getTable(headerName);
    for (let i = 0; i < table.table.columns.length; i++) {
      sheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 12 };
      sheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "c5d9f1" }
      };
  
      for (let j = 0; j < table.table.rows.length; j++) {
        let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 6}`);
        rowCell.alignment = { wrapText: true };
        rowCell.border = {
          bottom: {
            style: "thin",
            color: { argb: "a6a6a6" }
          }
        };
      }
    }
    sheet.columns.forEach(column => {
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength+2;
    });
    table.commit();
  
    const writeFile = (fileName, content) => {
      const link = document.createElement("a");
      const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;"
      });
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.click();
    };
  
    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(sheetName, buffer);
    });
  };

  
  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


  function converttoTime(val){


      let seconds = val / 1000;
  const hours = parseInt( seconds / 3600 );
  seconds = seconds % 3600;
  const minutes = parseInt( seconds / 60 ); 
  seconds = seconds % 60;
  
  let dispHour = hours > 0 && hours >9?hours:hours <10?"0"+hours:"00"
  let dispMinute = minutes > 0 && minutes >9?minutes:minutes <10?"0"+minutes:"00"
  let dispSecond = seconds > 0 && seconds >9?seconds:seconds <10?"0"+seconds:"00"

//  return hours>0 ? hours +":"+ minutes+":"+seconds:"00:"+ minutes+":"+seconds
  return dispHour+":"+dispMinute+":"+dispSecond;

}

function onChangeDepartment(val){
  let dept= type=='daily_worker'?departmentDaily:department

  if(val.indexOf('all')> -1){
      let value =[];
      for(let i=0;i<dept.length;i++){
        value.push(dept[i].name)
      }
      if((val.length -1) == value.length ){
        SetDepartmentID([]);  
      }else{
        SetDepartmentID(value);
      }

      
  }else{
    SetDepartmentID(val);
  }
  SetSubDepartmentID([]);
  SetExportEnable(false);
  SetFilterEnableCycle(false)
}

function SetAgencyListValue(val){
  if(val.indexOf('all')> -1){
      let value =[];
      for(let i=0;i<agencyList.length;i++){
        value.push(agencyList[i])
      }
      if((val.length -1) == value.length ){
        SetAgency([]);  
      }else{
        SetAgency(value);
      }

      
  }else{
    SetAgency(val);
  }
  SetExportEnable(false);
  SetFilterPerformance(false);
}

function onChangeSubDepartment(val){
  //val=[val] 
  if(val.indexOf('all')> -1){
    let value =[];
    for(let i=0;i<sub_department.length;i++){
      value.push(sub_department[i])
    }
    if((val.length -1) == value.length ){
      SetSubDepartmentID([]);  
    }else{
      SetSubDepartmentID(value);
    }

    
}else{
  SetSubDepartmentID(val);
}
SetFilterEnableCycle(false)
}

function exportEnableDisable(){
  SetExportEnable(true);
}
function exportEnableDisableBreakTime(){
  SetBreakTimeExport(true);
}
function exportEnableDisablePerformance(){
  SetFilterPerformanceExport(true);
}

function exportProcessExist(){
  SetExportEnableCycle(false);
}

function exportProcessExistBreakTime(){
  SetBreakTimeExport(false);
  SetFilterPerformance(false);
}

function exportProcessExistPerformance(){
  SetFilterPerformanceExport(false);
}

function dataReloadOnboard(date){
  
  setDate((prev) => ({ end:date, start: date })); 
  SetDataLoad(false);
}

function dataReloadOnboardPlanActual(date){
  
  setDate(date); 
  SetDataLoad(false);
}


function filterEnableDisable(){
  SetFilterEnableCycle(false);
}
function filterEnableDisableBreakTime(){
  SetBreakTimeFilter(false);
}

function filterEnableDisablePerformance(){
  SetFilterPerformance(true)
}

function onChangeLocationSelect(val){

  if(val.indexOf('all') > -1 && (val.length -1 ) == location.length){
    SetLocationID([]);
  }else if(val.indexOf('all') > -1){
    let tag= location.map((item)=> {return item.loc_tag });
    SetLocationID(tag);
  }else{
    SetLocationID(val);
  }
  SetExportEnable(false)
  SetFilterEnableCycle(false)
}

function FIlterResultHandlerFOP(value,status){
  SetFilterPassData(value);
  SetFIlterFOP(status);
}
function FIlterResultHandlerPlanActual(value,status){
  SetFilterPassData(value);
  SetFilterPlanActual(status);
}

  return (<>
    {permissionAPICall?
            permission.length > 0?
    <div className="manpowerManagementMainDiv manpowerManagementMainDivHeightFull">
      <Container className="header-breadcrumb">
        <Row>
          <Col lg={6}>
            {/* <CommonHeading title="Report" /> */}


            {/* <CommonHeading title="Visitor Management" /> */}
             <div className="VisitorHeaderTab">
              <h5 className={activeTab=='overview'?"tabVisitor commonHeading tabVisitorActive":"commonHeading tabVisitor"} onClick={()=>props.history.push('/report-overview')}>Report Overview</h5>
              <h5 className={activeTab=='report'?"tabVisitor commonHeading tabVisitorActive":"commonHeading tabVisitor"} onClick={()=>SetActiveTab('report')}>Report Download</h5>
             </div>
                {/* <h5 class="commonHeading">Visitor Management</h5> */}

          </Col>
          <Col lg={6} className="text-right">
            <div className="commonLangaugeStyleDiv">
              <DashboardLanguage
                selectedLangValue={selectedLangValue}
                changeLanguage={changeLanguage}
              />
            </div>

            <div className="commonHeadingDateMainDivParent">
              <CommonDatePicker
                selectedDate={selectedDate}
                handleSelectDate={handleDateSelect}
                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
              />
            </div>
          </Col>
        </Row>

        <Row className="m-t">
          <Col lg={12}>   
          <div className="manpowerManagementEmployeeListMainDiv">
                <div className="subLineTab">
                  <Select
                  onChange={(val)=>{
                    SetFilterPlanActual(false);
                    SetExportEnable(false);
                    SetShowData([]);
                    if(val==="working"){
                      SetFilterPlanActual(false);SetExportEnable(false);SetFilterActive('working');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterSLide([0,100]);SetFilterEnableCycle(false);SetFilterPerformance(false);SetFIlterFOP(false)
                    }else if(val=="performance"){
                      SetFilterPlanActual(false);SetExportEnable(false);SetFilterActive('performance');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterEnableCycle(false);SetCycleFilter(6);SetFilterPerformance(false);SetFIlterFOP(false)
                    }else if(val=="governance"){
                      SetFilterPlanActual(false);SetExportEnable(false);SetFilterSLide([1,60]);SetFilterActive('governance');SetShowDataDefault([]);SetExportEnable(false);SetFilterEnableCycle(false);SetCycleFilter(6);SetFilterPerformance(false);;SetFIlterFOP(false);SetReportPerformance('month')
                    }else if((val=="attendance")){
                      SetFilterPlanActual(false);SetExportEnable(false);SetFilterSLide([0,100]);SetFilterActive('attendance');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterEnableCycle(false);SetCycleFilter(6);SetFilterPerformance(false);SetReportPerformance('month');SetFIlterFOP(false) 
                    }else if((val=="planactual")){
                        SetFilterPlanActual(false);SetExportEnable(false);SetFIlterFOP(false);SetFilterActive('planactual')
                    }
                    else if((val=="movement_planactual")){
                        SetFilterPlanActual(false);SetExportEnable(false);SetFIlterFOP(false);SetFilterActive('movement_planactual');
                    }else if(val=="attendance_time"){
                      SetFilterActive('attendance_time');
                    }else{
                      SetFilterActive(val);
                      SetFilterPlanActual(false);SetExportEnable(false);SetFIlterFOP(false);SetFilterActive(val);
                    }
                  }}
                  value={filterActive}
                  className="withBorder"
                  style={{width:275}}
                 >
                   <Option value={"working"}>Report by Working</Option> 
                   {/* <Option value={"location"}>Report by Location</Option>  */}
            
                   <Option value={"performance"}>Report by Performance</Option> 
                   <Option value={"governance"}>Report by Governance</Option> 
                   <Option value={"attendance"}>Report by Attendance</Option> 
                   <Option value={"planactual"}>Report by Attendance (Plan Vs Actual)</Option> 
                   <Option value={"movement_planactual"}>Report by Movement (Plan Vs Actual)</Option> 
                   <Option value={"attendance_time"}>Report by Attendance Time</Option> 
                   <Option value={"report_usecase"}>Report by UseCase (15 Metric)</Option> 
                   <Option value={"location_time"}>Report by Location Time</Option> 
                   {org_id === 32?<Option value={"pleton"}>Report by Pleton</Option>:""} 
                  </Select>
                
                {/* <div className={filterActive==='working'?"sublinetablist active ":'sublinetablist '} onClick={()=>{SetFilterPlanActual(false);SetExportEnable(false);SetFilterActive('working');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterSLide([0,100]);SetFilterEnableCycle(false);SetFilterPerformance(false);SetFIlterFOP(false)} } >Report by Working</div> */}
                {/* delete option <div className={filterActive=='location'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterActive('location');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterEnableCycle(false);SetFilterPerformance(false)}}>Report by Location</div> */}
                {/* delete option <div className={filterActive=='cyclecount'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterActive('cyclecount');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterEnableCycle(false);SetFilterPerformance(false) }}>Report by Cycle Count</div>         */}
                {/*delete option <div className={filterActive==='breaktime'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterActive('breaktime');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterEnableCycle(false);SetCycleFilter(6);SetFilterPerformance(false) }}>Report by Break Time</div>         */}
                {/* <div className={filterActive==='performance'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterPlanActual(false);SetExportEnable(false);SetFilterActive('performance');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterEnableCycle(false);SetCycleFilter(6);SetFilterPerformance(false);SetFIlterFOP(false) }}>Report by Performance</div>         */}
                {/* <div className={filterActive==='governance'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterPlanActual(false);SetExportEnable(false);SetFilterSLide([1,60]);SetFilterActive('governance');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterEnableCycle(false);SetCycleFilter(6);SetFilterPerformance(false);;SetFIlterFOP(false);SetReportPerformance('month') }}>Report by Governance</div>                         */}
                {/* <div className={filterActive==='attendance'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterPlanActual(false);SetExportEnable(false);SetFilterSLide([1,100]);SetFilterActive('attendance');SetShowData([]);SetShowDataDefault([]);SetExportEnable(false);SetFilterEnableCycle(false);SetCycleFilter(6);SetFilterPerformance(false);SetReportPerformance('month');SetFIlterFOP(false) }}>Report by Attendance</div>                         */}
                
                {/* deleted option <div className={filterActive==='automatedfop'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterActive('automatedfop'); }}>Report by AutomatedFOP</div>                         */}
                {/* <div className={filterActive==='planactual'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterPlanActual(false);SetExportEnable(false);SetFIlterFOP(false);SetFilterActive('planactual') }}>Report by Attendance (Plan Vs Actual)</div>                         */}
                {/* <div className={filterActive==='movement_planactual'?"sublinetablist active":'sublinetablist'} onClick={()=>{SetFilterPlanActual(false);SetExportEnable(false);SetFIlterFOP(false);SetFilterActive('movement_planactual'); }}>Report by Movement (Plan Vs Actual)</div>                         */}
                <div className="btnExport sublinetablist"><button disabled={!filterPerformance || filterActive!="governance"} style={{opacity:!filterPerformance || filterActive!="governance"?"0.5":1}} className="btn-icon-share" onClick={()=>SetEmailModal(true)}><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10.5 2H14C14.1326 2 14.2598 2.05268 14.3536 2.14645C14.4473 2.24021 14.5 2.36739 14.5 2.5V6C14.5 6.13261 14.4473 6.25979 14.3536 6.35355C14.2598 6.44732 14.1326 6.5 14 6.5C13.8674 6.5 13.7402 6.44732 13.6464 6.35355C13.5527 6.25979 13.5 6.13261 13.5 6V3.70667L5.35333 11.8533C5.25855 11.9417 5.13319 11.9897 5.00365 11.9874C4.87412 11.9852 4.75053 11.9327 4.65892 11.8411C4.56731 11.7495 4.51484 11.6259 4.51255 11.4963C4.51026 11.3668 4.55835 11.2415 4.64667 11.1467L12.7933 3H10.5C10.3674 3 10.2402 2.94732 10.1464 2.85355C10.0527 2.75979 10 2.63261 10 2.5C10 2.36739 10.0527 2.24021 10.1464 2.14645C10.2402 2.05268 10.3674 2 10.5 2ZM3.5 5C3.23478 5 2.98043 5.10536 2.79289 5.29289C2.60536 5.48043 2.5 5.73478 2.5 6V13C2.5 13.2652 2.60536 13.5196 2.79289 13.7071C2.98043 13.8946 3.23478 14 3.5 14H10.5C10.7652 14 11.0196 13.8946 11.2071 13.7071C11.3946 13.5196 11.5 13.2652 11.5 13V7.5C11.5 7.36739 11.5527 7.24022 11.6464 7.14645C11.7402 7.05268 11.8674 7 12 7C12.1326 7 12.2598 7.05268 12.3536 7.14645C12.4473 7.24022 12.5 7.36739 12.5 7.5V13C12.5 13.5304 12.2893 14.0391 11.9142 14.4142C11.5391 14.7893 11.0304 15 10.5 15H3.5C2.96957 15 2.46086 14.7893 2.08579 14.4142C1.71071 14.0391 1.5 13.5304 1.5 13V6C1.5 5.46957 1.71071 4.96086 2.08579 4.58579C2.46086 4.21071 2.96957 4 3.5 4H9C9.13261 4 9.25979 4.05268 9.35355 4.14645C9.44732 4.24021 9.5 4.36739 9.5 4.5C9.5 4.63261 9.44732 4.75979 9.35355 4.85355C9.25979 4.94732 9.13261 5 9 5H3.5Z" fill="#364141"/>
</svg> Share</button> </div>
        </div>

        <div className="filterField">
                {
                filterActive=='location'?
                <Row className="">
                  <Col lg={1} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Start Date")}
                        maxDate={moment(dates.end).toDate()}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>
                  <Col lg={1} className="p-r-0 report-filter">
                      <label>
                        {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={moment(dates.end).toDate()}
                        onChange={(date) =>{ handleDate(date, "end");SetExportEnable(false) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("End Date")}
                        maxDate={selectedDate}
                        minDate={dates.start}
                        
                      />
                   
                    </div>
                  </Col>
                  <Col lg={1} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Employee Type")} 
                    </label>
                <Select
              placeholder="Select Type"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{SetType(val);SetDepartmentID([]);SetAgency([]);SetSubDepartmentID([])}}
              //defaultValue={department_id}
              value={type==''?"Select Type":type}
              key="departmentFilterv2"
              style={{width:"100%"}}
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
          >
    
            <Option value='employee' key={"Employee Type All v2 Regular"}>Regular Worker</Option>
            <Option value='daily_worker' key={"Employee Type All v2 Daily"}>Daily Worker</Option>              
          </Select>        
                </Col>
                  <Col lg={1} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Agency")} 
                    </label>
                 <Select
              placeholder="Select Agency"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{SetAgencyListValue(val)}}
              //defaultValue={department_id}
              value={agency}
              key="departmentFilterv2Agency5"
              style={{width:"100%"}}
              dropdownClassName={'smallerDrop'}
                              mode="tags"
              // mode={"multiple"}
          >
    
            <Option value='all' key={"Agency5 All"}>All</Option>
            {
              agencyList.map((item,index)=>{
                return <Option value={item} key={item+index+"1"}>{item}</Option>
              })
            }
           
          </Select>       
                </Col>
                

                  <Col lg={2} className="p-r-0 report-filter">
                  <label>
                        {getTranslatedText("Department")}
                      </label>
                  <Select
                placeholder="Select Department"
                //optionFilterProp="children"
                onChange={(val)=>onChangeDepartment(val)}
                
                value={department_id}
                key="departmentFilterv1"
                style={{width:"100%"}}
                dropdownClassName={'smallerDrop'}
                //mode="multiple"
                mode="tags"
                disabled={type==''}
            >

                {/* {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.name} key={"Department V1"+item.name}>{item.name}</Option>
                }):""
                }

                 */}
                                  <Option value='all' key={"Department All v2"}>Select All</Option>
              {type=='employee' && department && department.length > 0? department.map((item)=>{
                  return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
              }):type=='daily_worker' && departmentDaily && departmentDaily.length > 0? departmentDaily.map((item)=>{
                return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
            }):""
              }

            </Select>        

        
                  </Col>

                  <Col lg={2} className="p-r-0 report-filter">
                  <label>
                        {getTranslatedText("Sub Department")}
                      </label>
                      <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:"100%"}}
                    onChange={(val)=>onChangeSubDepartment(val)}
                   // disabled={department_id.length>1 || department_id.length==0 ?true:false}                   
                    value={sub_department_id}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}
                    mode="multiple"
                    disabled={department_id.length==0}
                >
                  <Option value='all' key={"Sub Department v1 All"}>Select All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item} key={"Sub Department V1"+item}>{item}</Option>
                    }):""
                    }
                    
                </Select>
 </Col>
 <Col lg={2} className="report-filter">
                  <label>
                        {getTranslatedText("Location")}
                      </label>
                  <Select
                placeholder="Select Location"
                optionFilterProp="children"
                onChange={(val)=>{SetLocationID(val); SetExportEnable(false) }  }
                //defaultValue={'Select Department'}
                value={location_id}
                key="departmentLocationFilterv1"
                style={{width:"100%"}}
                mode="multiple"
                dropdownClassName={'smallerDrop'}
                
            >
             {SetLocationID.length>0?"":<Option value='all' key={"Location v1 All"}>All</Option>} 
                {
                location && location.length > 0? location.map((item)=>{
                    return <Option value={item.loc_tag} key={"locationV1"+item.loc_tag}>{item.name}</Option>
                }):""
                }
            </Select>
      
</Col>

<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler(department_id,sub_department_id)} disabled={dates.start=='' || dates.end=='' || department_id=='' || location_id==''}>Submit</button>
                <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel}   disabled={!exportEnable} >Export</button>
                </div>
</Col>


                </Row>:
                filterActive==='working'?
                <Row className="">
                  <Col lg={1} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);SetFilterWorking(false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Start Date")}
                        maxDate={moment(dates.end).toDate()}
                        // minDate={moment().subtract(120, "days").toDate()}
                          minDate={new Date('2023-01-01')}
                          
                      />
                      
                    </div>
                  </Col>
                  <Col lg={1} className="p-r-0 report-filter">
                      <label>
                        {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={moment(dates.end).toDate()}
                        onChange={(date) =>{ handleDate(date, "end");SetExportEnable(false);SetFilterWorking(false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("End Date")}
                        maxDate={selectedDate}
                        minDate={dates.start}
                        
                      />
                      {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                    </div>
                  </Col>
                  

                  <Col lg={2} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Employee Type")} 
                    </label>
                <Select
              placeholder="Select Type"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              // onChange={(val)=>{SetType(val);SetDepartmentID([])}}
              onChange={(val)=>{SetType(val);SetDepartmentID([]);SetAgency([]);SetExportEnable(false);SetSubDepartmentID([]);SetFilterWorking(false);}}
              //defaultValue={department_id}
              value={type==''?"Select Type":type}
              key="departmentFilterv2"
              style={{width:"100%"}}
              dropdownClassName={'smallerDrop'}
              disabled={isLoading}
              // mode={"multiple"}
          >
    
            <Option value='employee' key={"Employee Type All v2 Regular"}>Regular Worker</Option>
            <Option value='daily_worker' key={"Employee Type All v2 Daily"}>Daily Worker</Option>              
          </Select>        
                </Col>

  <Col lg={1} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Agency")} 
                    </label>
                <Select
              placeholder="Select Agency"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              
              onChange={(val)=>{SetAgencyListValue(val);SetFilterWorking(false);}}
              //defaultValue={department_id}
              value={agency}
              key="departmentFilterv2"
              style={{width:"100%"}}
              disabled={type==""}
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
                              mode="tags"
          >
    
            <Option value='all' key={"Agency2 All"}>All</Option>
            {
              agencyList.map((item,index)=>{
                return <Option value={item} key={item+index+"1"}>{item}</Option>
              })
            }
         
          </Select>        
                </Col>
                
  <Col lg={2} className="p-r-0 report-filter">
                  <label>
                        {getTranslatedText("Department")} 
                      </label>
                  <Select
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(val)=>{onChangeDepartment(val);SetFilterWorking(false);}}
                //defaultValue={department_id}
                value={department_id}
                key="departmentFilterv2"
                style={{width:"100%"}}
                dropdownClassName={'smallerDrop'}
                mode={"multiple"}
                disabled={type==''}
            >
              {/* <Option value='all' key={"Department All v2"}>Select All</Option>
                {
                  
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
                }):""
                } */}
                            <Option value='all' key={"Department All v2"}>Select All</Option>
              {type=='employee' && department && department.length > 0? department.map((item)=>{
                  return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
              }):type=='daily_worker' && departmentDaily && departmentDaily.length > 0? departmentDaily.map((item)=>{
                return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
            }):""
              }
            </Select>        
                  </Col>


                  <Col lg={2 } className="p-r-0 report-filter">
                  <label>
                        {getTranslatedText("Sub Department")}
                      </label>
                      <Select
                    placeholder="Select Sub Department"
                   
                    style={{width:"100%"}}
                    onChange={(val)=>{onChangeSubDepartment(val);SetFilterWorking(false);}}
                    //defaultValue={'Select Sub Department'}
                    // disabled={department_id.length>1 || department_id.length==0 ?true:false}
                    value={sub_department_id}
                    key="SubdepartmentFilterv2"
                    dropdownClassName={'smallerDrop'}
                    mode="multiple"
                    disabled={department_id.length === 0}
                >
                  <Option value='all' key={"Sub Department All"}>SelectAll</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item} key={"Sub Department"+item}>{item}</Option>
                    }):""
                    }
                </Select>
 </Col>

 <Col lg={report_by.indexOf('Percentage') > -1?1:1} className="report-filter report-filter-less">
                  <label>
                        {getTranslatedText("Filter By")}
                      </label>
                  <Select
                placeholder="Filter By"
                optionFilterProp="children"
                onChange={(val)=>{SetFilterby(val); SetExportEnable(false);SetFilterWorking(false); }  }
                //defaultValue={'Select Department'}
                value={filterby}
                key="filterbyFilter"
                style={{width:"100%"}}
                mode={'multiple'}
                dropdownClassName={'smallerDrop'}
                
            >
              <Option value={'working_hour'} key={"Working Hour"}>{"Working Hour"}</Option>
              <Option value={'wasted_hour'} key={"wasted_hour Hour"}>{"Wasted Hour"}</Option>
              <Option value={'productive'} key={"Producivite Hour"}>{"Producivite Hour"}</Option>
              <Option value={'less_productive'} key={"Less Producivite Hour"}>{"Less Productive Hour"}</Option>
              <Option value={'non_productive'} key={"Non Producivite Hour"}>{"Non Productive Hour"}</Option>
              <Option value={'undefined'} key={"Out of Range Hour"}>{"Out of Range Hour"}</Option>
            </Select>
      
</Col>
<Col lg={2} className="report-filter report-filter-less">
<label>
                        {getTranslatedText("Report By")}
                      </label>
<div className="ant-checkbox-group">
                      <Checkbox defaultChecked={true}  checked={true}>Percentage</Checkbox>
                      <Checkbox defaultChecked={false}  disabled>Time</Checkbox>
                      </div>
                      {/* <Checkbox defaultChecked={false} disabled /> */}
 {/* <Checkbox.Group options={plainOptions} value={report_by} onChange={(val)=> {if(val.length>0){SetReportBy(val)} }} />    */}
 </Col>
 
 {filterby!=='all' && report_by.indexOf('Percentage') > -1 ?<Col lg={10} className='m-t'>
  <div className="mgSpace">
 <label>
    Filter by Min (<strong>{filterSlide[0]}</strong>) and Max (<strong>{filterSlide[1]}</strong>) 
  </label>
  <Slider range  marks={marks} value={filterSlide}   onChange={(val)=>{SetFilterSLide(val);SetFilterWorking(false);}}  />
  </div>
 </Col>:""}


{/* <Col lg={2} className="report-filter">
  <div className="filterMinMax"><label>Min <strong>0</strong></label><input type="number" value={filter_min} className="form-control"onChange={(e)=>SetMin(e.target.value)}/> </div>
  <div className="filterMinMax"><label>Max <strong>100</strong></label><input type="number" value={filter_max} className="form-control"onChange={(e)=>SetMax(e.target.value)}/> </div>
</Col> */}
<Col lg={2} className={filterby!=='all' && report_by.indexOf('Percentage') > -1?'m-t':''}>
  <label>
    Action 
   </label>
            <div className="btndiv">
                  <button className="btn btn-submit btn-filter" onClick={filterResultHandler2} disabled={dates.start=='' || dates.end=='' || department_id=='' || filterby=='' || agency.length ==0}>Submit </button>
                  <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcelReport2}   disabled={!exportEnable} >Export</button>
              </div>
</Col>


                </Row>:
                filterActive=='cyclecount'?
                <Row className="">
                  <Col lg={2} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Employee Type")} 
                    </label>
                <Select
              placeholder="Select Type"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              // onChange={(val)=>{SetType(val);SetDepartmentID([])}}
              onChange={(val)=>{SetType(val);SetDepartmentID([]);SetAgency([]);SetSubDepartmentID([])}}
              //defaultValue={department_id}
              value={type==''?"Select Type":type}
              key="departmentFilterv2"
              style={{width:"100%"}}
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
          >
    
            <Option value='employee' key={"Employee Type All v2 Regular"}>Regular Worker</Option>
            <Option value='daily_worker' key={"Employee Type All v2 Daily"}>Daily Worker</Option>              
          </Select>        
                </Col>
  <Col lg={2} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Agency")} 
                    </label>
 <Select
              placeholder="Select Agency"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{SetAgencyListValue(val)}}
              //defaultValue={department_id}
              value={agency}
              key="departmentFilterv2Agency3"
              style={{width:"100%"}}
                            disabled={type==""}
              dropdownClassName={'smallerDrop'}
                              mode="tags"
              // mode={"multiple"}
          >
    
            <Option value='all' key={"Agency3 All"}>All</Option>
            {
              agencyList.map((item,index)=>{
                return <Option value={item} key={item+index+"1"}>{item}</Option>
              })
            }
            
          </Select> 
                </Col>
                

                <Col lg={2} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Department")} 
                    </label>
                <Select
              placeholder="Select Department"
              optionFilterProp="children"
              onChange={(val)=>{onChangeDepartment(val);SetExportEnableCycle(false) }}
              //defaultValue={department_id}
              value={department_id}
              key="departmentFilterv2"
              style={{width:"100%"}}
              dropdownClassName={'smallerDrop'}
              mode={"multiple"}
              disabled={type==''}
          >
            {/* <Option value='all' key={"Department All v2"}>Select All</Option>
              {
              department && department.length > 0? department.map((item)=>{
                  return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
              }):""
              } */}

<Option value='all' key={"Department All v2"}>Select All</Option>
              {type=='employee' && department && department.length > 0? department.map((item)=>{
                  return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
              }):type=='daily_worker' && departmentDaily && departmentDaily.length > 0? departmentDaily.map((item)=>{
                return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
            }):""
              }
          </Select>        
                </Col>

                <Col lg={2} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Sub Department")}
                    </label>
                    <Select
                  placeholder="Select Sub Department"
                 
                  style={{width:"100%"}}
                  onChange={(val)=>{onChangeSubDepartment(val);SetExportEnableCycle(false)}}
                  //defaultValue={'Select Sub Department'}
                  // disabled={department_id.length>1 || department_id.length==0 ?true:false}
                  value={sub_department_id}
                  key="SubdepartmentFilterv2"
                  dropdownClassName={'smallerDrop'}
                  mode="multiple"
              >
                <Option value='all' key={"Sub Department All"}>SelectAll</Option>
                  {
                  sub_department && sub_department.length > 0? sub_department.map((item)=>{
                      return <Option value={item} key={"Sub Department"+item}>{item}</Option>
                  }):""
                  }
              </Select>
</Col>
<Col lg={3} className="report-filter">
                  <label>
                        {getTranslatedText("Location")}
                      </label>
                  <Select
                placeholder="Select Location"
                optionFilterProp="children"
                onChange={(val)=>{onChangeLocationSelect(val);SetExportEnableCycle(false) }  }
                //defaultValue={'Select Department'}
                value={location_id}
                key="departmentLocationFilterv1"
                style={{width:"100%"}}
                mode="multiple"
                dropdownClassName={'smallerDrop'}
                
            >
            <Option value='all' key={"Location v1 All"}>All</Option>
                {
                location && location.length > 0? location.map((item)=>{
                    return <Option value={item.loc_tag} key={"locationV1"+item.loc_tag}>{item.name}</Option>
                }):""
                }
            </Select>
      
</Col>

<Col lg={2} className="report-filter report-filter-less">
<label>
                        {getTranslatedText("Report By")}
                      </label>
                      <div className="ant-checkbox-group">
                        <Checkbox defaultChecked={true}  checked={cycleFilterSlug=='five_min'?true:false} onChange={(val)=>{SetCycleFilterSlug('five_min');SetFilterEnableCycle(false);SetCycleFilter(0)}}>5 Min</Checkbox>
                        <Checkbox defaultChecked={false} checked={cycleFilterSlug=='one_hour'?true:false} onChange={(val)=>{SetCycleFilterSlug('one_hour');SetFilterEnableCycle(false);SetCycleFilter(3)}}>One Hour</Checkbox>
                      </div>
                      {/* <Checkbox defaultChecked={false} disabled /> */}
 {/* <Checkbox.Group options={plainOptions} value={report_by} onChange={(val)=> {if(val.length>0){SetReportBy(val)} }} />    */}
 </Col>


<Col lg={10} className='m-t'>
<div className={cycleFilterSlug=='five_min'?"mgSpace disabledSlide":"mgSpace"}>
<label>
  Filter by Time Spent (minute)
</label>
<Slider  max={12} disabled={cycleFilterSlug=='five_min'?true:false} marks={marks2} value={cycleFilter} tooltipVisible={false}   onChange={(val)=>{SetCycleFilter(val);SetFilterEnableCycle(false)}}  />
</div>
</Col>


<Col lg={2}>
<label>
  Action 
 </label>
          <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>{SetFilterEnableCycle(true);SetInc(inc+1);}} disabled={dates.start==''  || department_id=='' || location_id.length=== 0}>Submit </button>
                <button className="btn btn-submit btn-filter btn-export" onClick={()=>SetExportEnableCycle(true)}   disabled={!exportEnable} >Export</button>
            </div>
</Col>


              </Row>:                
//                 filterActive=='breaktime'?<Row className="">

// <Col lg={1} className="p-r-0 report-filter">
//                     <label>
//                       {getTranslatedText("Start Date")}
//                       </label>
//                     <div
//                       className="startDateEndDateMainDiv"
//                       style={{ paddingLeft: "0px" }}
//                     >
//                       <DatePicker
//                         selected={dates.start}
//                         onChange={(date) => {handleDate(date, "start");SetAgency([]);SetBreakTimeExport(false);SetBreakTimeFilter(false) }}
//                         dateFormat={"MMM dd"}
//                         isClearable={false}
//                         placeholderText={getTranslatedText("Start Date")}
//                         maxDate={moment(dates.end).toDate()}
//                         // minDate={new Date('2023-01-01')}
//                                                 minDate={new Date('2023-01-01')}
                                                
//                       />
                   
//                     </div>
//                   </Col>
//                   <Col lg={1} className="p-r-0 report-filter">
//                       <label>
//                         {getTranslatedText("End Date")}
//                       </label>
//                     <div
//                       className="startDateEndDateMainDiv"
//                       style={{ paddingLeft: "0px" }}
//                     >
//                       <DatePicker
//                         selected={moment(dates.end).toDate()}
//                         onChange={(date) =>{ handleDate(date, "end");SetAgency([]);SetBreakTimeExport(false);SetBreakTimeFilter(false) }}
//                         dateFormat={"MMM dd"}
//                         isClearable={false}
//                         placeholderText={getTranslatedText("End Date")}
//                         maxDate={selectedDate}
//                         minDate={dates.start}
                        
//                       />
                   
//                     </div>
//                   </Col>

//                   <Col lg={2} className="p-r-0 report-filter">
//                 <label>
//                       {getTranslatedText("Employee Type")} 
//                     </label>
//                 <Select
//               placeholder="Select Type"
//               optionFilterProp="children"
//               //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
//               // onChange={(val)=>{SetType(val);SetDepartmentID([])}}
//                             onChange={(val)=>{SetType(val);SetDepartmentID([]);SetAgency([]);SetSubDepartmentID([])}}
//               //defaultValue={department_id}
//               value={type==''?"Select Type":type}
//               key="departmentFilterv2"
//               style={{width:"100%"}}
//               dropdownClassName={'smallerDrop'}
//               // mode={"multiple"}
//           >
    
//             <Option value='employee' key={"Employee Type All v2 Regular"}>Regular Worker</Option>
//             <Option value='daily_worker' key={"Employee Type All v2 Daily"}>Daily Worker</Option>              
//           </Select>        
//                 </Col>
//                   <Col lg={2} className="p-r-0 report-filter">
//                 <label>
//                       {getTranslatedText("Agency")} 
//                     </label>
//                  <Select
//               placeholder="Select Agency"
//               optionFilterProp="children"
//                            disabled={type==""}
//               onChange={(val)=>{SetAgencyListValue(val)}}
//               //defaultValue={department_id}
//               value={agency}
//               key="departmentFilterv2Agency3"
//               style={{width:"100%"}}
//                               mode="tags"
//               dropdownClassName={'smallerDrop'}
//               // mode={"multiple"}
//           >
    
//             <Option value='all' key={"Agency3 All"}>All</Option>
//             {
//               agencyList.map((item,index)=>{
//                 return <Option value={item} key={item+index+"1"}>{item}</Option>
//               })
//             }
            
//           </Select>        
//                 </Col>
                


//                 <Col lg={3} className="p-r-0 report-filter">
//                 <label>
//                       {getTranslatedText("Department")} 
//                     </label>
//                 <Select
//               placeholder="Select Department"
//               optionFilterProp="children"
//               onChange={(val)=>{onChangeDepartment(val);SetBreakTimeExport(false);SetBreakTimeFilter(false)}}
//               //defaultValue={department_id}
//               value={department_id}
//               key="departmentFilterv2"
//               style={{width:"100%"}}
//               dropdownClassName={'smallerDrop'}
//               mode={"multiple"}
//               disabled={type==''}
//           >
//             <Option value='all' key={"Department All v2"}>Select All</Option>
//               {type=='employee' && department && department.length > 0? department.map((item)=>{
//                   return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
//               }):type=='daily_worker' && departmentDaily && departmentDaily.length > 0? departmentDaily.map((item)=>{
//                 return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
//             }):""
//               }
//           </Select>        
//                 </Col>

//                 <Col lg={3} className="report-filter">
//                 <label>
//                       {getTranslatedText("Sub Department")}
//                     </label>
//                     <Select
//                   placeholder="Select Sub Department"
                 
//                   style={{width:"100%"}}
//                   onChange={(val)=>{onChangeSubDepartment(val);SetBreakTimeExport(false);SetBreakTimeFilter(false)}}
//                   //defaultValue={'Select Sub Department'}
//                   // disabled={department_id.length>1 || department_id.length==0 ?true:false}
//                   value={sub_department_id}
//                   key="SubdepartmentFilterv2"
//                   dropdownClassName={'smallerDrop'}
//                   mode="multiple"
//                   disabled={department_id.length== 0}
//               >
//                 <Option value='all' key={"Sub Department All"}>SelectAll</Option>
//                   {
//                   sub_department && sub_department.length > 0? sub_department.map((item)=>{
//                       return <Option value={item} key={"Sub Department"+item}>{item}</Option>
//                   }):""
//                   }
//               </Select>
// </Col>
// <Col lg={10} className='m-t'>
// <div className={"mgSpace"}>
// <label>
//   Threeshold (minute)
// </label>
// <Slider  max={12}  marks={marks2} value={cycleFilter} tooltipVisible={false}   onChange={(val)=>{SetCycleFilter(val);SetBreakTimeExport(false);SetBreakTimeFilter(false)}}  />
// </div>
// </Col>


// <Col lg={2}>
// <label>
//   Action 
//  </label>
//           <div className="btndiv">
//                 <button className="btn btn-submit btn-filter" onClick={()=>{SetBreakTimeFilter(true);SetInc(inc+1);}}
//                 disabled={ department_id.length==0 || dates.start==''|| dates.end=='' }
                 
//                 >Submit</button>
//                 <button className="btn btn-submit btn-filter btn-export" onClick={()=>SetBreakTimeExport(true)}   disabled={!breakTimeFilter} >Export</button>
//             </div>
// </Col>


//               </Row>:
              filterActive==='performance'?
              <Row className="">
                  <Col lg={1} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);SetFilterPerformance(false) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Start Date")}
                        maxDate={moment(dates.end).toDate()}
                        //minDate={moment().subtract(120, "days").toDate()}
                                                minDate={new Date('2023-01-01')}
                                                
                        
                      />
                      
                    </div>
                  </Col>
                  <Col lg={1} className="p-r-0 report-filter">
                      <label>
                        {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={moment(dates.end).toDate()}
                        onChange={(date) =>{ handleDate(date, "end");SetExportEnable(false);SetFilterPerformance(false)  }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("End Date")}
                        maxDate={selectedDate}
                        minDate={dates.start}
                        
                      />
                      {/* <div className="dropdownIconDiv">
                        <img src={dropdownIcon} />
                      </div> */}
                    </div>
                  </Col>

                  <Col lg={2} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Employee Type")} 
                    </label>
                <Select
              placeholder="Select Type"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              // onChange={(val)=>{SetType(val);SetDepartmentID([])}}
                            onChange={(val)=>{SetType(val);SetDepartmentID([]);SetAgency([]);SetSubDepartmentID([]);SetExportEnable(false);SetFilterPerformance(false) }}
              //defaultValue={department_id}
              value={type==''?"Select Type":type}
              key="departmentFilterv2"
              style={{width:"100%"}}
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
          >
    
            <Option value='employee' key={"Employee Type All v2 Regular"}>Regular Worker</Option>
            <Option value='daily_worker' key={"Employee Type All v2 Daily"}>Daily Worker</Option>              
          </Select>        
                </Col>
                  <Col lg={2} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Agency")} 
                    </label>
                <Select
              placeholder="Select Agency"
                            disabled={type==""}
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{SetAgencyListValue(val)}}
              //defaultValue={department_id}
              value={agency}
              key="departmentFilterv2Agency2"
              style={{width:"100%"}}
              dropdownClassName={'smallerDrop'}
                              mode="tags"
              // mode={"multiple"}
          >
    
            <Option value='all' key={"Agency2 All"}>All</Option>
            {
              agencyList.map((item,index)=>{
                return <Option value={item} key={item+index+"1"}>{item}</Option>
              })
            }
            
          </Select>        
                </Col>
                

                  <Col lg={2} className="p-r-0 report-filter">
                  <label>
                        {getTranslatedText("Department")} 
                      </label>
                  <Select
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(val)=>{onChangeDepartment(val);SetExportEnable(false);SetFilterPerformance(false)}}
                //defaultValue={department_id}
                value={department_id}
                key="departmentFilterv2"
                style={{width:"100%"}}
                dropdownClassName={'smallerDrop'}
                mode={"multiple"}
                disabled={type==''}
            >
              {/* <Option value='all' key={"Department All v2"}>Select All</Option>
                {
                  
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
                }):""
                } */}
                            <Option value='all' key={"Department All v2"}>Select All</Option>
              {type=='employee' && department && department.length > 0? department.map((item)=>{
                  return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
              }):type=='daily_worker' && departmentDaily && departmentDaily.length > 0? departmentDaily.map((item)=>{
                return <Option value={item.name} key={"Department v2"+item.name}>{item.name}</Option>
            }):""
              }
            </Select>        
                  </Col>

                  <Col lg={2} className="p-r-0 report-filter">
                  <label>
                        {getTranslatedText("Sub Department")}
                      </label>
                      <Select
                    placeholder="Select Sub Department"
                   
                    style={{width:"100%"}}
                    onChange={(val)=>{onChangeSubDepartment(val);SetExportEnable(false);SetFilterPerformance(false)}}
                    //defaultValue={'Select Sub Department'}
                    // disabled={department_id.length>1 || department_id.length==0 ?true:false}
                    value={sub_department_id}
                    key="SubdepartmentFilterv2"
                    dropdownClassName={'smallerDrop'}
                    mode="multiple"
                    disabled={department_id.length === 0}
                >
                  <Option value='all' key={"Sub Department All"}>SelectAll</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item} key={"Sub Department"+item}>{item}</Option>
                    }):""
                    }
                </Select>
 </Col>
 <Col lg={2} className="report-filter report-filter-less">
<label>
                        {getTranslatedText("Report By")}
                      </label>
                  <div className="ant-checkbox-group">
                      <Checkbox defaultChecked={reportPerformance=='month'?true:false}  checked={reportPerformance=='month'?true:false} onChange={()=>{SetReportPerformance('month');SetExportEnable(false);SetFilterPerformance(false)}} >Month</Checkbox>
                      <Checkbox defaultChecked={reportPerformance=='week'?true:false} checked={reportPerformance=='week'?true:false} onChange={()=>{SetReportPerformance('week');SetExportEnable(false);SetFilterPerformance(false)}}>Week</Checkbox>
                      </div>
                      {/* <Checkbox defaultChecked={false} disabled /> */}
 {/* <Checkbox.Group options={plainOptions} value={report_by} onChange={(val)=> {if(val.length>0){SetReportBy(val)} }} />    */}
 </Col>

<Col lg={2}>
  <label>
    Action 
   </label>
            <div className="btndiv">

                  <button className="btn btn-submit btn-filter" onClick={()=>{SetFilterPerformance(true);SetInc(inc+1);SetExportEnable(false)}} disabled={dates.start=='' || dates.end=='' || department_id=='' || agency.length == 0}>Submit</button>
                  <button className="btn btn-submit btn-filter btn-export" onClick={()=>SetFilterPerformanceExport(true)}   disabled={!filterPerformance || !exportEnable} >Export</button>
              </div>
</Col>


                </Row>
              :
                filterActive=='governance'?
                 <Row className="">
                  <Col lg={1} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);SetFilterPerformance(false);SetButtonReady(false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Start Date")}
                        maxDate={moment(dates.end).toDate()}
                        //minDate={moment().subtract(120, "days").toDate()}
                                                minDate={new Date('2023-01-01')}
                                                
                        
                      />
                      
                    </div>
                  </Col>
                  <Col lg={1} className="p-r-0 report-filter">
                      <label>
                        {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={moment(dates.end).toDate()}
                        onChange={(date) =>{ handleDate(date, "end");SetExportEnable(false);SetFilterPerformance(false);SetButtonReady(false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("End Date")}
                        maxDate={selectedDate}
                        minDate={dates.start}
                        
                      />
                    </div>
                  </Col>

                  <Col lg={2} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Employee Type")} 
                    </label>
                <Select
              placeholder="Select Type"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              // onChange={(val)=>{SetType(val);SetDepartmentID([])}}
                            onChange={(val)=>{SetTypeGovernance(val);SetFilterPerformance(false);}}
              //defaultValue={department_id}
              value={typeGovernance}
             
              key="departmentFilterv2"
              style={{width:"100%"}}
              mode="multiple"
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
          >
    
            <Option value='employee' key={"Employee Type All v2 Regular"}>Regular Worker</Option>
            <Option value='daily_worker' key={"Employee Type All v2 Daily"}>Daily Worker</Option>              
          </Select>        
                </Col>

<Col lg={4} className='' style={{paddingRight:"0px"}}>
  <div className="mgSpace">
 <label>
    Performance Limit 
  </label>
  <Slider range  marks={marks} value={filterSlide}   onChange={(val)=>{SetFilterSLide(val);SetFilterPerformance(false)}}  />
  </div>
 </Col>            
 <Col lg={2} className="report-filter report-filter-less">
<label>
                        {getTranslatedText("Report By")}
                      </label>
                  <div className="ant-checkbox-group">
                      <Checkbox defaultChecked={reportPerformance=='month'?true:false}  checked={reportPerformance=='month'?true:false} onChange={()=>{SetReportPerformance('month');SetFilterPerformance(false)}} >Month</Checkbox>
                      <Checkbox defaultChecked={reportPerformance=='week'?true:false} checked={reportPerformance=='week'?true:false} onChange={()=>{SetReportPerformance('week');SetFilterPerformance(false)}}>Week</Checkbox>
                      </div>
                      {/* <Checkbox defaultChecked={false} disabled /> */}
 {/* <Checkbox.Group options={plainOptions} value={report_by} onChange={(val)=> {if(val.length>0){SetReportBy(val)} }} />    */}
 </Col>

<Col lg={2}>
  <label>
    Action 
   </label>
   
            <div className="btndiv">
                  <button className="btn btn-submit btn-filter" onClick={()=>{SetFilterPerformance(true);SetInc(inc+1);SetExportEnable(false)}} disabled={dates.start=='' || dates.end=='' || typeGovernance.length===0}>Submit</button>
                  <button className="btn btn-submit btn-filter btn-export" onClick={()=>SetFilterPerformanceExport(true)}   disabled={!filterPerformance|| !exportEnable} >Export</button>
              </div>
</Col>



{/* <button className="form-control" onClick={()=>SendEmail()}> Send Email</button> */}

                </Row>:filterActive=="attendance"?
                <Row className="">
                  <Col lg={1} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);SetFilterPerformance(false);SetButtonReady(false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Start Date")}
                        maxDate={moment(dates.end).toDate()}
                        //minDate={moment().subtract(120, "days").toDate()}
                                                minDate={new Date('2023-01-01')}
                                                
                        
                      />
                      
                    </div>
                  </Col>
                  <Col lg={1} className="p-r-0 report-filter">
                      <label>
                        {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={moment(dates.end).toDate()}
                        onChange={(date) =>{ handleDate(date, "end");SetExportEnable(false);SetFilterPerformance(false);SetButtonReady(false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("End Date")}
                        maxDate={selectedDate}
                        minDate={dates.start}
                        
                      />
                    </div>
                  </Col>

                  <Col lg={2} className="p-r-0 report-filter">
                <label>
                      {getTranslatedText("Employee Type")} 
                    </label>
                <Select
              placeholder="Select Type"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              // onChange={(val)=>{SetType(val);SetDepartmentID([])}}
                            onChange={(val)=>{SetTypeGovernance(val);SetFilterPerformance(false);}}
              //defaultValue={department_id}
              value={typeGovernance}
             
              key="departmentFilterv2"
              style={{width:"100%"}}
              mode="multiple"
              dropdownClassName={'smallerDrop'}
              // mode={"multiple"}
          >
    
            <Option value='employee' key={"Employee Type All v2 Regular"}>Regular Worker</Option>
            <Option value='daily_worker' key={"Employee Type All v2 Daily"}>Daily Worker</Option>              
          </Select>        
                </Col>

<Col lg={4} className='' style={{paddingRight:"0px"}}>
  <div className="mgSpace">
 <label>
    Performance Limit 
  </label>
  <Slider range  marks={marks} value={filterSlide}   onChange={(val)=>{SetFilterSLide(val);SetFilterPerformance(false)}}  />
  </div>
 </Col>            
 <Col lg={2} className="report-filter report-filter-less">
<label>
                        {getTranslatedText("Report By")}
                      </label>
                  <div className="ant-checkbox-group">
                      <Checkbox defaultChecked={reportPerformance=='month'?true:false}  checked={reportPerformance=='month'?true:false} onChange={()=>{SetReportPerformance('month');SetFilterPerformance(false)}} >Month</Checkbox>
                      <Checkbox defaultChecked={reportPerformance=='week'?true:false} checked={reportPerformance=='week'?true:false} onChange={()=>{SetReportPerformance('week');SetFilterPerformance(false)}}>Week</Checkbox>
                      </div>
                      {/* <Checkbox defaultChecked={false} disabled /> */}
 {/* <Checkbox.Group options={plainOptions} value={report_by} onChange={(val)=> {if(val.length>0){SetReportBy(val)} }} />    */}
 </Col>

<Col lg={2}>
  <label>
    Action 
   </label>   
            <div className="btndiv">
                  <button className="btn btn-submit btn-filter" onClick={()=>{SetFilterPerformance(true);SetInc(inc+1);SetExportEnable(false)}} disabled={dates.start=='' || dates.end=='' || typeGovernance.length===0}>Submit</button>
                  <button className="btn btn-submit btn-filter btn-export" onClick={()=>SetFilterPerformanceExport(true)}   disabled={!filterPerformance || !exportEnable} >Export</button>
              </div>
</Col>



{/* <button className="form-control" onClick={()=>SendEmail()}> Send Email</button> */}

                </Row>:
                filterActive==="planactual"?<PlanActualReportFilter workday={workday} department={[...department,...departmentDaily]} employee={employeeAll} date={selectedDate} dataReloadOnboard={dataReloadOnboardPlanActual} filterResultHandler={FIlterResultHandlerPlanActual} exportEnable={()=>SetExportEnable(true)} />:
                filterActive==="movement_planactual"?<MovementPlanActualFilter workday={workday} department={[...department,...departmentDaily]} employee={employeeAll} date={selectedDate} dataReloadOnboard={dataReloadOnboard} filterResultHandler={FIlterResultHandlerPlanActual} exportEnable={()=>SetExportEnable(true)} />:
                filterActive==="attendance_time"?<AttendanceTimeFilter workday={workday}  date={selectedDate} filterResultHandler={FIlterResultHandlerPlanActual} exportEnable={()=>SetExportEnable(true)} />:
                filterActive==="report_usecase"?<UseCaseFilter workday={workday}  date={selectedDate} filterResultHandler={FIlterResultHandlerPlanActual} exportEnable={()=>SetExportEnable(true)} />:
                filterActive==="location_time"?<LocationTimeFilter workday={workday}  date={selectedDate} filterResultHandler={FIlterResultHandlerPlanActual} exportEnable={()=>SetExportEnable(true)} />:
                filterActive==="pleton"?<PletonWorkerFilter dataReloadOnboardPlanActual={dataReloadOnboardPlanActual} workday={workday}  date={selectedDate} filterResultHandler={FIlterResultHandlerPlanActual} exportEnable={()=>SetExportEnable(true)} employee={[...employeeAllDaily,...employeeAll]} pletonLoad={pletonLoad} pletonLoaddOff={()=>SetPletonLoad(false)} pletonLoadOn={()=>SetPletonLoad(true)}
                />:
                <AutomatedFOPFilter filterResultHandler={FIlterResultHandlerFOP} />
                
                }
                </div> 

                </div>
          </Col>
        </Row>
        < Row className="m-t">
                    <Col lg={12} className={props.hideHeading ? 'p-l-0 p-r-0' : ''}>
                        <div className="listingRecordMainDiv manpowerManagementEmployeeListMainDiv" ref={elementRef}>
                        
                            {isLoading?
                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div> :
                             filterActive==="pleton" && pletonLoad?<div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:   
                                
                                isLoading2?
                                  // <div className="text-center loader-filter m-t-lg">
                                  //     <img src={spinnerLoader} className="m-t-lg" />
                                  //     <div className="loadingInfo">
                                  //       <h4>
                                  //           <span>Please wait</span>
                                  //            <span className="loading-dots">
                                  //                   <div className="loading-dots--dot"></div>
                                  //                   <div className="loading-dots--dot"></div>
                                  //                   <div className="loading-dots--dot"></div>
                                  //             </span>
                                  //         </h4> 
                                  //       <div className="loadingInner">Anlysis of date <strong className="pink">{dateCount}</strong> <br/>
                                  //       <strong className="pink">{count}</strong> out of <strong className="pink">{totalcount}</strong> is in progress</div>
  
                                  //     </div>
                                        
                                  // </div> 
                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>
                                  
                                  :
                                <React.Fragment>
                                    {isLoading3?
                                    // <div className="loadingInfo loadingInfo2">
                                    //     <h4>
                                    //         <span>Please wait</span>
                                    //          <span className="loading-dots">
                                    //                 <div className="loading-dots--dot"></div>
                                    //                 <div className="loading-dots--dot"></div>
                                    //                 <div className="loading-dots--dot"></div>
                                    //           </span>
                                    //       </h4> 
                                    //     <div className="loadingInner">Anlysis of date <strong className="pink">{dateCount}</strong> <br/>
                                    //     {filterActive=='location'?<><strong className="pink">{count}</strong> out of <strong className="pink">{totalcount}</strong> is in progress</>:<><strong className="pink">Data Analaysis</strong> is in progress</>}</div>
                                    // </div>
                                    <div className="text-center m-t-lg">
                                      <img src={spinnerLoader} className="m-t-lg" />
                                  </div>
                                      :""}
                            
                                   
                              <div className="rportArea">
                              {errorMessage}
                               {!exportEnable && filterWorking && filterActive=='working'?
                                    <div className="loadingInfo loadingInfo2">
                                        <h4>
                                            <span>Please wait</span>
                                             <span className="loading-dots">
                                                    <div className="loading-dots--dot"></div>
                                                    <div className="loading-dots--dot"></div>
                                                    <div className="loading-dots--dot"></div>
                                              </span>
                                          </h4> 
                                        <div className="loadingInner">Anlysis of date <strong className="pink">{dateCount}</strong> <br/>
                                        {filterActive=='location'?<><strong className="pink">{count}</strong> out of <strong className="pink">{totalcount}</strong> is in progress</>:<><strong className="pink">Data Analaysis</strong> is in progress</>}</div>
                                    </div>:""}
                                { !isLoading3 && exportEnable  && filterWorking && filterActive=='working'?
                                <div className="reportChart">
                                  <div className="accordionHeader" onClick={()=>{SetChart1(!chart1);SetChartLoader(false); }}>                                      
                                      {chart1?<Minus/>:<Plus/>}Chart Compare by <span style={{color:"#ef5e8c"}}>Function</span>
                                 </div>
                                 {chart1?<div className="accordionContentReport">
                                  <div className="buttonFilterArea">
                                  <button className={activeChart==''?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>SetActiveChart('')}>All</button>
                              {filterbyCoumn.map(item =>{
                                let text= 'Working Hour';

                                if(item =='wasted_hour'){
                                  text= 'Wasted Hour';
                                }else if(item =='productive'){
                                  text= 'Productive Hour';
                                }
                                else if(item =='less_productive'){
                                  text= 'Less Productive';
                                }
                                else if(item =='non_productive'){
                                  text= 'Non Productive';
                                }
                                else if(item =='undefined'){
                                  text= 'Out of Range';
                                }
                                return <button key={"itemFilter1"+item} className={activeChart==item?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>SetActiveChart(item)}>{text} </button>
                              })}</div>
{chartLoader?
<div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
                                   <div style={{minHeight:"300px"}}><ChartReport chartData={chartData} SetLegendEnable close={()=>SetChart1(false)} key={"reportV1"} keys={"reportV1"} legendEnable={activeChart==""?false:true} /></div>}
                                 </div>:""}


                                 </div>:""}

{/* Chart 2 UI */}
{!isLoading3 && exportEnable && filterWorking  && filterActive=='working'?
<div className="reportChart" style={{marginTop:"20px"}}>
                                  <div className="accordionHeader" onClick={()=>{SetChart2(!chart2);SetChartLoader2(false); }}>                                      
                                      {chart2?<Minus/>:<Plus/>}Chart Compare by <span style={{color:"#ef5e8c"}}>Work Status</span>
                                 </div>
                                 {chart2?<div className="accordionContentReport">
                                  <div className="buttonFilterArea">

                                 
                                  <button className={activeChart2==''?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>SetActiveChart2('')}>All</button>
                              {filterBtn.map(item =>{
                              
                                return <button key={"itemFilter2"+item} className={activeChart2==item?'buttonChartFilter buttonChartFilterActive button btn':'buttonChartFilter button btn'} onClick={()=>SetActiveChart2(item)}>{item} </button>
                              })}</div>
{chartLoader2?
<div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
                                   <div style={{minHeight:"300px"}} id="chartt2"><ChartReport chartData={chartData2} close={()=>SetChart1(false)} key={"reportV2"} keys={"reportV2"} legendEnable={activeChart2==""?false:true} /></div>}
                                 </div>:""}
                                 

                                 </div>:""}
</div>

{filterActive=='cyclecount'?
<CycleCount 
                role={props.role}
                exportEnableDisable={exportEnableDisable}
                filterEnableDisable={filterEnableDisable}
                cycleFilter={cycleFilter*5}
                cycleFilterSlug={cycleFilterSlug}
                locationList={location}
                location_id={location_id}
                start_date={selectedDate}
                end_date={dates.end}
                department_id= {department_id}
                sub_department_id= {sub_department_id}
                filterEnableCycle ={filterEnableCycle}
                exportEnableCycle = {exportEnableCycle}
                exportProcessExist={exportProcessExist}
                inc={inc}
                type={type}
                                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}

              />:
            //   filterActive=='breaktime'?
            //   <BreakTimeAnalysis 
            //   role={props.role}
            //   //type={type}
            //   type={type}
            //   exportEnableDisable={exportEnableDisableBreakTime}
            //   filterEnableDisable={filterEnableDisableBreakTime}
            //   cycleFilter={cycleFilter==0?1:cycleFilter*5}
            //   cycleFilterSlug={'one_hour'}
            //   start_date={dates.start}   
            //   end_date={dates.end}
            //   department_id= {department_id}
            //   sub_department_id= {sub_department_id}
            //   filterEnableCycle={breakTimeFilter}
            //   exportEnableCycle = {breaktTimeExport}
            //   exportProcessExist={exportProcessExistBreakTime}
            //   inc={inc}
            //   agency={agency}
            //   agencyList ={agencyList}
            //   employee={type == 'employee'?employeeAll:employeeAllDaily}
            //   isWeekday={isWeekday}
            //                   weekDays={props.weekDays}
            //     holiday={props.holiday}
// workday={props.workday}

            //   //type={type}
            // />:
            filterActive=='performance'?
              <Performance
                role={props.role}
                //type={type}
                 workday={workday}
                type={type}
                exportEnableDisable={exportEnableDisablePerformance}
                filterEnableDisable={filterEnableDisablePerformance}
                start_date={dates.start}   
                end_date={dates.end}
                department_id= {department_id}
                sub_department_id= {sub_department_id}
                filterEnableCycle={filterPerformance}
                exportEnableCycle = {filterPerformanceExport}
                exportProcessExist={exportProcessExistPerformance}
                inc={inc}
                reportPerformance={reportPerformance}
                widthContainer={widthContainer}
                agency={agency}
                agencyList ={agencyList}
                employee={type == 'employee'?employeeAll:employeeAllDaily}
                 isWeekday={isWeekday}
                                 weekDays={props.weekDays}
                holiday={props.holiday}
                 exportEnableFunction={()=>SetExportEnable(true)}

              />:filterActive=='governance'?
              <Governance
                role={props.role}
                //type={type}
                 workday={workday}
                type={typeGovernance}
                emailModal={emailModal}
                closeEmailModal={closeEmailModal}
                exportEnableDisable={exportEnableDisablePerformance}
                filterEnableDisable={filterEnableDisablePerformance}
                start_date={dates.start}   
                end_date={dates.end}
                department_id= {department_id}
                sub_department_id= {sub_department_id}
                filterEnableCycle={filterPerformance}
                exportEnableCycle = {filterPerformanceExport}
                exportProcessExist={exportProcessExistPerformance}
                inc={inc}
                reportPerformance={reportPerformance}
                widthContainer={widthContainer}
                agency={agency}
                agencyList ={agencyList}
                threeshold= {filterSlide}
                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                department={[...department,...departmentDaily]}
                //onBoardDetail={onBoardDetail}
                employee={[...employeeAll.map(item=>({...item,emp_type:"employee" })),...employeeAllDaily.map(item=> ({...item, emp_type:"daily_worker" }))]}
                 isWeekday={isWeekday}
                 exportEnableFunction={()=>SetExportEnable(true)}
                 
              />
              :filterActive=='attendance'?
              <Attendance
                role={props.role}
                //type={type}
                 workday={workday}
                type={typeGovernance}
                exportEnableDisable={exportEnableDisablePerformance}
                filterEnableDisable={filterEnableDisablePerformance}
                start_date={dates.start}   
                end_date={dates.end}
                department_id= {department_id}
                sub_department_id= {sub_department_id}
                filterEnableCycle={filterPerformance}
                exportEnableCycle = {filterPerformanceExport}
                exportProcessExist={exportProcessExistPerformance}
                inc={inc}
                reportPerformance={reportPerformance}
                widthContainer={widthContainer}
                agency={agency}
                agencyList ={agencyList}
                threeshold= {filterSlide}
                weekDays={props.weekDays}
                holiday={props.holiday}
                department={[...department,...departmentDaily]}
                //onBoardDetail={onBoardDetail}
                employee={[...employeeAll.map(item=>({...item,emp_type:"employee" })),...employeeAllDaily.map(item=> ({...item, emp_type:"daily_worker" }))]}
                 isWeekday={isWeekday}
                 exportEnableFunction={()=>SetExportEnable(true)}
              />
              :filterActive=='planactual'?
              <PlanActualReport widthContainer={widthContainer}  workday={workday} filterActive={filterPlanActual} filterPassData={filterPassData} exportEnable={exportEnable} exportFalseFOP={()=>SetExportEnable(false)}/>:
              filterActive=='movement_planactual'?
              <MovementPlanActual widthContainer={widthContainer}  workday={workday} filterActive={filterPlanActual} filterPassData={filterPassData} exportEnable={exportEnable} exportFalseFOP={()=>SetExportEnable(false)}/>
              :
              filterActive=='attendance_time'?
              <AttendanceTime widthContainer={widthContainer}  workday={workday} filterActive={filterPlanActual} filterPassData={filterPassData} exportEnable={exportEnable} exportFalseFOP={()=>SetExportEnable(false)}/>:
              filterActive=='report_usecase'?
              <ReportByUseCase widthContainer={widthContainer}  workday={workday} filterActive={filterPlanActual} filterPassData={filterPassData} exportEnable={exportEnable} exportFalseFOP={()=>SetExportEnable(false)}/>
              :
 filterActive=='location_time'? 
              <LocationTime widthContainer={widthContainer}  workday={workday} filterActive={filterPlanActual} filterPassData={filterPassData} exportEnable={exportEnable} exportFalseFOP={()=>SetExportEnable(false)} />              
              :
              filterActive==="pleton"?<PletonWorker widthContainer={widthContainer}  workday={workday} filterActive={filterPlanActual} filterPassData={filterPassData} exportEnable={exportEnable} exportFalseFOP={()=>SetExportEnable(false)} employee={[...employeeAllDaily,...employeeAll]}/>:              
              filterActive=="automatedfop"?<AutomatedFOP  workday={workday} filterActive={filterFOP} filterPassData={filterPassData}/>:
              filterWorking?<div style={{marginTop:"20px"}}>
            {showDataDefault}
         </div>:""}    


                                </React.Fragment> 
                            }

                        </div>
                    </Col>
                </Row>
      </Container>


      
    </div>:<Col><div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
            </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                    <img src={spinnerLoader} />
                                </Col>}</>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(ReportExport)
);
