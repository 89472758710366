import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import '../../manPowerManagement/style/manpowerManagement.scss';

import CommonDatePicker from "../../common/commonDatePicker";


import moment from "moment";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";
import ReactModal from "react-modal";
import MapComponent from "./MapBox";

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
  },
};

function MapBox(props) {
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");

  const [PriData, updatePRIData] = useState("");
  const [prevPriData, updatePrevPriData] = useState("");

  const [selectedTab, updatedSelectedTab] = useState("employees");

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null

  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }






  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);


  const handleDate = (date, type) => {
   // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
  };
  function changeLanguage(lang) {
    getLanguageTranslation(lang).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }

  return (
    <div className="manpowerManagementMainDiv">
      <Container className="header-breadcrumb">
        <Row>
          <Col lg={6}>
            <CommonHeading title="Manpower Management" />
          </Col>
          <Col lg={6} className="text-right">
            <div className="commonLangaugeStyleDiv">
              <DashboardLanguage
                selectedLangValue={selectedLangValue}
                changeLanguage={changeLanguage}
              />
            </div>

            <div className="commonHeadingDateMainDivParent">
              <CommonDatePicker
                selectedDate={selectedDate}
                handleSelectDate={handleDateSelect}
                weekDays={props.weekDays}
                holiday={props.holiday}
                workday={props.workday}
              />
            </div>
          </Col>
        </Row>




        <Row className="m-t">
          <Col lg={12}>
            <MapComponent />
          </Col>
        </Row>
      </Container>


    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(MapBox)
);
