import axios from "axios";

let prefixURL = process.env.REACT_APP_URL_LOGIN
let API_KEY = process.env.REACT_APP_CHATGPTAPI
let prefixURL2 = process.env.REACT_APP_URL

let token = localStorage.getItem('tokenAuthTrace')?localStorage.getItem('tokenAuthTrace'):'tituw3958589'



export function getBreakTimeReport( requestBody, sessionID, org_id,sub_org_id) {
    return axios.get(prefixURL2 + `/get_breaktime_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${requestBody.start_date}&end_date=${requestBody.end_date}&treshold=${requestBody.treshold}&slug=${requestBody.slug}`)
        .then(res => res.data).catch(err => err)
}

export function getDashboardReportOverview( requestBody, sessionID, org_id,sub_org_id) {
    return axios.get(prefixURL2 + `/management_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&employee_details_start_date=${requestBody.start_date}&employee_details_end_date=${requestBody.end_date}&agency=${requestBody.agency}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}
export function getPerformanceHistory( requestBody, sessionID, org_id,sub_org_id) {
    return axios.get(prefixURL2 + `/get_productivity_history?session=${sessionID}&org_id=${org_id}&slug=${requestBody.slug}&sub_org_id=${sub_org_id}&start_date=${requestBody.start_date}&end_date=${requestBody.end_date}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}
export function getOnBoardDetail(  sessionID, org_id,sub_org_id) {
    return axios.get(prefixURL2 + `/onboarding/get_onboarding_details?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}
export function testingEmail(body){
    return axios.post( `https://traceplus.ai/wp-json/custom/v1/send-email`,body)
        .then(res => res).catch(err => err)
}

export function getFopReportFilter(requestBody, sessionID, org_id,sub_org_id){
    return axios.get(prefixURL2 + `/fop_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&date=${requestBody.date}&productivity=${requestBody.productivity}&volume=${requestBody.volume}`)
        .then(res => res.data).catch(err => err)
}

export function getPastReportData(date, sessionID, org_id,sub_org_id){
    return axios.get(prefixURL2 + `/employee/get_employees_attendance_day_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&employee_details_datetime=${date}`)
        .then(res => res.data).catch(err => err)
}


export function getProcessCyclecount(sessionID, org_id,sub_org_id,start_date,end_date){
    return axios.get(prefixURL2 + `/get_process_cyclecount?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}`)
        .then(res => res.data).catch(err => err)
}

export function getProcessList(sessionID, org_id,sub_org_id,start_date,end_date){
    return axios.get(prefixURL2 + `/get_process_list_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}`)
        .then(res => res.data).catch(err => err)
}

export function getPlanAttendanceReport(sessionID, org_id,sub_org_id,start_date,end_date){
    return axios.get(prefixURL2 + `/get_plan_attendance_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}`)
        .then(res => res.data).catch(err => err)
}

export function getAttendanceTime(sessionID, org_id,sub_org_id,start_date,end_date,checkin_filter,checkout_filter){
    return axios.get(prefixURL2 + `/attendance_filter?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}&checkin_filter=${checkin_filter}&checkout_filter=${checkout_filter}`)
        .then(res => res.data).catch(err => err)
}

export function getOverallProcessReport(sessionID, org_id,sub_org_id,start_date,end_date,threeshold,slug){
    return axios.get(prefixURL2 + `/overall_process_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}&performance_threshold=${threeshold}&slug=${slug}`)
        .then(res => res.data).catch(err => err)
}

export function getOverallPerformanceReport(sessionID, org_id,sub_org_id,start_date,end_date,threeshold,slug){
    return axios.get(prefixURL2 + `/get_overall_performance_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}&performance_threshold=${threeshold}&slug=${slug}`)
        .then(res => res.data).catch(err => err)
}

export function getOverallAttendanceReport(sessionID, org_id,sub_org_id,start_date,end_date,threeshold,slug){
    return axios.get(prefixURL2 + `/get_overall_attendance_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}&performance_threshold=${threeshold}&slug=${slug}`)
        .then(res => res.data).catch(err => err)
}
export function getRegularEmployeeMap(sessionID, org_id,sub_org_id){
    return axios.get(prefixURL2 + `/onboarding/regular_employee_map?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}


export function getReportByAttendanceV2(sessionID, org_id,sub_org_id,start_date,end_date,lowerThreshold,higherThreshold,slug,interval){
    
    return axios.get(prefixURL2 + `/attendance_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}&performance_higher_threshold=${higherThreshold}&performance_lower_threshold=${lowerThreshold}&slug=${slug}&data_date_format=${interval}`)
        .then(res => res.data).catch(err => err)
}

export function getReportByPleton(sessionID, org_id,start_date,end_date){
   
    return axios.get(prefixURL2 + `/pleton_report?session=${sessionID}&org_id=${org_id}&start_date=${start_date}&end_date=${end_date}`)
        .then(res => res.data).catch(err => err)
}

export function getReportByLocaionTime(sessionID, org_id,sub_org_id,start_date,end_date,threeshold,location,slug){
    if(slug==="multiple"){
        return axios.get(prefixURL2 + `/worker_location_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}&location_presence=${threeshold}&slug=${slug}&location_serial=[${location}]`)
        .then(res => res.data).catch(err => err)
    }else{
        return axios.get(prefixURL2 + `/worker_location_report?session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&start_date=${start_date}&end_date=${end_date}&location_presence=${threeshold}&slug=${slug}&location_serial=${location}`)
        .then(res => res.data).catch(err => err)
    }

}


export function getChatGPTRecommendation(message){
   const  api = axios.create({
    baseURL: 'https://api.openai.com/v1',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${API_KEY}`
    }
    });
    return  api.post('/chat/completions', {
      model: 'gpt-3.5-turbo',
      messages: [{ role: 'user', content: message }],
      max_tokens: 600
    }).then(response => response.data.choices[0].message.content).catch(err => err)
    
    
}