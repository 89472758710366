import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import {  Select, Spin } from 'antd';
import {getCategoryList} from '../../../realtimeTracker/actionMethods/actionMethods';
import {getReportByPleton} from  '../../actionMethods/actionMethods';
import DummyData from './dummyAll.json';


export default function PletonWorkerFilter (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : moment().subtract(1,'day').toDate();
    date = date > moment().subtract(1,'days').set({hour:0,minute:0,second:0})?moment().subtract(1,'days').toDate():date
    
    const [dates, setDate] = useState({
        start:null,
        end: null,
    });
    const [inc,SetInc]= useState(0)

  const [isLoading, setIsLoading] = useState(true); // Assuming your state for loader

    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [pletonList,SetPLetonList]= useState([]);
    const [pletonSelected,SetPletonSelected]= useState('');
    const [pletonData,SetPletonData]= useState({})
    const [firstLoad,SetFirstLoad] = useState(true);

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;


    
    let workday =props.workday;
    // const [volume,SetVolume]= useState('');    



    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

      useEffect(()=>{
        
        if(dates.start!=null){
          console.log("dates.start",dates.start);
        
        let requestBody ={}
        requestBody.session =userSession;
        requestBody.org_id= org_id
        props.pletonLoadOn()
        
         
        

        // props.filterResultHandler({},true);
        SetPLetonList([]);
        SetPletonData({});
        SetPletonSelected('');
        
        getReportByPleton(userSession,org_id,getDateFormat(dates.start),getDateFormat(dates.start)).then(res=>{
          // let res= DummyData;
          if(res.status == 200){
            let pl = [];
            console.log("res",res);
            Object.keys(res.data).forEach((key)=>{
                console.log("key",key);
                pl.push(key);
            })
            SetInc((prev)=>prev+1);
            SetPLetonList(pl);
            SetPletonData(res.data);
            props.pletonLoaddOff()
          }

        });
      }
      },[dates.start])

      useEffect(()=>{
        if(inc > 1){
          props.dataReloadOnboardPlanActual({start:dates.start,end:dates.start});  
        }
      },[inc])

    useEffect(()=>{
      setDate((prev)=>({end:props.date,start:props.date}));
      
    },[props.date])

        const marks= {
            1: '1',
            10: '10',
            20: '20',
            30: '30',
            40: '40',
            50: '50',
            60: '60',
            70: '70',
            80: '80',
            90: '90',
            100: '100',
          };

    function filterResultHandler(){
        
       
        let requestBody={
          date:moment(dates.start).format('YYYY-MM-DD') ,
          start_date:moment(dates.start).format('YYYY-MM-DD') ,
          end_date:moment(dates.start).format('YYYY-MM-DD') ,
          name:pletonSelected,
          data:pletonData
        }
        props.pletonLoadOn()
        props.filterResultHandler(requestBody,true);
        setTimeout(function(){
          props.pletonLoaddOff()
        },200);
        
    }

    function exportToExcel(){
        props.exportEnable(true);
    }
    
    useEffect(()=>{
      return()=>{
        props.filterResultHandler({},false);
      }
    },[])


//     useEffect(()=>{

// let date = props.date > moment().subtract(1,'days').set({hour:0,minute:0,second:0})?moment().subtract(1,'days').toDate():props.date
      
      

//       setDate((prev)=>({end:date,start:date}) )
//     },[props.date])

    function onChangePletonSelect(val){
      SetPletonSelected(val);
}

    
    return (
<Row className="">

                  <Col lg={3} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        value={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);props.filterResultHandler({},false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={props.date}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>
                   
                  <Col lg={6} className="p-r-0 report-filter report-filter2">
                      <label>
                        {getTranslatedText("Select Pleton")}
                      </label>
                        <Select
                          onChange={(e)=>onChangePletonSelect(e)}
                          style={{width:"100%"}}
                           value={pletonSelected}
                           disabled={pletonList.length===0}
                           dropdownStyle={{ maxHeight: '800px', overflowY: 'auto' }}
                          
                        >{pletonList.map(el=>{
                            return  <Select.Option value={el}>{el}</Select.Option>
                          })}
                        </Select>
                  </Col>

<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={dates.start==''|| pletonSelected.length===0 }>Submit</button>
                {/* <button className="btn btn-submit btn-hfilter btn-export" onClick={exportToExcel} disabled={!filterFOP} >Export</button> */}
                </div>
</Col>


                </Row>
                )

}