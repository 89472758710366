import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { CommonHeading } from "../../common/commonHeading";
//import { cameraPlacements } from  '../hooks/_data'
import spinnerLoader from "../../assets/images/loader.svg";
import moment from "moment";
import {
  getLanguageTranslation
} from "../../dashboard/actionMethods/actionMethods";
import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider} from 'antd';
import { Select } from 'antd';
import LocationIcon from  '../../assets/images/location.png'

import {getPproductivitylistDetail } from "../../productivity/actionMethods/actionMethods";
// import {getEmployeeTracker} from '../../employeeTracker/actionMethods/actionMethod';

import { getPproductivityDetailOverview,
  getIndividualProductivityList } from '../../productivity/actionMethods/actionMethods';

  import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import '../../employeeTracker/style/style.scss'

import '../../realtimeTracker/style/style.scss'
import Scrollbars from 'react-custom-scrollbars';
import 'antd/dist/antd.css';
import { useScript, useStyle } from './useScriptAndStyle.js';
import { getLocationList } from '../actionMethods/actionMethods';
import Light from '../../assets/images/light.png';
import Street from '../../assets/images/street.png';
import Satellite from '../../assets/images/satellite.png';
import Outdoors from '../../assets/images/outdoor.png';
import Dark from '../../assets/images/dark.png';
import '../style/styles.scss';

import ManProductiveIcon from '../../assets/images/man.png'

import { getLocationCordinate ,getEmployeeTracker} from '../actionMethods/actionMethods';
import { map } from 'd3';



let dataUniqueLocation=[];
let previousTag={};
let biDirectional=[];

let layer=[];
let dataPush=[];
const MapboxTracker = (props) => {
  const { Option } = Select;
  

  const [locationCordinate,SetLocationCordinate]= useState([]);
  const [slug,SetSlug] =useState('');
  const [error,SetError]=useState('');
  
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
//const slideTooltip=useRef()
  const slideTooltip = useRef(null);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const existingPolygons = useRef([]);
  const [selectedStyle, setSelectedStyle] = useState('mapbox://styles/mapbox/streets-v8'); // Default style
  const [locationList, SetLocationList] = useState([]);
  const [currentMode, setCurrentMode] = useState('Streets');
  const [currentModeImg, setCurrentModeImg] = useState(Street);
  const [userMovement,SetUserMovement] = useState([]);


  const [width, setWidth] = useState(0);  
  
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);
  const [department,SetDeparment] =useState([]);
  const [department_id,SetDepartmentID]=useState('');
  
  const [sub_department,SetSubDeparment] =useState([]);
  const [sub_department_id,SetSubDepartmentID]=useState('');
    
  const [employeeID,SetEmployeeID]=useState('');
  const [tag_serial,SetTagSerial] = useState('')
  const [employeeAll,SetEmployeeAll]=useState([]);
  const [employeeDefault,SetEmployeeDefault]=useState([]);
  const [loaderEmp,SetLoaderEmp]=useState(false)
  const [start_time,SetStartTime]=useState(props.match.params && props.match.params.hour?[props.match.params.hour]:[]);
  const [end_time,SetEndTime]=useState('');
  const [isloading,Setloading] =useState(true);
  
  const [autoPlay,SetAutoPlay] =useState(false);
  const [productiveLocation,SetProductiveLocation] =useState([])
  const [nonproductiveLocation,SetNonProductiveLocation] =useState([])
  const [lessproductiveLocation,SetLessProductiveLocation] =useState([])

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));

  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 0;
  let lastReqId = null
  
  const [shiftStarttime,SetShiftStartTime] =useState('');
  const[ ShiftEndtime,SetShiftEndTime]=useState('');
  const [shiftBasedDropdown,SetShiftBasedDropdown] =useState([]);  
  const [activeHourDropdown,SetActiveHourDropdown] = useState([]);
  
  const [filterTimeData,SetFilterTimeData] =useState([]);
  const [sucessMessage,SetSucessMessage] =useState(false);

  const [dailyEmpDefault,SetDailyEmpDefault] =useState([]);
  const [dailyEmpDepartment,SetDailyEmpDepartment] =useState([]);
  const [regularEmpDepartment,SetRegularEmpDepartment] =useState([]);
  const [enable,SetEnable] =useState(false);
  const [startBigger,SetStartBigger]=useState(false);  
  
  const elementRef =useRef(null);
  const [heightMap,SetHightMap]= useState(0);
  const [dualLocationList,SetDualLocationList]=useState([])
  const [oldPosition,SetOldPosition]=useState(0);
  const [lagFreeFlag,SetLagFreeFlag]=useState(false);
  const [selectedStartTime,SetSelectedStartTime]=useState([])
  const [widthBox, SetWidthBox] = useState(60);
  const [locationListMapBox,SetLocationListMapBox] =  useState([]);
  const [loader,SetLoader]=useState(true);
  const markersRef = useRef([]);
  const activePopupRef = useRef(null);

  const timeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';    
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  
  const mapboxtoken = process.env.REACT_APP_MAPBOX_TOKEN

    const changeMapStyle = (newStyle, title, image) => {
        setSelectedStyle(newStyle);
        setCurrentMode(title);
        setCurrentModeImg(image);
        setTimeout(() => SetWidthBox(60), 500);

        if (mapRef.current) {
            mapRef.current.setStyle(newStyle);
        }
    };
    
    const mapBoxMode = [
        { function: 'mapbox://styles/mapbox/streets-v8', title: "Streets", image: Street },
        { function: 'mapbox://styles/mapbox/outdoors-v8', title: "Outdoors", image: Outdoors },
        { function: 'mapbox://styles/mapbox/light-v10', title: "Light", image: Light },
        { function: 'mapbox://styles/mapbox/dark-v10', title: "Dark", image: Dark },
        { function: 'mapbox://styles/mapbox/satellite-v9', title: "Satellite", image: Satellite }
    ];

    useScript('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.js');
    useStyle('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.css');
    useScript('https://cdnjs.cloudflare.com/ajax/libs/Turf.js/6.5.0/turf.min.js');

    useEffect(() => {
        if (window.mapboxgl && !loader && mapContainerRef.current) {
            window.mapboxgl.accessToken = mapboxtoken;

            // Initialize the map
            mapRef.current = new window.mapboxgl.Map({
                container: mapContainerRef.current,
                style: selectedStyle,
                center: [100.58317249183762, 13.72696169281258], // Example coordinates
                zoom: 19,
                projection: 'globe',
                pitch: 10,
                bearing: -15.6,
            });

            const nav = new window.mapboxgl.NavigationControl();
            mapRef.current.addControl(nav, 'top-right');
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, [selectedStyle, loader, locationList, locationCordinate]);

        useEffect(() => {
        const checkTurfLoaded = setInterval(() => {
            if (window && window.turf) {
                clearInterval(checkTurfLoaded);

                const requestBody = {
                    session: userDetails.session,
                    org_id: userDetails.org_id,
                };
                getLocationList(requestBody)
                    .then(res => {
                        if (res.status === 200 || res.code === 200) {
                            SetLocationListMapBox(res.data);
                            
                            getLocationCordinate(requestBody).then(res3 => {
                              if (res3.status === 200) {
                                  const locList = res3.data.map(el => {
                                      const polygonFeature = window.turf.polygon([el.coordinates]);
                                      const centroid = window.turf.centroid(polygonFeature);
                                      const allDetail=  res.data.find(elm=>elm.tag_serial === el.location_serial)

                                      return { ...el, centroid: centroid,...allDetail};

                                  });

                                  SetLocationCordinate(locList);
                                  setTimeout(function(){SetLoader(false);},200);
                                  Setloading(false);
                                  
                              }
                            }).catch(error => console.error("Error fetching location coordinates:", error));
                        }
                    })
                    .catch(error => console.error("Error fetching location list:", error));
            }
        }, 100);

        return () => clearInterval(checkTurfLoaded);
    }, []);

        useEffect(() => {

        if (locationCordinate.length > 0 && window.mapboxgl && mapRef.current && !loader) {
            locationCordinate.forEach(location => {
                const coordinates = location.coordinates;
                
                let loc = locationListMapBox.find(el => el.tag_serial === location.location_serial);


                mapRef.current.on('style.load', () => {
                    existingPolygons.current.push([coordinates]);
                    mapRef.current.addLayer({
                        id: `outline-${loc.id}`,
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: [coordinates]
                                },
                            },
                        },
                        layout: {},
                        paint: {
                            'line-color': '#4ec274',
                            'line-width': 1
                        }
                    });
                    addPolygonWithLabel([coordinates], loc.location_name, loc.id, location.centroid);
                });
            });
        }
        
    }, [locationCordinate, loader, locationListMapBox,selectedStyle]);
    
    function addPolygonWithLabel(polygonCoordinates, labelText, locationId, centroid) {
        const lngLat = centroid.geometry.coordinates;
        // new window.mapboxgl.Marker({ color: 'red' }).setLngLat(lngLat).addTo(mapRef.current);
        mapRef.current.addSource(`label-${locationId}`, {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: centroid.geometry.coordinates,
                },
                properties: {
                    title: labelText,
                },
            },
        });

        mapRef.current.addLayer({
            id: `label-layer-${locationId}`,
            type: 'symbol',
            source: `label-${locationId}`,
            layout: {
                'text-field': ['get', 'title'],
                'text-size': 10,
                'text-offset': [0, 0.5],
                'text-anchor': 'top',
            },
            paint: {
                'text-color': '#000000',
            },
        });
    }


  useEffect(() => {
    if(elementRef && elementRef.current){    
      SetHightMap(elementRef.current.getBoundingClientRect().height);
      setWidth(elementRef.current.offsetWidth);
    }
  
  }, [elementRef,isloading]);

useEffect(()=>{
if(props.match.params && props.match.params.tag ){
  let useDaily= dailyEmpDefault.find(item=>item.tag_serial==props.match.params.tag);  
  if(useDaily){
    SetSlug('daily_worker')
    SetDepartmentID(useDaily.department)
  }else{
    let useRegular = employeeAll.find(item=>item.tag_serial==props.match.params.tag);  
    SetSlug('employee');
    if(useRegular){
      SetDepartmentID(useRegular.department)
    }
  }


}
},[props.match.params,employeeAll,dailyEmpDefault])

useEffect(() => {
    setTimeout(function(){
      SetLagFreeFlag(true);
    },3000)
  }, []);

  useEffect(() => {
    let perm= props.pages.filter(el=>el.sub_page_name && el.sub_page_name =="Employee Tracker Mapbox"); 
    if(perm){
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
    }
},[props.pages]);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}


useEffect(()=>{
 
  SetDepartmentID('');
  SetDeparment([]);
  SetSubDeparment([]);
  SetSubDepartmentID([]);
  if(props.match.params.hour == start_time && props.match.params.tag==employeeID){

  }else{
    SetEmployeeID('')
  }
  
  SetDailyEmpDefault([]);
  SetEmployeeAll([])
  SetDailyEmpDepartment([]);
  SetSlug('')
  if(permission.length > 0){
    

    let arr=[];

    let requestBody={};
    requestBody.startdate=getDateFormat(selectedDate)
    requestBody.enddate=getDateFormat(selectedDate)
    requestBody.slug='employee'
    let dept=[]
    let catIndex=0;
    let empList= [];

    
    getPproductivitylistDetail(requestBody,userDetails.session,org_id,sub_org_id).then(res=>{
     
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empList.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            dept.push({name:key,sub_department:subdept}) 
          }else{

            dept.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empList.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }


      // SetShowData(arr);
      // SetShowDataDefault(arr);
      if(empList.find(item=>item.tag_serial == employeeID )){
        SetSlug('regular_worker');
      }
      SetEmployeeAll(empList)
      SetEmployeeDefault(empList)
      //SetDeparment(dept)
      SetRegularEmpDepartment(dept)
  
      
    })
  
    let requestBody2={};
    requestBody2.startdate=getDateFormat(selectedDate)
    requestBody2.enddate=getDateFormat(selectedDate)
    requestBody2.slug='daily_worker'
    let deptDaily=[]
    let catIndexDaily=0;
    let empListDaily= [];
    getPproductivitylistDetail(requestBody2,userDetails.session,org_id,sub_org_id).then(res=>{
 
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empListDaily.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            deptDaily.push({name:key,sub_department:subdept}) 
          }else{

            deptDaily.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empListDaily.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }
      if(empListDaily.find(item=>item.tag_serial == employeeID )){
        SetSlug('daily_worker');
      }

      SetDailyEmpDefault(empListDaily)
      SetDailyEmpDepartment(deptDaily)
  
      
    })



  }

},[permission,selectedDate])  

useEffect(()=>{


  if(props.match.params.hour == start_time[0] && props.match.params.tag==employeeID){

  }else{
    SetEmployeeID('')
    SetDepartmentID('');
    SetSubDepartmentID('');

  }
  
  let dept = slug=='employee'?regularEmpDepartment:dailyEmpDepartment
  // SetDepartmentID('');
  SetDeparment(dept);
  

},[slug,regularEmpDepartment,dailyEmpDepartment])


useEffect(()=>{
  if(permission.length > 0){
    let sub=[];  
    let subDept=[];
    SetSubDeparment([]);
    //SetEmployeeID('')
    if(props.match.params.hour == start_time &&  props.match.params.tag==employeeID){
      let subName = slug=='employee'?employeeDefault.filter(item=>item.tag_serial== employeeID)?.sub_department:dailyEmpDefault.filter(item=>item.tag_serial== employeeID)?.sub_department;
      
      SetSubDepartmentID(subName?subName:'')

    }else{
      SetEmployeeID('')
      SetSubDepartmentID('')
   
      
    }
      let sub2= slug=='employee'?department.filter(item=>item.name.toString().toLowerCase().includes(department_id.toLowerCase()) ):dailyEmpDepartment.filter(item=>item.name.toString().toLowerCase().includes(department_id.toLowerCase()) )
      for (let j=0;j<sub2.length;j++){
      subDept = subDept.concat(sub2[j].sub_department);
    }  
  if(subDept.length > 0){
  SetSubDeparment(subDept);  
  }   

  let empList2 = slug=='employee'?employeeDefault.filter(item=>item.department== department_id):dailyEmpDefault.filter(item=>item.department== department_id)
  SetEmployeeAll(empList2);
  }
},[permission,department_id])

useEffect(()=>{
  if(permission.length > 0){
    let sub=[];  
    let subDept=[];
    //SetEmployeeID('')
    if(props.match.params.hour == start_time & props.match.params.tag==employeeID){

    }else{
      SetEmployeeID('')
    }
    let empList2 = slug=='employee'?employeeDefault.filter(item=>item.department== department_id && item.sub_department.toString().toLowerCase().includes(sub_department_id=='all'?'':sub_department_id.toLowerCase()) ):dailyEmpDefault.filter(item=>item.department== department_id && item.sub_department.toString().toLowerCase().includes(sub_department_id=='all'?'':sub_department_id.toLowerCase()) )
   
    SetEmployeeAll(empList2,department_id); 
  }
},[permission,sub_department_id])



useEffect(()=>{


  // if(permission.length > 0 && locationCordinate.length > 0 && (dailyEmpDefault.length> 0 || employeeDefault.length> 0) && employeeID!='' ){
  if(permission.length > 0  && (dailyEmpDefault.length> 0 || employeeDefault.length> 0) && employeeID!='' ){

    let requestBody={} ;
    requestBody.startdate= getDateFormat(selectedDate)
    requestBody.enddate= getDateFormat(selectedDate)
    requestBody.worker_id= employeeID

    
    if(employeeDefault.filter(item=>item.worker_id == employeeID ).length>0){
      requestBody.slug= 'employee'
      
    }else if(dailyEmpDefault.filter(item=>item.worker_id == employeeID )){
      requestBody.slug= 'daily_worker'
    }
    SetShiftBasedDropdown([])
    setCount(0)
    SetShiftEndTime('')
    // SetAutoPlay(false);
    SetShiftStartTime('')
   // SetStartTime('')
   if(props.match.params.hour == start_time && props.match.params.tag == employeeID){

   }else{
    SetStartTime([])
   }
    SetFilterTimeData([])
    
    SetDefaultPoly([])
    SetSucessMessage(false)
    SetStartBigger(false);
    let stbigger =false;
    
    let prod =[];
    let nonProd=[];
    let lessProd = [];

    for(let i= 0;i<productiveLocation.length;i++){

      updateDataLayer(productiveLocation[i],LocationIcon)
    }
    for(let i= 0;i<nonproductiveLocation.length;i++){
     
      updateDataLayer(nonproductiveLocation[i],LocationIcon)
    }
    for(let i= 0;i<lessproductiveLocation.length;i++){
      updateDataLayer(lessproductiveLocation[i],LocationIcon)
    }
    let shiftHour=[];
  getPproductivityDetailOverview(requestBody,userSession,org_id,sub_org_id).then(res=>{
    if(res.shift){
   
      let shift = res.shift && typeof res.shift == "object"?res.shift:res.shift?JSON.parse(res.shift):[]
      let startSft= res.shift?shift.start_time.split(':'):null
      let endSft= res.shift?shift.end_time.split(':'):null
      let st = parseInt(startSft && startSft[0]?parseInt(startSft[1]) > 0 && parseInt(startSft[0])!=23 ?parseInt(startSft[0]):parseInt(startSft[0]):0);
      let et = parseInt(endSft && endSft[0]?parseInt(Math.ceil(endSft[1])) > 0 && parseInt(endSft[0])!=23 ?parseInt(endSft[0]) + 1:parseInt(endSft[0]):0);
      
      let oft=[]
      
      let smaller=st>et?et:st;
      let bigger = st>et?st:et;
      
      
      SetShiftStartTime(startSft?startSft[0]+":"+startSft[1]:'-');
      SetShiftEndTime(endSft?endSft[0]+":"+endSft[1]:'-');
      
      for(let i=0;i<24;i++){
        if(st > et){
          SetStartBigger(true);
          stbigger= true;
          if( (smaller <= i) && (bigger > i) ){

            }else{
                shiftHour.push(i);
            }
        }else{
          SetStartBigger(false);
          stbigger= false;
            if( (smaller <= i) && (bigger > i) ){
                shiftHour.push(i);    
            }
        }
    }        

  
     let sDate= moment(requestBody.startdate).set({hour:st}).toDate();
          let eDate= moment(requestBody.startdate).set({hour:et}).toDate();
          if(st > et && props.workday.start_hour!="00:00:00" ){
              eDate = moment(requestBody.startdate).add(1,'days').set({hour:et}).toDate();
          }
      let allHour =  fetchAllHour(sDate,eDate,res.data);
    
      SetShiftBasedDropdown(allHour.map(el=>el.hour))
      SetActiveHourDropdown(allHour);
      if(parseInt(startSft?startSft[0]:10) == 0 && parseInt(endSft?endSft[0]:11) < 12){
        stbigger= true;
        SetStartBigger(true);
      }
      

    }

    getIndividualProductivityList(requestBody,userSession,org_id,sub_org_id).then(res=>{
   
      if(res.data && res.data.productive){
        let data = res.data.productive;
        for(let i=0;i<data.length;i++){
        
          let item = locationCordinate.find(item=> item.tag_serial == data[i].tag_serial)
          if(item){prod.push(item);updateDataLayer(item)}  
        }          
      }
      if(res.data && res.data.less_productive){
        let data = res.data.less_productive;
        for(let i=0;i<data.length;i++){
          let item = locationCordinate.find(item=> item.tag_serial == data[i].tag_serial)
          if(item){lessProd.push(item);updateDataLayer(item)}  
        }          
      }
      if(res.data && res.data.non_productive){
        let data = res.data.non_productive;
        for(let i=0;i<data.length;i++){
          let item = locationCordinate.find(item=> item.tag_serial == data[i].tag_serial)
          if(item){nonProd.push(item);updateDataLayer(item)}  
        }          
      }        
    
      SetProductiveLocation(prod);
      SetNonProductiveLocation(nonProd);
      SetLessProductiveLocation(lessProd);  

      
      if(props.match.params.hour == 'all' && props.match.params.tag == employeeID){
       
        SetStartTime(shiftHour);
        filterResultHandler(employeeID,shiftHour,stbigger)
      }
      else if(props.match.params.hour == start_time && props.match.params.tag == employeeID){
        filterResultHandler(employeeID,[start_time],stbigger)
      }

      //shiftHour

    })


    
  })

  }
  
},[permission,employeeID,locationCordinate,dailyEmpDefault,employeeDefault])

//     function fetchAllHour(start,end){
//         const startDate = start;
// const endDate = end; // Assuming end_time is on the next day

// const hourArray = [];

// while (startDate < endDate) {
//   const hourString = moment(startDate).format('H');
//   hourArray.push({hour:hourString,date:moment(startDate).format('YYYY-MM-DD')});

//   startDate.setHours(startDate.getHours() + 1);
// }
// return hourArray;



//     }
    function fetchAllHour(start,end,data){
        const startDate = start;
        const endDate = end; // Assuming end_time is on the next day

        const hourArray = [];
        Object.keys(data).forEach(element => {
            Object.keys(data[element]).forEach(key2=>{
                hourArray.push({hour:parseInt(key2).toString() ,date:element,sortTime:element+" "+key2+":00:00"});
            });        
        });
        let sortedTIme = hourArray.sort((a,b)=>new Date(a.sortTime) - new Date(b.sortTime))
        return sortedTIme;

    }
    
useEffect(()=>{ 


},[permission,employeeID,start_time,department_id,sub_department_id,slug])

// useEffect(() => {
//   if(slideTooltip.current){
    
//   }
  
// }, [slideTooltip]);

function removeLayerSmplrSpace(){
  
    
    }



  function handleDateSelect(date) {
    updateSelectedDate(date);
    SetSlug('');
    SetStartTime([]);

      removeLayerSmplrSpace()    
             setCount(0);
           SetSucessMessage(false);
           SetLoaderEmp(false);
       SetDefaultPoly([])
    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function AddDataLayer(data){
}

function convertTimeZone(time){
 const utcTimeString = time;
 
const utcDateTime = new Date(utcTimeString + "Z"); // Append 'Z' to indicate UTC time
const indonesiaTimeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';  
const indonesiaOptions = { timeZone:  indonesiaTimeZone };
const indonesiaTimeString = utcDateTime.toLocaleString("en-US", indonesiaOptions);
return moment.utc(indonesiaTimeString).format('hh:mm:ss A')

} 

  function updateDataLayer(data,url,width,widthSize,inc){

  }

  function filterResultLocation(name){

  }
  

  
  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }
    
  const  [marks,SetMark]  = useState([]) 
  const [marks2,SetMark2] =useState([]) 
  






  const [defaultPoly,SetDefaultPoly] = useState([])

  const [currentCount, setCount] = useState(0);
  const randomcolor = ['#000','#d95780','#51ac30','#101535','#b8cdda','#ffa500','#ff0000']

  

  const timer = () => {
    setCount(currentCount + 1);    
  }




      function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
        
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    
  
  
    const handleDate = (date, type) => {
      // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }


    function HandleRange(e){
      setCount(e);
    }

   function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  
async function filterResultHandler(tag = employeeID, stimeInput = start_time, bigTimer = startBigger) {
  let dataUniqueLocation = [];
  let dualLocation = [];
  let arr = [];
  let mk = [];
  let locationPoint = [...locationCordinate,
    
    {
  "coordinates": [],
"id": 'Out of Boundary',
  "location_serial": "Out of Boundary",
  "org_id": 36,
  "properties": {},
  "centroid": {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": []
    }
  },
  "area_size": 5,
  "capacity": 5,
  "categories": "Outdoor",
  "description": "Out of Boundary",
  "loc_type": 1,
  "location_name": "Out of Boundary",
  "tag_serial": "Out of Boundary"
},{
  "coordinates": [],
"id": 'Out of Range',
  "location_serial": "Out of Range",
  "org_id": 36,
  "properties": {},
  "centroid": {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": []
    }
  },
  "area_size": 5,
  "capacity": 5,
  "categories": "Outdoor",
  "description": "Out of Range",
  "loc_type": 1,
  "location_name": "Out of Range",
  "tag_serial": "Out of Range"
}];  
  SetLoaderEmp(true);
  setCount(0);
  SetError('');
  SetFilterTimeData([]);
  SetSucessMessage(false);
  const stime = stimeInput.map(el => activeHourDropdown.find(item => item.hour === el));

  console.log("stime",stime)

  const promises = stime.map(async (timeObj, index) => {
    const requestBody = {
      worker_id: employeeID,
      date: timeObj.date ? getDateFormat(timeObj.date) : getDateFormat(selectedDate),
      start_time: `${parseInt(timeObj.hour || start_time[index])}:00:00`,
      end_time: `${parseInt(timeObj.hour || start_time[index]) === 23 ? "00" : parseInt(timeObj.hour || start_time[index]) + 1}:00:00`,
      work_day: moment(selectedDate).format("YYYY-MM-DD"),
    };

    try {
      console.log("")
      const res = await getEmployeeTracker(requestBody, userSession, org_id, sub_org_id);
      if (res.status === 200) {
        let data = res.data[tag_serial] ? res.data[tag_serial] : res.data;
        
        if (data.length > 0) {
          data = data.sort((a, b) => moment(a.timestamp) - moment(b.timestamp));
        }

        let previousLocation = [];
        let incCount = 0;
        data.forEach((entry) => {

          const key = entry.location_serial;
          const findPos = locationPoint.find(item => item.location_serial === key);

          if (findPos) {
            mk.push(moment.utc(entry.timestamp));
            // moment.utc(item.time_spent).format("HH:mm:ss")
            if (!dataUniqueLocation.includes(findPos.location_name)) {
              dataUniqueLocation.push(findPos.location_name	);
            }

            const polylineFormat = {
              levelIndex: 0,
              x: entry.location[0],
              z: entry.location[1],
              elevation: 5,
              name: findPos.location_name	,
              tag_serial: findPos.location_serial,
              time: entry.timestamp,
              position: incCount,
            };
            arr.push(polylineFormat);

            if (previousLocation.length > 0 && previousLocation[previousLocation.length - 1].tag_serial !== findPos.location_serial) {
              const oldLoc = previousLocation[previousLocation.length - 1];
              const newLoc = polylineFormat;

              dualLocation.push({
                old: { ...oldLoc },
                new: { ...newLoc },
                position: incCount,
              });
            }
            previousLocation.push(polylineFormat);
            incCount++;
          }
        });
      }
    } catch (error) {

    }
  });

  // Wait for all promises to complete
  await Promise.all(promises);

  // After all async operations, update the state with collected data
  arr = arr.sort((a, b) => new Date(a.time) - new Date(b.time));
  mk = mk.sort((a, b) => new Date(a) - new Date(b)).map(item => moment(item).utc().format('hh:mm:ss A'));


let movement = arr.map(el=>{

return ({location:[el.x,el.z],timestamp:el.time,name:el.name,tag_serial:el.tag_serial})
})

console.log("movement",movement);

SetUserMovement(movement)


  SetDefaultPoly(arr);
  SetDualLocationList(dualLocation);
  SetSucessMessage(true);
  SetOldPosition(0);
  setCount(arr.length);
  SetMark(mk);
  SetLoaderEmp(false);
  SetSelectedStartTime(stime);
}

    async function getMatch(coordinates, radius, profile,dataToShow,timeData) {

 const lineCoordinates = []; // To store coordinates for the path line
       console.log("data to show cord 2",dataToShow);
dataToShow.forEach((movement, index) => {
      const { location, timestamp } = movement;

            let length = dataToShow.filter(elm=>elm.tag_serial  === movement.tag_serial).length;
            const el = document.createElement('div');
           
            const width = 15;
            const height =25;
            el.className = 'marker';
            el.style.backgroundImage = `url(${ManProductiveIcon})`;
            el.style.width = `${width}px`;
            el.style.height = `${height}px`;
            el.style.backgroundSize = '100%';
            // new window.mapboxgl.Marker(el).setLngLat(point).addTo(mapRef.current);
      let tooltipText= `${movement.name}`;

      if(timeData[index]!== undefined){
        
        tooltipText+=` (${convertTimeZone(timeData[index].time)})`
      }

        const popup = new window.mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        }).setText(`${tooltipText})`);

        const marker = new window.mapboxgl.Marker(el)
            .setLngLat(location)
            .setPopup(new window.mapboxgl.Popup().setText(`${movement.name}`)) // Optional popup
            .addTo(mapRef.current);
        marker.getElement().addEventListener('mouseenter', () => {
            popup.setLngLat(location).addTo(mapRef.current);
            activePopupRef.current = popup;
        });
        marker.getElement().addEventListener('mouseleave', () => {
            popup.remove();
        });     
        markersRef.current.push(marker);


      
      lineCoordinates.push(location);
      

      // Draw the line incrementally
      if (index > -1 && lineCoordinates.length > 1) {
        // Remove any existing line
        if (mapRef.current.getSource('route')) {
          mapRef.current.removeLayer('route');
          mapRef.current.removeSource('route');
        }

        // Add GeoJSON line source
        mapRef.current.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: lineCoordinates,
            },
          },
        });

        // Add line layer
        mapRef.current.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#888',
            'line-width': 4,
          },
        });
      }
    });

    const chunkSize = 60; // Define the size of each chunk
  
    let allLineCoordinates = []; // 
    const chunkArray = (arr, size) => {
      const result = [];
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
      }
      return result;
    };
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const coordinateChunks = chunkArray(coordinates, chunkSize);
    const radiusChunks = chunkArray(radius, chunkSize);

     for (let i = 0; i < coordinateChunks.length; i++) {
    const coords = coordinateChunks[i].join(';');
    const radiuses = radiusChunks[i].join(';');
    const url = `https://api.mapbox.com/matching/v5/mapbox/${profile}/${coords}?geometries=geojson&radiuses=${radiuses}&steps=true&access_token=${mapboxtoken}`;

    try {
      // Fetch map-matching data
      const response = await fetch(url, { method: 'GET' });
      const data = await response.json();

      // Check if response is successful
      if (data.code === 'Ok') {
        const coords = data.matchings[0].geometry.coordinates;
        allLineCoordinates.push(...coords); // Aggregate coordinates for the final route
      } else {
        console.warn(`Map-matching error for chunk ${i + 1}: ${data.message}`);
      }
    } catch (error) {
      console.error(`Error fetching map-matching data for chunk ${i + 1}:`, error);
      // Skip this chunk and proceed to the next
    }

    // Delay to avoid hitting rate limits
    await delay(200); // Adjust delay time as needed (200ms is an example)
  }


 
if (allLineCoordinates.length > 0) {
  console.log("allLineCoordinates",allLineCoordinates)
      addRoute(allLineCoordinates);
}

      
      
      }



    
    
    function updateRoute(coords,dataToShow,timeData) {
      removeRoute(); 
      const profile = 'walking'; // Set the profile
      const newCoords = coords.join(';');
      const radius = coords.map(() => 5);
      console.log("data to show cord",dataToShow);
      getMatch(coords, radius, profile,dataToShow,timeData);
    }

    //    function addRoute(coords) {
    //   // If a route is already loaded, remove it
    //   if (mapRef.current.getSource('routematch')) {
    //     mapRef.current.removeLayer('routematch');
    //     mapRef.current.removeSource('routematch');
    //   } else {
    //     mapRef.current.addLayer({
    //       'id': 'routematch',
    //       'type': 'line',
    //       'source': {
    //         'type': 'geojson',
    //         'data': {
    //           'type': 'Feature',
    //           'properties': {},
    //           'geometry': coords
    //         }
    //       },
    //       'layout': {
    //         'line-join': 'round',
    //         'line-cap': 'round'
    //       },
    //       'paint': {
    //         'line-color': '#03AA46',
    //         'line-width': 8,
    //         'line-opacity': 0.8
    //       }
    //     });
    //   }
    // }

    // If the user clicks the delete draw button, remove the layer if it exists
    function addRoute(coords) {
  // Ensure `coords` is an array of coordinate pairs
  if (!Array.isArray(coords) || coords.length === 0) {
    console.error("Invalid coordinates provided to addRoute");
    return;
  }

  // Format the data as a GeoJSON Feature object
  const geojsonData = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: coords,
    },
  };

  // If a route is already loaded, remove it
  if (mapRef.current.getSource('routematch')) {
    mapRef.current.removeLayer('routematch');
    mapRef.current.removeSource('routematch');
  }

  // Add a new source and layer for the route
  mapRef.current.addSource('routematch', {
    type: 'geojson',
    data: geojsonData,
  });

  mapRef.current.addLayer({
    id: 'routematch',
    type: 'line',
    source: 'routematch',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#03AA46',
      'line-width': 8,
      'line-opacity': 0.8,
    },
  });
}

    function removeRoute() {
      if (!mapRef.current.getSource('routematch')) return;
          mapRef.current.removeLayer('routematch');
          mapRef.current.removeSource('routematch');
      }

useEffect(() => {
  if (!loader && userMovement.length > 0 && mapRef && mapRef.current && defaultPoly.length > 0) {
    
    const dataToShow = userMovement.slice(0, currentCount); // Limit to specified count
    const timeData = defaultPoly.slice(0,currentCount);
    console.log("data to show",dataToShow);
    SetFilterTimeData(timeData)
   
  
    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];
        if (mapRef.current.getSource('route')) {
          mapRef.current.removeLayer('route');
          mapRef.current.removeSource('route');
        }

      updateRoute(dataToShow.map(el=>el.location),dataToShow,timeData)  
    
  }
}, [dualLocationList, userMovement, currentCount, loader,defaultPoly]);


useEffect(() => {
if(markersRef && markersRef.current && mapRef && mapRef.current){
markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];
        if (mapRef.current.getSource('route')) {
          mapRef.current.removeLayer('route');
          mapRef.current.removeSource('route');
        }
}

},[selectedDate])



  function formatAMPMHour(date) {
    var hours = date;
    var minutes = 0;
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function onChangeStartTime(val){

    let dept= shiftBasedDropdown;
  
    if(val.indexOf('all')> -1){
        let value =dept;
        if((val.length -1) == value.length ){
          SetStartTime([])
        }else{
          SetStartTime(value);
        }
    }else{
      SetStartTime(val);
    }
  }
  
  return (<>
    {permissionAPICall?permission.length > 0? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
        <Col lg={6}>
          
          <div className='tabHeader'>
            
              {props.pages.filter(el=> el && el.sub_page_name =="Employee Tracker Mapbox" ).length >0?<div className='tabHeaderList active' >
              <CommonHeading title="Employee Tracker"/>
              </div>:""}   
              {props.pages.filter(el=> el && el.sub_page_name =="Facility Tracker Mapbox" ).length >0?<div className='tabHeaderList ' onClick={()=>props.history.push('/geo-map-outdoor')}>
              <CommonHeading title="Facility Tracker" />
              </div>:""}
              {props.pages.filter(el=> el && el.sub_page_name =="Movement Analyzer Mapbox" ).length >0?<div className='tabHeaderList' onClick={()=>props.history.push('/mapbox-movement-analyzer')}>
                  <CommonHeading title="Movement Analyzer" />
              </div>:""}
           </div>   
        </Col>
        <Col lg={6} className="text-right">
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="commonHeadingDateMainDivParent">
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
            />
          </div>
        </Col>
      </Row>

      <Row className="m-t" >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
            <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}> Filter</h5>
            </div>
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Selct User Type"
                optionFilterProp="children"
                onChange={(val)=>{SetSlug(val);setTimeout(function(){removeLayerSmplrSpace()},100)}}
                //defaultValue={'Select Department'}
                value={slug==''?'Select User Type':slug}
                key="departmentUserType"
                style={{width:140}}
                disabled={!lagFreeFlag}
                
            >
              <Option value='employee' key={"UserTypeRegular All"}>Regular Worker</Option>
              <Option value='daily_worker' key={"UserTypeDaily All"}>Daily Worker</Option>
              
            </Select>            
          </div>

            <div className='inlineBlockFilter'>
              <Select
                placeholder="Select Department"
                onChange={(val)=>{SetDepartmentID(val);setTimeout(function(){removeLayerSmplrSpace()},100) }}
                value={department_id==''?"Select Department":department_id}
                key="departmentFilterv1"
                style={{width:200}}
                dropdownClassName={'smallerDrop'}
                disabled={slug==''?true:false}
            >
             
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.name} key={"Department V1"+item.name}>{item.name}</Option>
                }):""
                }
            </Select>
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:200}}
                    onChange={(val)=>{SetSubDepartmentID(val);setTimeout(function(){removeLayerSmplrSpace()},100)  }}
                    disabled={department_id==''?true:false}                   
                    value={sub_department_id==''?"Select Sub Department":sub_department_id}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}

                >
                  <Option value='all' key={"Sub Department v1 All"}>Select All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item} key={"Sub Department V1"+item}>{item}</Option>
                    }):""
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Employee"
                    optionFilterProp="children"
                    style={{width:150}}
                    onChange={(val)=>{SetEmployeeID(val);SetTagSerial(employeeAll.find(el=>el.worker_id==val)?.tag_serial);setTimeout(function(){removeLayerSmplrSpace()},100) }}
                    disabled={department_id=='' || sub_department_id.length == 0?true:false}                   
                    value={employeeID==''?"Select Employee":employeeID}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}
                >
                    {
                    employeeAll && employeeAll.length > 0? employeeAll.map((item)=>{
                        return <Option value={item.worker_id} key={"Employee V1"+item.emp_name}>{item.emp_name}</Option>
                    }):""
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter inlineBlockFilterLabel' style={{width:"150px"}}>
                  <label>Shift</label> <span style={{float:"right"}}>{shiftStarttime} - {ShiftEndtime}</span>
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Time"
                    optionFilterProp="children"
                    style={{width:150}}
                    onChange={(val)=>{onChangeStartTime(val);setTimeout(function(){removeLayerSmplrSpace()},100)}}
                    value={start_time}
                    key="EndTimeV1"
                    dropdownClassName={'smallerDrop'}
                    disabled={shiftStarttime==''||ShiftEndtime==''}
                    mode={'multiple'}
                >
                  <Option value='all' key={"All shift Hour v1 All"}>All Shift Hour</Option>
                    {
                    shiftBasedDropdown.map((item)=>{

                        return <Option value={item} key={"Employee V2"+item}>{item+":00"}</Option>
                    })
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter'>
                  <div className="btndiv">
                      {/* <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={ department_id==''  || start_time.toString()==''  || employeeID==''}>Submit</button> */}
                      <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} >Submit</button>
                      
                  </div>                
                </div>


</div>
</Col>
    </Row>






      <Row className="m-t" ref={elementRef}>
        <Col lg={9} className='tabletfull' >
 
 
                    {loaderEmp? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4> 
                    
                    

                  </div></div>:''}
                  
                  {sucessMessage && defaultPoly.length >0?<div className='loaderStyle loadStyleSucess' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Ready</h4> 
                    
                    

                  </div></div>:sucessMessage && defaultPoly.length ==0?<div className='loaderStyle loadStyleError' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Oops! This Hour data not available</h4> 
                  </div></div>:""}
                  {isloading? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:<>
                                            <div id="container" style={{ display: 'flex', height: "calc(100vh - 170px)", position: "relative" }}>
                <div ref={mapContainerRef} style={{ width: '100%' }} />
                {!loader ? <div className='absGreatStyle' style={{ width: widthBox }}>
                    <div onClick={() => SetWidthBox((prev) => (prev === 60 ? 480 : 60))} className='currentStyle boxStyle'>
                        <img src={currentModeImg} alt="Mode Icon" />
                        <small>{currentMode}</small>
                    </div>
                    <div className='expandMode'>
                        {mapBoxMode.map(el => (
                            <div key={el.title} className={el.title === currentMode ? ' boxStyle active' : 'boxStyle'}
                                onClick={() => changeMapStyle(el.function, el.title, el.image)}>
                                <img src={el.image} alt="Map Style Icon" />
                                <small>{el.title}</small>
                            </div>
                        ))}
                    </div>
                </div> : ""}
            </div>
                                          <div position='center' my='md'>
        
       
        <Row classNam
        e='nicerFormatRange'>
          <Col lg={12}>
            <Slider
              //marks={marks}
            
              min={0} 
               max={defaultPoly.length==0?120:defaultPoly.length}
              value={currentCount}
              onChange={HandleRange}
              tooltipVisible={currentCount==0 || defaultPoly.length == 0?false:true}
        tipProps={{ placement: 'top', visible: true }}
         tipFormatter={(index) => convertTimeZone(filterTimeData[currentCount - 1]?.time)}

            />

          </Col>
          <Col lg={2}>
          
          </Col>

        </Row>
          
      </div>                                
                                    </>}


                  </Col>
          <Col lg={3} className='tabletfull p-l-0'>
          <div className="legendArea manStyle legendArea2" style={{height:heightMap,background:"#fff",}}>
            {filterTimeData.length>0?<div className='legendSmallDate'>Date <strong>{startBigger ===true  && start_time < 11 ?moment(selectedDate, "YYYY-MM-DD").add(1, 'days').format("MMM DD"):moment(selectedDate).format("MMM DD")}</strong> ( { formatAMPMHour(start_time)})</div>:""}
            <div className='legendStart'>
              <div className={enable?'legendList legendHeader disable':'legendList legendHeader disable'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  {enable?<div className='legendLeftAreaTime'><strong>Spend</strong></div>:""}
                  <div className='legendRightAreaProd'><strong>Time</strong></div>
              </div>

                    <Scrollbars style={{height:"calc(-190px + 100vh)"}}>{filterTimeData.map((item,index)=>{
                   
                      return(
                                <div key={"tracker"+item.time+index} className={enable?'legendList  disable ':'legendList'}>
                                        <div className='legendLeftAreaProd'>{item.name}</div>
                                        {enable?<div className='legendLeftAreaTime'>{moment.utc(item.time_spent).format("HH:mm:ss")}</div>:""}
                                        <div className='legendRightAreaProd'>{convertTimeZone(item.time)}</div>
                                </div>
                                )
                    })}</Scrollbars>

                </div>

          </div>
        </Col>        

      

      </Row>
    </Container>
  </div>:
<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>

  )
}

export default MapboxTracker