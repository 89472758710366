import React, { useState,useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';


import downThumbIcon from '../../assets/traceplusImages/orange_thumb.svg.svg'
import dashboardPeopleImage from '../../assets/traceplusImages/threat_watch_orange_img.svg'
import greenThumbIcon from '../../assets/traceplusImages/green_thumb.svg'
import {ReactComponent as HelpIcon} from '../../assets/images/info-circle.svg'

import dropdownIcon from '../../assets/images/down-arrow.png'
import DatePicker from "react-datepicker";
import { getDashboardPerformanceWidget, getLanguageTranslation } from '../actionMethods/actionMethods';
import { getTranslatedText } from '../../common/utilities';
import DownArrow from '../../assets/images/down-arrow(1).png';
import UPArrow from '../../assets/images/up-arrow.png';
import { Slider } from 'antd';
import { getPproductivityOverviewAll } from "../../productivity/actionMethods/actionMethods";
import{ getFacilityWidget}from '../actionMethods/actionMethods'
import { ReactComponent as UpIcon } from "../../assets/images/play-solid.svg";

import { ToastContainer, toast } from "react-toastify";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ReactModal from 'react-modal';
import {  Modal } from 'antd';
import {ReactComponent as RightAngle} from '../../assets/images/angle-right.svg'
import { getOnBoardEmp } from '../../costManagement/actionMethods/actionMethods';
import {getLiveReportEmployee}  from '../actionMethods/actionMethods'
import { Tabs } from 'antd';
import Morning from '../../assets/images/morning.png'
import Afternoon from '../../assets/images/afternoon.png'
import Night from '../../assets/images/night.png'
import Plan from '../../assets/images/plan.png'
import Actual from '../../assets/images/actual.png'
import Unexpected from '../../assets/images/unexpected2.png'
import Absent from '../../assets/images/absent2.png'
import Yes from '../../assets/images/yes.png'
import No from '../../assets/images/no.png'
import Compliance from '../../assets/images/compliance.png'
import { getPlanAttendanceReport } from '../../report/actionMethods/actionMethods';

function TopAreaPerformance(props) {
const { TabPane } = Tabs;

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id = userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  

  const [chartDate, SetChartDate] = useState(props.selectedDate);
  const [compareDate, SetCompareDate] = useState([props.selectedDate]);
  const [chartDataPerformance, setChartDataPerformance] = useState({
    categories: [],
    series: [],
  });
    function callback(key) {
   
  }
  const [modalStyle, setModalStyle] = useState({
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "1000px",
    maxHeight: "90%",
  },
});

  const [todayStats, SetTodayStats] = useState({});
  const [WeekStats, SetWeekStats] = useState({});
  const [weekName, SetWeekName] = useState("");
  const [monthStats, SetMonthStats] = useState({});
  const [performanceTitle, SetPerformanceTitle] = useState("");
  const [loader, SetLoader] = useState(true);
  const [loaderSecondWidget,SetLoaderSecondWidget]=useState(true);
  const [toastClass, updateToastClass] = useState("errorToast");
  const [checkedIn,SetCheckedIn] = useState({prev:0,current:0});
  const [inFacility,SetInFacility] = useState({prev:0,current:0});
  const [magicRatio,SetMagicRatio]= useState({prev:0,current:0});
  const [monthStats2,SetMonthStats2] =useState(0);
  const [modalFacility,SetModalFacility] = useState(false);
  const [modalDataInfacility,SetModalDataInfacility]= useState([]);
  const [modalDataOutfacility,SetModalDataOutfacility]= useState([]);
  const [employee,SetEmployee]=useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [plannedCount,SetPlannedCount] = useState('');
  const [plannedEmp,SetPlannedEmp]= useState([]);
  const [activeTab,SetActiveTab]= useState(1);
  const [allEmployee,SetAllEmployee]= useState([]);
  const [todayCheckedIn,SetTodayCheckedIn]= useState('');
  const [headerData,SetHeaderData] = useState({});
  const [planActualData,SetPlanActualData] = useState([]);
  const [loader1,SetLoader1]= useState(true);
  const [loader2,SetLoader2]= useState(true);
  const [loader3,SetLoader3]= useState(true);
  
  
  const indonesiaTimeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';  
  

const onMouseDown = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const onMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - offset.x;
      const deltaY = e.clientY - offset.y;

      setOffset({
        x: e.clientX,
        y: e.clientY,
      });

      // Update modal position
      // const modal = document.querySelector('.ant-modal-content');
      // modal.style.top = `${modal.offsetTop + deltaY}px`;
      // modal.style.left = `${modal.offsetLeft + deltaX}px`;
      const hoveredElement = document.querySelector('.ant-tabs-content.ant-tabs-content-top');
      if (!hoveredElement.contains(e.target)) {
        const modal = document.querySelector('.ant-modal-content');
        modal.style.top = `${modal.offsetTop + deltaY}px`;
        modal.style.left = `${modal.offsetLeft + deltaX}px`;
      }



    }
  };
  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseLeave = () => {
    setIsDragging(false);
  };
    useEffect(()=>{
    if(props.employee){

      SetEmployee(props.employee);
    }
  },[props.employee])

  function AddZeroFormatter(value){
  let sp =value.toString().split('.');
  let inital=parseFloat(sp[0])?parseFloat(sp[0]):0;
  let end= parseFloat(sp[1])?parseFloat(sp[1]):0;
  
  if(parseFloat(sp[1]) < 10 && sp[1].length == 2){
    end ='0'+ parseFloat(sp[1]);
  }else{
    end = parseFloat(sp[1])+'0';
  }
  return parseFloat(inital+"."+end);
  // return parseFloat(value.toFixed(2));

}

  const [chartDataPerformanceAll, setChartDataPerformanceAll] = useState({
    categories: [],
    series: [],
  });
   const [selectedDate, updateSelectedDate] = useState(props.selectedDate);
    function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }
useEffect(()=>{
    SetChartDate(props.selectedDate);
    updateSelectedDate(props.selectedDate);
    
},[props.selectedDate]);
  const isWeekday = (date) => {
      
        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
        //here sunday
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return true     
        }else{
          //here normal days
        return false ;
        }        
  };
useEffect(()=>{
    SetChartDate(props.chartDate);
},[props.chartDate])

      useEffect(() => {
        (async () => {
const fetchDataPerformance = async (startDate,endDate) => {
   return await getDashboardPerformanceWidget(
          userSession,
          org_id,
          sub_org_id,
          startDate,
          endDate
        ).then(res=>res);

        // Process todayData as needed
      }
      
if(props.indexTitle==0 || props.topAPICall){

  let requestBody = {};

    requestBody.slug = "employee";
    if (props.indexTitle == 0) {
      props.offChartLoader(true);
    }
    if(!props.topAPICall){
        SetLoader(false);
    }else{
      SetLoader(true);
    }
    props.offLoader(true);
    

    requestBody.start_date = moment(chartDate).format("YYYY-MM-DD");
    requestBody.end_date = moment(chartDate).format("YYYY-MM-DD");
    requestBody.slug = "employee";
    let arr = [];

    if (props.active == "today") {
      
    }

    let series = [];
    let lessProductive = [];
    let Productive = [];
    let nonProductive = [];
    let undefinedProductive = [];
    requestBody.startdate = getDateFormat(
      moment(chartDate).subtract(60, "days")
    );
    // let today = await fetchDataPerformance(moment(chartDate).format("YYYY-MM-DD"),moment(chartDate).format("YYYY-MM-DD"))
    // console.log("today",today);
    // if(today.status === 200){
    //   SetTodayStats(today.data);
    // }else{
    //   SetTodayStats({overall:"-",});
    // }
    getDashboardPerformanceWidget(userSession,org_id,sub_org_id,moment(chartDate).format("YYYY-MM-DD"),moment(chartDate).format("YYYY-MM-DD")).then(today=>{
        SetLoader1(false);
        if(today.status === 200){
          SetTodayStats(today.data);
        }else{
          SetTodayStats({overall:"-",});
        }
    })



    
    let monthDiff = props.getMonths(
            moment(chartDate).subtract(7, "days").toDate(),
            moment(chartDate).toDate(),
            "week"
          );
          monthDiff = monthDiff[monthDiff.length - 1];
    getDashboardPerformanceWidget(userSession,org_id,sub_org_id,monthDiff.start,monthDiff.end).then(week=>{
    SetLoader2(false);
      if(week.status=== 200){
      SetWeekStats(week.data)
    }else{
      SetWeekStats({overall:"-"});
    }
    SetWeekName(monthDiff.weekName);
    })
  
  getDashboardPerformanceWidget(userSession,org_id,sub_org_id,moment(chartDate).format("YYYY-MM")+"-01",moment(chartDate).format("YYYY-MM-DD")).then(month=>{
    SetLoader3(false);
    console.log("month",month);
    if(month.status=== 200){
      SetMonthStats(month.data)
    }else{
      SetMonthStats({overall:"-"});
    }

  })


    

    SetLoader(false);
  }
  })();
  }, [selectedDate, chartDate]);
 

        useEffect(() => {
        (async () => {
      
if(props.indexTitle==0 || props.topAPICall){

  let requestBody = {};

    requestBody.slug = "employee";
    if (props.indexTitle == 0) {
      props.offChartLoader(true);
    }
    if(!props.topAPICall){
        SetLoader(false);
    }else{
      SetLoader(true);
    }
    props.offLoader(true);
    

    requestBody.start_date = moment(chartDate).format("YYYY-MM-DD");

    requestBody.startdate = getDateFormat(
      moment(chartDate).subtract(60, "days")
    );
    requestBody.end_date = moment(chartDate).format("YYYY-MM-DD");

    requestBody.slug = "employee";
    let arr = [];

    if (props.active == "today") {
      
    }

    let series = [];
    let lessProductive = [];
    let Productive = [];
    let nonProductive = [];
    let undefinedProductive = [];
    requestBody.enddate = getDateFormat(selectedDate);
    console.log("requestbody",requestBody)
    getPproductivityOverviewAll(
      requestBody,
      userSession,
      org_id,
      sub_org_id
    ).then((res) => {
      let cat = [];
      if (res.status != 200) {
        if (props.indexTitle == 0) {
                    props.offChartLoader(false);
        }
      } else {
      }
      if (res.data && res.status == 200) {
        let startDate = new Date(requestBody.startdate);
        let endDate = new Date(requestBody.enddate);
        
        for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {

         
          if( isWeekday(startDate) ){

            lessProductive.push({date: moment(startDate).format("YYYY-MM-DD"),value: 0,marker: {width: 16,height: 16,},});
            Productive.push({date: moment(startDate).format("YYYY-MM-DD"),value: 0,marker: {width: 16,height: 16,},});
            nonProductive.push({date: moment(startDate).format("YYYY-MM-DD"),value: 0,marker: {width: 16,height: 16,},});
            undefinedProductive.push({date: moment(startDate).format("YYYY-MM-DD"),value: 0,marker: {width: 16,height: 16,},});
            cat.push(moment(startDate).format("YYYY/MM/DD"));
            
          }else{
          cat.push(moment(startDate).format("YYYY/MM/DD"));
          let d2 = new Date(startDate);
          let item = res.data[moment(startDate).format("YYYY-MM-DD")]?.overall;
          if (item) {
            lessProductive.push({
              date: moment(startDate).format("YYYY-MM-DD"),
              value: AddZeroFormatter(item.less_productive),marker: {width: 16,height: 16,},
            });
            Productive.push({
              date: moment(startDate).format("YYYY-MM-DD"),
              value: AddZeroFormatter(item.productive),marker: {width: 16,height: 16,},
            });
            nonProductive.push({
              date: moment(startDate).format("YYYY-MM-DD"),
              value: AddZeroFormatter(item.non_productive),
              marker: {
                width: 16,
                height: 16,
              },
            });
            undefinedProductive.push({
              date: moment(startDate).format("YYYY-MM-DD"),
              value: AddZeroFormatter(item.undefined),
              marker: {
                width: 16,
                height: 16,
              },
            });
          } else {
            lessProductive.push({
              date: moment(startDate).format("YYYY-MM-DD"),
              value: 0,
              marker: {
                width: 16,
                height: 16,
              },
            });
            Productive.push({
              date: moment(startDate).format("YYYY-MM-DD"),
              value: 0,
marker: {
width: 16,
height: 16,
},
            });
            nonProductive.push({
              date: moment(startDate).format("YYYY-MM-DD"),
              value: 0,
marker: {
width: 16,
height: 16,
},
            });
            undefinedProductive.push({
              date: moment(startDate).format("YYYY-MM-DD"),
              value: 0,
marker: {
width: 16,
height: 16,
},
            });
          }

          }



        }

        requestBody.slug = "daily_worker";
        getPproductivityOverviewAll(
          requestBody,
          userSession,
          org_id,
          sub_org_id
        ).then((res2) => {
          if (res2.status != 200) {
            if (props.indexTitle == 0) {
              props.offChartLoader(false);

            }
          } else {
            if (props.indexTitle == 0) {
              // props.offChartLoader(false);
            }
          }

          if (res2.data && res2.status == 200) {
            startDate = new Date(requestBody.startdate);

            for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {

              let d2 = new Date(startDate);
            

if(isWeekday(d2)){
  
          }else{
            

              let item = res2.data[moment(startDate).format("YYYY-MM-DD")]?.overall;
              if (item) {
                //less
                if (
                  item.less_productive > 0 &&
                  lessProductive[
                    lessProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value > 0
                ) {
                  lessProductive[
                    lessProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value =
                    (lessProductive[
                      lessProductive.findIndex(
                        (item) =>
                          item.date == moment(startDate).format("YYYY-MM-DD")
                      )
                    ].value +
                      AddZeroFormatter(item.less_productive)) /
                    2;
                } else if (
                  item.less_productive == 0 &&
                  lessProductive[
                    lessProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value == 0
                ) {
                } else if (
                  lessProductive.findIndex(
                    (item) =>
                      item.date == moment(startDate).format("YYYY-MM-DD")
                  ) > -1
                ) {
                  lessProductive[
                    lessProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value =
                    lessProductive[
                      lessProductive.findIndex(
                        (item) =>
                          item.date == moment(startDate).format("YYYY-MM-DD")
                      )
                    ].value + AddZeroFormatter(item.less_productive);
                } else {
                  lessProductive.push({
                    date: moment(startDate).format("YYYY-MM-DD"),
                    value: AddZeroFormatter(item.less_productive),
                  });
                }

                //prod
                if (
                  item.productive > 0 &&
                  Productive[
                    Productive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value > 0
                ) {
                  Productive[
                    Productive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value =
                    (Productive[
                      Productive.findIndex(
                        (item) =>
                          item.date == moment(startDate).format("YYYY-MM-DD")
                      )
                    ].value +
                      AddZeroFormatter(item.productive)) /
                    2;
                } else if (
                  item.productive == 0 &&
                  Productive[
                    Productive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value == 0
                ) {
                } else if (
                  Productive.findIndex(
                    (item) =>
                      item.date == moment(startDate).format("YYYY-MM-DD")
                  ) > -1
                ) {
                  Productive[
                    Productive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value =
                    Productive[
                      Productive.findIndex(
                        (item) =>
                          item.date == moment(startDate).format("YYYY-MM-DD")
                      )
                    ].value + AddZeroFormatter(item.productive);
                } else {
                  Productive.push({
                    date: moment(startDate).format("YYYY-MM-DD"),
                    value: AddZeroFormatter(item.productive),
                  });
                }

                //nonprod
                if (
                  item.non_productive > 0 &&
                  nonProductive[
                    nonProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value > 0
                ) {
                  nonProductive[
                    nonProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value =
                    (nonProductive[
                      nonProductive.findIndex(
                        (item) =>
                          item.date == moment(startDate).format("YYYY-MM-DD")
                      )
                    ].value +
                      AddZeroFormatter(item.non_productive)) /
                    2;
                } else if (
                  item.non_productive == 0 &&
                  nonProductive[
                    nonProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value == 0
                ) {
                  nonProductive[
                    nonProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value =
                    (nonProductive[
                      nonProductive.findIndex(
                        (item) =>
                          item.date == moment(startDate).format("YYYY-MM-DD")
                      )
                    ].value +
                      AddZeroFormatter(item.non_productive)) /
                    2;
                } else if (
                  nonProductive.findIndex(
                    (item) =>
                      item.date == moment(startDate).format("YYYY-MM-DD")
                  ) > -1
                ) {
                  nonProductive[
                    nonProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value =
                    nonProductive[
                      nonProductive.findIndex(
                        (item) =>
                          item.date == moment(startDate).format("YYYY-MM-DD")
                      )
                    ].value + AddZeroFormatter(item.non_productive);
                } else {
                  nonProductive.push({
                    date: moment(startDate).format("YYYY-MM-DD"),
                    value: AddZeroFormatter(item.non_productive),
                  });
                }

                //undefined
                if (
                  item.undefined > 0 &&
                  undefinedProductive[
                    undefinedProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value > 0
                ) {
                  undefinedProductive[
                    undefinedProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value =
                    (undefinedProductive[
                      undefinedProductive.findIndex(
                        (item) =>
                          item.date == moment(startDate).format("YYYY-MM-DD")
                      )
                    ].value +
                      AddZeroFormatter(item.undefined)) /
                    2;
                } else if (
                  item.undefined > 0 &&
                  undefinedProductive[
                    undefinedProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value > 0
                ) {
                } else if (
                  undefinedProductive.findIndex(
                    (item) =>
                      item.date == moment(startDate).format("YYYY-MM-DD")
                  ) > -1
                ) {
                  undefinedProductive[
                    undefinedProductive.findIndex(
                      (item) =>
                        item.date == moment(startDate).format("YYYY-MM-DD")
                    )
                  ].value =
                    undefinedProductive[
                      undefinedProductive.findIndex(
                        (item) =>
                          item.date == moment(startDate).format("YYYY-MM-DD")
                      )
                    ].value + AddZeroFormatter(item.undefined);
                } else {
                  undefinedProductive.push({
                    date: moment(startDate).format("YYYY-MM-DD"),
                    value: AddZeroFormatter(item.undefined),
                  });
                }
              } else {
                
              }
            }
            }
          }
  
          props.offLoader(false);
          
           
      
          props.performaceData(
            {
            categories: cat,
            series: [
              {
                name: "Out of Range",
                data: undefinedProductive,
                color: "#dedee3",
              },
              { name: "Non Productive", data: nonProductive, color: "#fc0001" },
              {
                name: "Less Productive",
                data: lessProductive,
                color: "#fdbf00",
              },
              { name: "Productive", data: Productive, color: "#90cf4e" },
            ],
          },true);
          if (props.indexTitle == 0 && props.active != "today") {
            props.offChartLoader(false);
                      
              props.changeDataofPerformance(props.active, 
                {
              categories: cat,
              series: [
                {
                  name: "Out of Range",
                  data: undefinedProductive,
                  color: "#dedee3",
                },
                {
                  name: "Non Productive",
                  data: nonProductive,
                  color: "#fc0001",
                },
                {
                  name: "Less Productive",
                  data: lessProductive,
                  color: "#fdbf00",
                },
                { name: "Productive", data: Productive, color: "#90cf4e" },
              ],
            });
          }
        });
      } else {
        toast.error(
          "TW:13 Something went wrong while retriving productivity overview "
        );
      }
    });
  }
  })();
  }, [selectedDate, chartDate]);

 function getCurrentTimeIndonesia() {
  // Create a new Date object
  let now = new Date();

  // Set the timezone offset for Indonesia (WIB = UTC+7)
  let offset = 7;

  // Get the current UTC time and add the offset for Indonesia timezone
  let utc = now.getTime() + (now.getTimezoneOffset() * 60000);
  let currentTimeIndonesia = new Date(utc + (3600000 * offset));

  return currentTimeIndonesia;
}

const syncTimer = async (chartDate) =>{
  let liveReportEmployee= await getLiveReportEmployee(userDetails.session,userDetails.org_id,userDetails.sub_org_id).then(res=>{ return  res.data})
  let pastReport = await getPlanAttendanceReport(userSession,org_id,sub_org_id,moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')).then(res=>{return res.data});
  let pastReportEmployee ={};
  pastReportEmployee.organization_live_attendance_count =0;
  pastReportEmployee.organization_active_hour_attendance_list =[]
  Object.keys(pastReport).forEach((key)=>{    
    let currentTimeIndonesia = getCurrentTimeIndonesia();
    let filteredData = pastReport[key].attendance_list.filter(item => new Date(item.check_in.replace(" GMT","")) <= currentTimeIndonesia);
    pastReportEmployee.organization_live_attendance_count+= filteredData.length;
    pastReportEmployee.organization_active_hour_attendance_list.push(...filteredData);    
  });  



  
  SetPlannedCount(liveReportEmployee.organization_live_attendance_count);
  SetPlannedEmp(liveReportEmployee.organization_active_hour_attendance_list);


  getOnBoardEmp(userSession, org_id, moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), sub_org_id).then(res2=>{
    let emp = [];
    if(res2.status === 200){
      emp = res2.data.regular ? [...res2.data.regular] : [];
      if (res2.data.daily) {
         emp.push(...res2.data.daily);
      }
    }
    

    let startHour = props.workday.start_hour?props.workday.start_hour.split(':')[0]:"00";
    let startMinute = props.workday.start_hour?props.workday.start_hour.split(':')[1]:"00";

    let endHour = props.workday.end_hour?props.workday.end_hour.split(':')[0]:"00";
    let endMinute = props.workday.end_hour?props.workday.end_hour.split(':')[1]:"00";

//today
    let start= moment().set({hour:startHour,minute:startMinute});
    let end = moment().set({hour:endHour,minute:endMinute})
    console.log("startHour",startHour,startMinute)
    console.log("endHour",endHour,endMinute)
    if(startHour==="00" && startMinute==="00" && endHour==="23" && endMinute=="59"){
      // start= moment().subtract(1,'day').set({hour:startHour,minute:startMinute});
      console.log("start Logic",start);
    }
    else if(moment() < end){
      start= moment().subtract(1,'day').set({hour:startHour,minute:startMinute});
    }else{
      end = moment().add(addDay,'day').set({hour:endHour,minute:endMinute});
    }
    let currentTodayOnboarded = emp.filter(el=> moment(el.check_in) >= start && moment(el.check_in) <= end);



    emp = emp.filter(el=>el.check_out===null && moment(el.check_in) >= start );
    console.log("outfacility test allemp those who is active",emp);
    SetAllEmployee(emp);
    
    let dataPlan = liveDataPlanActualFormation(pastReportEmployee.organization_active_hour_attendance_list,currentTodayOnboarded);

    let todayLive ={};
    todayLive.planned =pastReportEmployee.organization_active_hour_attendance_list.length;
    todayLive.actual = currentTodayOnboarded.length;
    todayLive.yes = dataPlan.filter(el=>el.compliance =="Yes").length
    todayLive.no = dataPlan.filter(el=>el.compliance =="No").length
    todayLive.absent = dataPlan.filter(el=>el.compliance =="Absent").length
    todayLive.unexpected = dataPlan.filter(el=>el.compliance =="Unexpected").length
    todayLive.compliance = (todayLive.yes/ (todayLive.yes + todayLive.no + todayLive.absent)) * 100
    
    SetHeaderData(todayLive)
    SetPlanActualData(dataPlan)
    
    
    //only active
    // SetAllEmployee(emp);
    
    SetTodayCheckedIn(emp.length)
    
    SetEmployee(emp)
    getFacilityWidget(userSession, org_id, sub_org_id).then(res=>{
      if(res.status==200){
    let checkedIn =emp?[...emp]:[]; // Assuming 'employee' is your initial array of all employees
    let infacility = [];
    let outfacility = [];
    let allEMp=[];
    let outfacilityArray = [];

    console.log("before format",res.data);
    console.log("before format infacility",res.data.infacility);
    console.log("before format outfacility",res.data.outfacility);

    for (const [location, workers] of Object.entries(res.data.infacility)) {
      workers.forEach(worker => {
        infacility.push({
          name: worker.name,
          worker_id: worker.worker_id,
          location: location
        });
        // Remove the checked-in employee from the 'outfacility' array
        checkedIn = checkedIn.filter(emp => emp.worker_id !== worker.worker_id);
      });
    }
    
    

    for (const [location, workers] of Object.entries(res.data.outfacility)) {
      workers.forEach(worker => {
        outfacilityArray.push({
          name: worker.name,
          worker_id: worker.worker_id,
          location: location
        });
      });
    }
    
console.log("outfacility this user is causing issue ", checkedIn,outfacilityArray);
    // Populate 'outfacility' with the remaining checked-in employees
    outfacility = checkedIn.map(emp => ({
      name: emp.name,
      worker_id: emp.worker_id,
      location: outfacilityArray.find(el=>el.name.toLowerCase().trim() === emp.name.toLowerCase().trim())?.location
    }));


    console.log("outFacility",outfacility,checkedIn);
    let checked =res.data.total_count;
    let inFac =  res.data.facility_count;
    let magic =  res.data.deviation_percent;
      SetModalDataInfacility(infacility);


      SetModalDataOutfacility(outfacility)
    SetCheckedIn((prev)=>({prev:prev.current,current:checked}));
    SetInFacility((prev)=>({prev:prev.current,current:inFac}));
    SetMagicRatio((prev)=>({prev:prev.current,current:magic}));
    setTimeout(function(){
    if(loaderSecondWidget){
        SetLoaderSecondWidget(false);
      }
    },500);
      }

    });


  });

  



      
   


  }

  useEffect(() => {
    syncTimer();
  const id3 = setInterval(function(){
    syncTimer(chartDate);
  }, 30000);

  return () =>{
    clearInterval(id3);
  }   
},[chartDate]);

  function AGTable(data,key){    
        let widthCal= 0;
    
        let arr;

        arr=<div className={"keyAGTableSmall"}><div className="ag-theme-alpine if cell-size-40" style={{height:270,maxHeight:270 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
              autoGroupColumnDef={{
                  headerName: 'Name',field: 'emp_name',
              }}
              headerHeight={20}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
          
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
          <AgGridColumn
              field="name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value?params.value.toLowerCase():"-"}
              cellClass={'textCapitalize textCap'}
              // flex={1}
          />
          <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              // flex={1}
          />
          <AgGridColumn 
              field="location" 
              headerName="Location" 
              valueFormatter={(params) =>params.value?params.value.toLowerCase():"-"}    
              cellClass={'textCapitalize textCap'}
              // flex={1}
             >
          </AgGridColumn> 
      </AgGridReact>
      </div></div>
      
      return arr
    
    
    }

    function handleOnChangeContactRankValue(event) {
     //   props.handleChangeValue(event.target.value)
    }

    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'block'
        }
    }

    const handleMouseLeave = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'none'
        }
    }
 function AGGridEMptyMEssage(key){
            let msg;
            msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
        return msg
      }
  function callback(key) {
    
    SetActiveTab(key);
  }

  function checkCompliance(planDate, actualDate,name) {
    // Convert dates to JavaScript Date objects
    const planDateTime = new Date(planDate);
    const actualDateTime = new Date(actualDate);
    
    // Check if plan date is in the future
    if (planDateTime > new Date().toLocaleString('en-US', { timeZone: indonesiaTimeZone })) {
        return "Awaiting";
    }

    // Calculate the difference in minutes between plan date and actual date
    const timeDifference = Math.abs(actualDateTime - planDateTime) / (1000 * 60);
    
    // Check if the difference is less than or greater than 30 minutes
    if (timeDifference <= 30 && timeDifference >= -30) {
        return "Yes";
    } else {
        return "No";
    }
}

function liveDataPlanActualFormation(dataPlan,empployee){
  let data=[];
   
    for(let i=0;i<dataPlan.length;i++){
      //check data exist
      let single={}
      let emp= empployee.find(el=>el.worker_id== dataPlan[i].worker_id);
      //found employee
      if(emp){
        single.name= dataPlan[i].name;
        single.worker_id= dataPlan[i].worker_id;
        single.function = dataPlan[i].sub_function_name || emp.department_name;
        // let planSplit = dataPlan[i].shift_start_time.split(":");
        // let planStart = moment(dataPlan[i].employee_details_datetime).set({hour:planSplit[0],minute:planSplit[1],second:planSplit[2]});
        let planStart = moment(dataPlan[i].check_in.replace(" GMT",""));
        let acStart = moment(emp.check_in);
        single.plan_check_in = moment(planStart).format('HH:mm A');
        single.actual_check_in = moment(acStart).format('HH:mm A');
        single.compliance = checkCompliance(planStart, acStart,dataPlan[i].name);
        single.date= planStart.toDate();
        data.push(single);

      }else{
        //not found this mean not checkin
        single.name= dataPlan[i].name;
        single.worker_id= dataPlan[i].worker_id;
        single.function = dataPlan[i].sub_function_name;
        // let planSplit = dataPlan[i].shift_start_time.split(":");        
        // let planStart = moment(dataPlan[i].employee_details_datetime).set({hour:planSplit[0],minute:planSplit[1],second:planSplit[2]});

        let planStart = moment(dataPlan[i].check_in.replace(" GMT",""));

        //future time
        if(new Date(planStart) > new Date().toLocaleString('en-US', { timeZone: indonesiaTimeZone })){
          single.compliance = "Awaiting";  
        }else if(Math.abs(new Date(new Date().toLocaleString('en-US', { timeZone: indonesiaTimeZone })) - new Date(planStart)) / (1000 * 60) > 30){
          single.compliance = "Absent";  
        }else{
          single.compliance = "Awaiting"; 
        }

        single.date= planStart.toDate();
        single.plan_check_in = moment(planStart).format('HH:mm A');
        single.actual_check_in = " - ";
        data.push(single);
      }


    }

    //unplanned employee
  for(let i=0;i<empployee.length;i++){
      //check data exist
      if(!data.find(el=>el.worker_id == empployee[i].worker_id)){
        let single={}
              let emp= empployee[i];
      //found employee
        single.name= emp.name;
        single.worker_id= emp.worker_id;
        single.function = emp.sub_function_name || emp.department_name;
        let planSplit = " - ";
        let planStart = " - ";
        let acStart = moment(emp.check_in);
        single.plan_check_in = " - ";
        single.actual_check_in = moment(acStart).format('HH:mm A');
        single.compliance = "Unexpected";
        single.date= acStart.toDate();
        data.push(single);
        
      }
    
    }
    data = data.sort((a,b)=>new Date(a.date)- new Date(b.date));
    data = data.map((el,index)=>({...el,serial:index+1}))
    return data;
}

  function AGTablePlanActual(data){    
    

    let widthCal= 0;
    
        let arr;

        arr=<div className={"keyAGTableSmallPlan"}><div className="ag-theme-alpine if cell-size-40" style={{height:475,maxHeight:475 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
            
              headerHeight={20}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
            <AgGridColumn
              field="serial"
              headerName={"S.No"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={80}
              maxWidth={80}
          />
          <AgGridColumn
              field="name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap textLeft'}
              flex={1}
          />
          <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
          <AgGridColumn 
              field="function" 
              headerName="Function" 
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
                        <AgGridColumn 
              field="plan_check_in" 
              headerName="Check-In (Plan)" 
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={'textUppercase textCap'}
              flex={1}
             />
                        <AgGridColumn 
              field="actual_check_in" 
              headerName="Check-In  (Actual)" 
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
             ></AgGridColumn>
            <AgGridColumn 
              field="compliance" 
              headerName="Compliance" 
              valueFormatter={(params) =>params.value?params.value:"-"}    
              cellClass={(params) =>params.value==="Yes"?'textCapitalize textCap textCapGreen':params.value==="No" || params.value==="Absent"?'textCapitalize textCap textCapRed':'textCapitalize textCap textCapYellow'}
              flex={1}
             ></AgGridColumn>
 
      </AgGridReact>
      </div></div>
      
      return arr
    
    }





    return (
        // <div className={'threatWatchMainDiv ' + (props.contaminatedEmployeeCount <= 0 ? ' greenGradienColor' : ' orangeGradientColor')} >
        // <div className={props.todayStats > 70 ?'threatWatchMainDiv greenGradienColor': 'threatWatchMainDiv orangeGradientColor' } >
        <div className='threatWatchMainDiv whitebgShadow' >
            <Row>
                <Col lg={3}>
                    <div className="threatWatchTextDiv">
                        <div className="title">
                            {getTranslatedText('Overall Performance')}
                        </div>
                        <div className="subTitle">As of {moment(selectedDate).format('Do MMM YYYY')}</div>

                        <div className="workSpaceStripeDiv">
                            {
                                // threatWatchColor && threatWatchColor == 'green' ? getTranslatedText('Hey, Your Workplace Is Safe !') : getTranslatedText('Hey, your Workplace is Not Safe!')
                                todayStats.overall > 80 ?'Hey, Your Workplace Is Excellent !' :todayStats.overall < 80 && todayStats.overall > 70 ?'Hey, Your Workplace Is Good !' :'Hey, Your Workplace Is Optimal!'
                            }
                            <div className={'thumbIconDiv greenBorderColor ' + (todayStats.overall < 70 ? 'red':' green' )}>
                                
                                <img src={todayStats.overall > 70 ? greenThumbIcon:downThumbIcon   } />
                                {/* <img src={ greenThumbIcon} /> */}
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={5} className="parent-lg-6">
                    <div className={"threatWatchAnalyticsMainDiv threatWatchAnalyticsMainDiv2 "}>
                        <div className="eachColumnDiv contaminatedMainDiv">
                            <div className={loader1?'skeleton threeList':todayStats&& todayStats.overall > 70?'threeList greenGradienColor':'threeList orangeGradientColor'}>
                                <h4 className='skeleton-text'>On {moment(selectedDate).format("DD MMM")} 
                                
                                <React.Fragment>
                                            {/* <img
                                                alt=''
                                                src={helpIcon}
                                                onMouseEnter={() => handleMouseEnter(`desc_live`)}
                                                onMouseLeave={() => handleMouseLeave(`desc_live`)}
                                                className='iconSmallHelp'
                                            /> */}

<div className='helpSmallInfo iconSmallHelp' 
                                                onMouseEnter={() => handleMouseEnter(`desc_live`)}
                                                onMouseLeave={() => handleMouseLeave(`desc_live`)}
                                            >
                                                    <HelpIcon/>
                                            </div>
                                            
                                            <div className='tooltipNewStyle' id={`desc_live`} >
                                              <h5>Daily Performance</h5>
                                              <p>Based on the master date selected, this shows the overall manpower performance for the selected date.</p>
                                              
                                              </div>
                                        </React.Fragment>
                                
                                </h4>
                                <div className='newHeadline' >
                                    <strong className='skeleton-text'>{todayStats && !isNaN(todayStats.overall)?todayStats.overall.toFixed(2):0}%</strong>
                                    <small className='skeleton-text' style={{margin:loader1?"15px 0px":0}} >As on {moment(selectedDate).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")?moment().format('hh A'):"-"}</small>
                                </div>
                                <div className='smallerHeadline'>
                                  <Row>
                                    <Col lg={4}>{loader1?"":<img src={Morning}  />}<strong className='skeleton-text'>{todayStats && todayStats.P?todayStats.P:0}%</strong></Col>
                                    <Col lg={4}>{loader1?"":<img src={Afternoon} />} <strong className='skeleton-text'>{todayStats && todayStats.S?todayStats.S:0}%</strong></Col>
                                    <Col lg={4}>{loader1?"":<img src={Night} />} <strong className='skeleton-text'>{todayStats && todayStats.M?todayStats.M:0}%</strong></Col>
                                  </Row>
                                </div>
                            </div>
                            <div className={loader2?'skeleton threeList':WeekStats && WeekStats.overall > 70?'threeList greenGradienColor':'threeList orangeGradientColor'}>
                                <h4 className='skeleton-text'  >{weekName?weekName:"week"}
                                <React.Fragment>
                                            {/* <img
                                                alt=''
                                                src={helpIcon}
                                                onMouseEnter={() => handleMouseEnter(`week_live`)}
                                                onMouseLeave={() => handleMouseLeave(`week_live`)}
                                                className='iconSmallHelp'
                                            /> */}
                                            <div className='helpSmallInfo iconSmallHelp' 
                                                onMouseEnter={() => handleMouseEnter(`week_live`)}
                                                onMouseLeave={() => handleMouseLeave(`week_live`)}
                                            >
                                                    <HelpIcon/>
                                            </div>
                                            <div className='tooltipNewStyle' id={`week_live`} >
                                              <h5>Weekly Performance</h5>
                                              <p>Based on the master selected date, this shows the overall manpower performance for the week. For eg. If the selected master date is a Thursday, this number will show performance from the previous Sunday to Thursday.</p>                                           
                                              </div>
                                        </React.Fragment>
                                </h4>
                                <div className='newHeadline'>
                                     <strong className='skeleton-text'>{WeekStats.overall?isNaN(WeekStats.overall)?"0%":WeekStats.overall.toFixed(2)+"%":"0%"}</strong>
                                    <small className='skeleton-text' style={{margin:loader2?"15px 0px":0}}> As on {moment(selectedDate).format("dddd")}</small>
                                </div>
                                <div className='smallerHeadline'>
                                  <Row>
                                    <Col lg={4}>{loader2?"":<img src={Morning} />}<strong className='skeleton-text'>{WeekStats && WeekStats.P?WeekStats.P:0}%</strong></Col>
                                    <Col lg={4}>{loader2?"":<img src={Afternoon} />} <strong className='skeleton-text'>{WeekStats && WeekStats.S?WeekStats.S:0}%</strong></Col>
                                    <Col lg={4}>{loader2?"":<img src={Night} />} <strong className='skeleton-text'>{WeekStats && WeekStats.M?WeekStats.M:0}%</strong></Col>
                                  </Row>
                                </div>
                            </div>

                            <div className={loader3?'skeleton threeList':monthStats && monthStats.overall > 70?'threeList greenGradienColor':'threeList orangeGradientColor'}>
                                <h4 className='skeleton-text' >{moment(selectedDate).format("MMM, YYYY")}
                                <React.Fragment>
                                            {/* <img
                                                alt=''
                                                src={helpIcon}
                                                onMouseEnter={() => handleMouseEnter(`month_live`)}
                                                onMouseLeave={() => handleMouseLeave(`month_live`)}
                                                className='iconSmallHelp'
                                            /> */}
                                                                                        <div className='helpSmallInfo iconSmallHelp' 
                                                onMouseEnter={() => handleMouseEnter(`month_live`)}
                                                onMouseLeave={() => handleMouseLeave(`month_live`)}
                                            >
                                                    <HelpIcon/>
                                            </div>
                                            <div className='tooltipNewStyle' id={`month_live`} style={{width:"260px",maxWidth:"260px"}} >
                                              <h5>Monthly Performance</h5>
                                              <p>Based on the master selected date, this shows the overall manpower performance for the month. For eg. If the selected master date is a 24th Oct, this number will show performance from the 1st Oct to 24th Oct.</p>
                                              
                                              </div>
                                        </React.Fragment>
                                </h4>
                                <div className='newHeadline'>
                                    <strong className='skeleton-text'>{monthStats.overall?!isNaN(monthStats.overall)? monthStats.overall.toFixed(2)+"%":"0%":"0%"}</strong>
                                    
                                    <small className='skeleton-text'style={{margin:loader3?"15px 0px":0}}>As on {moment(selectedDate).format("Do")}</small>
                                </div>
                                <div className='smallerHeadline'>
                                  <Row>
                                    <Col lg={4}>{loader3?"":<img src={Morning} />}<strong className='skeleton-text'>{monthStats && monthStats.P?monthStats.P:0}%</strong></Col>
                                    <Col lg={4}>{loader3?"":<img src={Afternoon} />} <strong className='skeleton-text'>{monthStats && monthStats.S?monthStats.S:0}%</strong></Col>
                                    <Col lg={4}>{loader3?"":<img src={Night} />} <strong className='skeleton-text'>{monthStats && monthStats.M?monthStats.M:0}%</strong></Col>
                                  </Row>
                                </div>
                            </div>

                        </div>


                    </div>
                </Col>

                <Col lg={4}>
                    

                        <div className={loader && loaderSecondWidget?'skeleton threatWatchAnalyticsMainDiv threatWatchAnalyticsMainDiv2 threatWatchAnalyticsMainDiv3 greenGradienColor':'threatWatchAnalyticsMainDiv threatWatchAnalyticsMainDiv2 threatWatchAnalyticsMainDiv3 greenGradienColor'} >
                            {loader?"":<div className='livearea'>LIVE</div>}

                            {loader?"":
                            <div className='livearea liveAreaHelp'>
                              <React.Fragment>
                                      
                                                                                  <div className='helpSmallInfo iconSmallHelp' 
                                                onMouseEnter={() => handleMouseEnter(`facilityHelp`)}
                                                onMouseLeave={() => handleMouseLeave(`facilityHelp`)}
                                            >
                                                    <HelpIcon/>
                                            </div>
                                            <div className='tooltipNewStyle' id={`facilityHelp`} style={{width:"260px",maxWidth:"260px",left:"-210px"}} >
                                              <h5>Facility Counter</h5>
                                              <p>This box shows the number of workers who have checked in and the number of workers who are in working ops areas. This helps to understand how many workers (or % of workers) are not working at any given point in time.</p>
                                              
                                              </div>
                                        </React.Fragment></div>}
                        <div className="eachColumnDiv contaminatedMainDiv">
                          <div className='topThreeList'>
                            <div className={loader && loaderSecondWidget?'skeleton threeList':todayStats > 70?'threeList greenGradienColor':'threeList orangeGradientColor'}>
                                <h4 className='skeleton-text'>Planned</h4>
                                <div className='newHeadline' >
                                    <strong className={'skeleton-text'}>{plannedCount ?plannedCount:" - "}</strong>
                                    <small className='skeleton-text' style={{color:"transparent",opacity:0}}> - {moment().format('hh:mm:ss')}</small>
                                </div>
                            </div>
                            <div className={loader && loaderSecondWidget?'skeleton threeList':todayStats > 70?'threeList greenGradienColor':'threeList orangeGradientColor'}>
                                <h4 className='skeleton-text'>Checked-In
                                                                <React.Fragment>
                                            {/* <img
                                                alt=''
                                                src={helpIcon}
                                                onMouseEnter={() => handleMouseEnter(`month_live`)}
                                                onMouseLeave={() => handleMouseLeave(`month_live`)}
                                                className='iconSmallHelp'
                                            /> */}
                                                                                        <div className='helpSmallInfo iconSmallHelp' 
                                                onMouseEnter={() => handleMouseEnter(`checkedIN`)}
                                                onMouseLeave={() => handleMouseLeave(`checkedIN`)}
                                            >
                                                    <HelpIcon/>
                                            </div>
                                            <div className='tooltipNewStyle' id={`checkedIN`} style={{width:"260px",maxWidth:"260px"}} >
                                              <h5 style={{width:"100%"}}>Total Active: <strong style={{float:"right"}}>{checkedIn.current}</strong></h5>
                                              <h5 style={{width:"100%"}}>Today Check-In: <strong style={{float:"right"}}>{todayCheckedIn}</strong></h5>
                                              <h5 style={{width:"100%"}}>Yesterday Non-Checkout: <strong style={{float:"right"}}>{checkedIn.current - todayCheckedIn}</strong></h5>
                                              
                                              
                                              </div>
                                        </React.Fragment>
                                </h4>
                                <div className='newHeadline' >
                                    <strong className={'skeleton-text'}>{checkedIn  &&  checkedIn.current ?checkedIn.current:" - "}</strong>
                                    <small className='skeleton-text' style={{color:"transparent",opacity:0}}> - {moment().format('hh:mm:ss')}</small>
                                </div>

                            </div>

                            <div className={loader && loaderSecondWidget?'skeleton threeList':WeekStats > 70?'threeList greenGradienColor':'threeList orangeGradientColor'} style={{cursor:"pointer"}} onClick={()=>SetModalFacility(true)}>
                                <h4 className='skeleton-text'  >In - Ops</h4>
                                <div className='newHeadline'>
                                    <strong className={'skeleton-text'}>{inFacility  &&  inFacility.current ?inFacility.current:" - "}</strong>
                                    {inFacility  &&  inFacility.current ?<small className={loader && loaderSecondWidget?'skeleton-text':'readmoreStyle'} style={{cursor:"pointer",opacity:1,marginTop:"5px"}}>More Info <span className='rightGoing'><RightAngle/></span></small>:<small className='skeleton-text' style={{color:"transparent",opacity:0}}>-</small>}
                                </div>
                            </div>
                            <div className={loader && loaderSecondWidget?'skeleton threeList':monthStats > 70?'threeList greenGradienColor':'threeList orangeGradientColor'}>
                                <h4 className='skeleton-text' >Magic Ratio</h4>
                                <div className='newHeadline'>
                                    <strong className={magicRatio?magicRatio.current >= magicRatio.prev && magicRatio.current >= 0?'skeleton-text greenColor':'skeleton-text redColor':'skeleton-text redColor'}>
                                        <div className='iconShape'>
                                           {magicRatio?magicRatio.current >= magicRatio.prev && magicRatio.current >= 0?
                                           
                                             <div className={'iconNew upicon active'}><UpIcon/></div>:
                                            <div className={'iconNew downicon active'}><UpIcon/></div>:<div className={'iconNew downicon active'}><UpIcon/></div>
                                           }
                                        </div>
                                        <div className='textShapre'>{Math.abs(magicRatio.current)+"%"}</div></strong>        
                                    <small className='skeleton-text' style={{color:"transparent",opacity:0}}>-</small>
                                </div>
                            </div>
                            </div>
                            <div className='liveCountPlanActual smallHeadline' style={{padding:"0px 15px"}}>
                                  <Row>
                                      <Col><img src={Plan} alt="plan" title="Plan Attendance" /></Col>
                                      <Col><img src={Actual} alt="Actual" title="Actual Attendance"/></Col>
                                      <Col><img src={Yes} alt="Yes" title="Yes Count" /></Col>
                                      <Col ><img src={No} alt="No" title="No Count" /></Col>
                                      <Col ><img src={Absent}alt="Absent"  title="Absent Count" /></Col>
                                      <Col><img src={Unexpected}alt="Unexpected" title="Unexpected Count"/></Col>
                                      <Col><img src={Compliance} alt="Compliance" title="Compliance"/></Col>
                                  </Row>         
                                  <Row>
                                      <Col><strong>{headerData.planned}</strong></Col>
                                      <Col><strong>{headerData.actual}</strong></Col>
                                      <Col><strong>{headerData.yes}</strong></Col>
                                      <Col><strong>{headerData.no}</strong></Col>
                                      <Col ><strong>{headerData.absent}</strong></Col>
                                      <Col><strong>{headerData.unexpected}</strong></Col>
                                      <Col><strong>{headerData?.compliance?.toFixed(2)}%</strong></Col>
                                  </Row>         
                            </div>               

                        </div>



                    </div>
                    
                    
                </Col>
            </Row>
              <Modal
      visible={modalFacility}
      // title={}
      onCancel={()=>SetModalFacility(false)}
      footer={null}
      maskClosable={false}    
       style={{ width: '1200px' }}
        width={1000}  
    >
             <div
        className="ant-modal-content"
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        
        style={{boxShadow:"unset",margin:"-20px",padding:"20px",cursor:"grabbing"}}

      >
              <div class="modal-cyclecount" style={{width:"100%"}}>
               <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab={'Plan Vs Actual'} key="1">
        {/* Content of Tab Pane 1 */}
        {AGTablePlanActual(planActualData)}
      </TabPane>
      <TabPane tab="Facility Counter" key="2">
        <Row>
          <Col lg={12}>
            <h4 className='someTitleThing'>Facility Counter - Worker Details <strong className='rounded rounded-pink'>{employee.length}</strong></h4>
          </Col>
        </Row>
              <Row>
                <Col lg={6} className='facilityCompare inFacilityArea'>
                  <h6>In-Facility: <strong className='rounded rounded-pink'>{modalDataInfacility.length}</strong></h6>
                  
                  <div className='siteWidgetTable siteWidgetTable2 siteWidgetTable3 '>
                    {AGTable(modalDataInfacility,'modalDataInfacility')}
                  </div>
                </Col>
                                <Col lg={6} className='facilityCompare outFacilityArea'>
                  <h6>Out-Facility: <strong className='rounded rounded-pink'>{modalDataOutfacility.length}</strong></h6>
                  
                  <div className='siteWidgetTable siteWidgetTable2 siteWidgetTable3'>
                                    {AGTable(modalDataOutfacility,'modalDataOutfacility')}
                            </div>
                </Col>
              </Row>
      </TabPane>
     
    </Tabs>
             

              
</div>
</div>
              </Modal>
        </div>
    )
}

export default TopAreaPerformance