import React, { useState,useEffect,useRef } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { CommonHeading } from "../../common/commonHeading";
import moment from "moment";
import {
  getLanguageTranslation,
} from "../../dashboard/actionMethods/actionMethods";
import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider } from 'antd';
import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import spinnerLoader from "../../assets/images/Spinner Loader.gif";
import { getCategoryList} from '../../realtimeTracker/actionMethods/actionMethods'
import {getLocationCordinate,getLocationList} from '../actionMethods/actionMethods'

import { getDepartment } from '../../productivity/actionMethods/actionMethods';

import {getCycleCountPlan,getLiveData,getOnBoardEmployee,getLocationType} from '../../realtimeTracker/actionMethods/actionMethods';

import {ReactComponent as Eye} from '../../assets/images/eye.svg';
import ReactModal from 'react-modal';
import { getActiveEmployeeV2 } from '../../realtimeTracker/actionMethods/actionMethods';
import { Select } from 'antd';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {ReactComponent as CircleArrow} from '../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../assets/images/caret-down.svg';

import {ReactComponent as PlayCircle} from '../../assets/images/play-circle.svg';
import {ReactComponent as PauseCircle} from '../../assets/images/pause-circle.svg';
import DefaultManIcon from '../../assets/images/deafutManIconSmall.png'
import ManProductiveIcon from '../../assets/images/man.png'
import ManLessProductiveIcon from '../../assets/images/dailyworker-yellow.png'
import ManNonProductiveIcon from '../../assets/images/nonProductiveMan.png'
import { useScript, useStyle } from  './useScriptAndStyle';
import Light from '../../assets/images/light.png';
import Street from '../../assets/images/street.png';
import Satellite from '../../assets/images/satellite.png';
import Outdoors from '../../assets/images/outdoor.png';
import Dark from '../../assets/images/dark.png';
import LoaderSpinner from '../../assets/images/loader.svg';
import '../style/styles.scss';
import '../../realtimeTracker/style/style.scss'

let dataSocket=[];   
let oldLocation=[];
let batch30=[];
let dataRemove =false;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    maxWidth: "80%",
    height: "650",
  },
};


const mystyle = {
  display: "none !important",
};

let layerPublish=0;
let fetchHit =0;
const FacilityMapBox = (props) => {

  const { Option } = Select;
  

  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

    let resetTime= moment().isSame(date, 'day')?30:5;  
    const [live,SetLive]=useState(moment().isSame(date, 'day')?true:false);
  const [nextUpdate,SetNextUpdate] = useState(resetTime);
  const [liveAPI,SetLIveAPI] = useState(false);

    const [permission,SetPermission] =useState([])
    const [permissionAPICall,SetPermissionAPICall] = useState(false);
    const [locationListDefault,SetLocationListDefault] =  useState([]);
    const [reloadInc,SetReloadInc] = useState(0);
  const [selectedDate, updateSelectedDate] = useState(date);
  const [dates, setDates] = useState({start:date,end:moment.utc()});
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [localTime,SetLocalTime] = useState([]);
    
  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let prefixURL = process.env.REACT_APP_URL;
  let userSession = userDetails ? userDetails.session:props.match.params.session?props.match.params.session:'';
  let org_id = userDetails ? userDetails.org_id : props.match.params.org_id?props.match.params.org_id:'';
  const role = props.match.params.role?props.match.params.role:null; 
  const [locationCordinates,SetLocationCordinates]= useState([]);  
  const [employeeALL,SetEmployeeAll] =useState([]);
  const [employeeDefault,SetEmployeeDefault] =useState([]);
  const [department,SetDeparment] = useState([]);
  const [sub_department,SetSubDeparment] = useState([]);
  const [activeDepartment,SetActiveDepartment] = useState([]);
  const [activeSubDepartment,SetActiveSubDepartment] = useState([]);
  const [apiCall,SetAPICall]=useState(false)
  const [activeAccord,SetActiveAccord] =useState([]);
  const [locationList,SetLocationList] =  useState([]);
  const [locationListMapBox,SetLocationListMapBox] =  useState([]);
  const [loader,SetLoader]=useState(true);
  const [loaderEmp,SetLoaderEmp] =useState(false);
  const [slug,SetSlug]=useState('');
  const [modalView,SetModalView]=useState(false);
  const [modalData,SetModalData ]= useState([]);                   
  const [inc,SetInc]=useState(0)
  const [time,SetTime] =useState('');      
  const [delayTimer,SetDelayTimer]= useState(false);
  const [departmentName,SetDepartmentName]=useState([]);
  const [subDepartmentName,SetSubDepartmentName]=useState([]);
  const [playPosition,SetPlayPosition]= useState(0);
  const [currentData,SetCurrentData]= useState([]);
  let previousCoordinates = [];
  const mapContainerRef = useRef(null);
  const markersRef = useRef([]);
  const mapRef = useRef(null);
      const activePopupRef = useRef(null);
  const existingPolygons = useRef([]);
  const [selectedStyle, setSelectedStyle] = useState('mapbox://styles/mapbox/streets-v11'); // Default style


  
  const [timeOptions, setTimeOptions] = useState([]);
  const [startTime,SetStartTime] = useState('');
  const [endTime,SetEndTime] = useState('');
  const [displayTime,SetDisplayTime] = useState('');
  const [autoplay,SetAutoPlay] = useState(true);
  const [hoverValue, setHoverValue] = useState(null);
  const [productiveType,SetProductiveType] = useState([]);
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1    
  const [currentMode, setCurrentMode] = useState('Streets');
  const [currentModeImg, setCurrentModeImg] = useState(Street);
  const [widthBox, SetWidthBox] = useState(60);
    const mapboxtoken = process.env.REACT_APP_MAPBOX_TOKEN

  const changeMapStyle = (newStyle, title, image) => {
        setSelectedStyle(newStyle);
        setCurrentMode(title);
        setCurrentModeImg(image);
        setTimeout(() => SetWidthBox(60), 500);

        if (mapRef.current) {
            mapRef.current.setStyle(newStyle);
        }
    };
    
    const mapBoxMode = [
        { function: 'mapbox://styles/mapbox/streets-v11', title: "Streets", image: Street },
        { function: 'mapbox://styles/mapbox/outdoors-v11', title: "Outdoors", image: Outdoors },
        { function: 'mapbox://styles/mapbox/light-v10', title: "Light", image: Light },
        { function: 'mapbox://styles/mapbox/dark-v10', title: "Dark", image: Dark },
        { function: 'mapbox://styles/mapbox/satellite-v9', title: "Satellite", image: Satellite }
    ];  


        useScript('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.js');
    useStyle('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.css');
    useScript('https://cdnjs.cloudflare.com/ajax/libs/Turf.js/6.5.0/turf.min.js');

    useEffect(() => {
        if (window.mapboxgl && !loader && mapContainerRef.current) {
            window.mapboxgl.accessToken = mapboxtoken

            // Initialize the map
            mapRef.current = new window.mapboxgl.Map({
                container: mapContainerRef.current,
                style: selectedStyle,
                center: [100.58317249183762, 13.72696169281258], // Example coordinates
                zoom: 17,
                projection: 'globe',
                pitch: 10,
                bearing: -15.6,
            });

            const nav = new window.mapboxgl.NavigationControl();
            mapRef.current.addControl(nav, 'top-right');
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, [selectedStyle, loader, locationList, locationCordinates]);

        useEffect(() => {
        const checkTurfLoaded = setInterval(() => {
            if (window && window.turf) {
                clearInterval(checkTurfLoaded);

                const requestBody = {
                    session: userDetails.session,
                    org_id: userDetails.org_id,
                };
                getLocationList(requestBody)
                    .then(res => {
                        if (res.status === 200 || res.code === 200) {
                            SetLocationListMapBox(res.data);

                            getLocationCordinate(requestBody).then(res3 => {
                              if (res3.status === 200) {
                                  const locList = res3.data.map(el => {
                                      const polygonFeature = window.turf.polygon([el.coordinates]);
                                      const centroid = window.turf.centroid(polygonFeature);
                                      return { ...el, centroid: centroid };
                                  });
                                getCycleCountPlan(userSession,org_id,moment().utc().format('YYYY-MM-DD')).then(res2=>{
                                      if(res2.status==200){
                                        for(let i=0;i<locList.length;i++){
                                            let ctPlan = moment().format('HH');
                                              let data = res2.data[locList[i].tag_serial]
                                              data =  data?data.find(item=>item.hour == ctPlan+":00"):undefined; 
                                              let result =0;
                                              if(data){
                                                  locList[i].planned = Math.ceil(data.cycle_count);
                                              }else{
                                                locList[i].planned = '-';
                                              }
                                          
                                          }
                                          

                                      }else{
                                      
                                        for(let i=0;i<locList.length;i++){
                                            locList[i].planned = '-';
                                          }
                                          
                                          SetLocationCordinates(locList);
                                      }
                                    })



                                  SetLocationCordinates(locList);
                                  SetLoader(false);
                              }
                            }).catch(error => console.error("Error fetching location coordinates:", error));
                        }
                    })
                    .catch(error => console.error("Error fetching location list:", error));
            }
        }, 100);

        return () => clearInterval(checkTurfLoaded);
    }, []);

        useEffect(() => {

        if (locationCordinates.length > 0 && window.mapboxgl && mapRef.current && !loader) {
            locationCordinates.forEach(location => {
                const coordinates = location.coordinates;
                
                let loc = locationListMapBox.find(el => el.tag_serial === location.location_serial);


                mapRef.current.on('style.load', () => {
                    existingPolygons.current.push([coordinates]);
                    mapRef.current.addLayer({
                        id: `outline-${loc.id}`,
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: [coordinates]
                                },
                            },
                        },
                        layout: {},
                        paint: {
                            'line-color': '#4ec274',
                            'line-width': 1
                        }
                    });
                    addPolygonWithLabel([coordinates], loc.location_name, loc.id, location.centroid);
                });
            });
        }

        if(dataSocket){
          processFacilityMap(dataSocket);
        }
        
    }, [locationCordinates, loader, locationListMapBox,selectedStyle]);
    
    function addPolygonWithLabel(polygonCoordinates, labelText, locationId, centroid) {
        const lngLat = centroid.geometry.coordinates;
        // new window.mapboxgl.Marker({ color: 'red' }).setLngLat(lngLat).addTo(mapRef.current);
        mapRef.current.addSource(`label-${locationId}`, {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: centroid.geometry.coordinates,
                },
                properties: {
                    title: labelText,
                },
            },
        });

        mapRef.current.addLayer({
            id: `label-layer-${locationId}`,
            type: 'symbol',
            source: `label-${locationId}`,
            layout: {
                'text-field': ['get', 'title'],
                'text-size': 10,
                'text-offset': [0, 0.5],
                'text-anchor': 'top',
            },
            paint: {
                'text-color': '#000000',
            },
        });
    }




  useEffect(()=>{
      var date = moment(); 
      var timeAndDate = moment(date).set({"hour": parseInt(props.workday.start_hour.split(":")[0]), "minute": parseInt(props.workday.start_hour.split(":")[1]) || 0});
    
      let dt={start:null,end:null}
      if(timeAndDate <= moment() ){        
        setDates((prev) => ({ ...prev, [`end`]:  moment(date).add(addDay,'days').toDate()})); 

      }else {  
        setDates((prev) => ({ ...prev, [`start`]:moment(date).subtract(addDay,'days').toDate()  }));
        setDates((prev) => ({ ...prev, [`end`]:  date.toDate()}));
      }
       getLocationType(userSession).then(res=>{
        if(res.status===200){

          let obj = {};
          obj.productive= res.data.find(el=>el.loc_type=="productive").id
          obj.less_productive= res.data.find(el=>el.loc_type=="less_productive").id
          obj.non_productive= res.data.find(el=>el.loc_type=="non_productive").id

          SetProductiveType(obj);
        }
      })

    },[])

    useEffect(()=>{
      
      let val= generateTimeOptions(selectedDate);
      setTimeOptions(val.utc);
      SetLocalTime(val.local);


      
      let ind = 2;
        if(val.utc[val.utc.length - ind].split(":")[2]==='30'){
          ind = 3;
        }

      if(moment().utc().format('YYYY-MM-DD') === moment(selectedDate).utc().format('YYYY-MM-DD')){
        

        SetStartTime(val.utc[val.utc.length - ind]);
        SetDisplayTime(val.local[val.local.length - ind]);
        SetPlayPosition((prev)=>{
          return val.utc.length - ind;
        });
        
      }else{

        SetStartTime(val.utc[0] ||  '00:00:00');
        SetPlayPosition(0);

      }
    },[selectedDate])

  useEffect(()=>{ 
    
    let requestBody2={};
    requestBody2.session=userSession
    requestBody2.org_id=org_id

    getCategoryList(requestBody2).then(res2=>{
        if(res2.status == 200){
          let arrCat=res2.data;
          let requestBody={}
          requestBody.session= userSession;
          requestBody.org_id= org_id;
          let arr=[];

        for(let i=0;i<res2.data.length;i++){
          if(res2.data[i].location.length > 0){
            let loc = res2.data[i];
            loc.count = res2.data[i].location.length;
            arr.push(loc);
       
          }
        }

       let outofBoundary = {name:"Out of Boundary",location:[
          {
          "id": 'outofboundary', 
          "location_name": "Out of Boundary", 
          "tag_serial": "Out of Boundary"
        }, 
       ],count:1}
       arr.push(outofBoundary)

        SetLocationList(arr); 
        SetLocationListDefault(arr)
      
        }else{
            SetLocationList([]);                
            SetLocationListDefault([])

        }
    
})
},[])  
  
  
  const timerLive = ()=>{
    SetTime(moment().format('HH:mm:ss'));
  }
  function GetRandomDigit(min=0,max=10){
   return  Math.floor(Math.random() * (max - min + 1)) + min;
  }  




      function datetime(year, month, date, hour=0,minute=0,second=0,millisecond=0){
        return 0
      }

 

useEffect(() => {
  
  if(locationCordinates.length === 0 || permission.length == 0 || startTime === "" || !autoplay || live) {

    return;
  
  }else{

  }

    const abortController = new AbortController();
    const { signal } = abortController;

    let start = '';
    let end = moment(selectedDate).format('YYYY-MM-DD 23:59:59');

    if (moment(selectedDate).utc().format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      start = moment(selectedDate).utc().format('YYYY-MM-DD');
      end = '';
    } else {
      start = moment(selectedDate).set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD');
      end = moment(selectedDate).format('YYYY-MM-DD 23:59:59');
    }

  
    let st = startTime.split(":");
    let stime = st[0] + ":" + st[1] + ':00';
    let endTime = timeOptions[timeOptions.length - 1]

    
    const eventSource = new EventSource(`${prefixURL}/recording_data?session=${userDetails.session}&org_id=${userDetails.org_id}&start_time=${startTime}&end_time=${endTime}&sub_org_id=${userDetails.sub_org_id ? userDetails.sub_org_id : 0}`, { signal });

    eventSource.onmessage = async (res) => {
      let newData = JSON.parse(res.data).map(el => ({ ...el, tag_serial: el.tag_id }));
    
      let data=[];
      for(let i=0;i<newData.length;i++){
        let single = employeeALL.find(el=>newData[i].tag_id == el.tag_serial )
         if(single){data.push(newData[i])}else{console.log("data skipped",newData[i].tag_id)};
      }
      dataSocket = data;

      if (layerPublish !== 1) {
        if(moment(selectedDate).utc().format('YYYY-MM-DD') === moment().utc().format('YYYY-MM-DD') ){

          if(data.length > 0){
            
              setTimeOptions((tOption)=>{
                var originalTime = tOption[tOption.length - 1];
                var date = new Date('2023-01-01 ' + originalTime);
                date.setSeconds(date.getSeconds() + 30);
                var updatedTime = date.toLocaleTimeString('en-US', { hour12: false });
              
                var utcDate = moment.utc('2023-01-01 ' + originalTime, 'YYYY-MM-DD HH:mm:ss');
                var localDate = moment(utcDate).local();
                let index = tOption.indexOf(moment(data[0].contact_time).subtract(7,'hours').format('HH:mm:ss'));
                if(index > -1){
                  SetPlayPosition(index);
                  return tOption
                }else{
                                    
                  SetLocalTime((prev)=>[...prev,localDate.add(30, 'seconds').format('HH:mm:ss')]); 
                  SetPlayPosition((prev)=>prev + 1);
                  return ([...tOption,updatedTime])
                }
              })

              
          }

        }else{
          SetPlayPosition((prev) => prev + 1);
        }
      }

      
    processFacilityMap(data,locationCordinates);


    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
     
    };

    return () => {
      abortController.abort();

      eventSource.close();
    };

}, [permission, locationCordinates,timeOptions, selectedDate, startTime, autoplay,employeeALL,productiveType,live]);

function processFacilityMap(data,locationCordinates){

        if (data.length > 0) {
            console.log("if data",data,dataSocket,employeeALL)
        layerPublish++;

  if (activePopupRef.current) {
                activePopupRef.current.remove();
                activePopupRef.current = null; // Clear the reference
            }

        markersRef.current.forEach(marker => marker.remove());
        markersRef.current = [];
        SetDelayTimer(false);
        SetCurrentData(data);
        SetNextUpdate(30);
        SetLIveAPI(true);
        let productive=[]
        let lessproductive=[]
        let nonproductive=[]
        let defaultProducive= []

        for(let i=0;i<dataSocket.length;i++){

          let mm = [...employeeALL].find(item=>item.tag_serial == dataSocket[i].tag_id);
          
          if(mm){
          let formatAr={}
            console.log("data mapping for location ",dataSocket[i],locationCordinates)
          let pos = locationCordinates.find(el=>el.location_serial === dataSocket[i].loc_id);

          
          if(!pos){
          formatAr.name = mm && mm.name?mm.name+" ("+dataSocket[i].tag_id+")": data.tag_id
          let location = {};
          formatAr.id= randomString(32);
          formatAr.tag_serial =dataSocket[i].tag_serial;          
        
          
          formatAr.time= moment().unix(); 
          formatAr.tag_serial= "";
          formatAr.loc_id= "";
          formatAr.position=[dataSocket[i].x,dataSocket[i].z]       

          console.log("formatAr live",formatAr);
          if(parseInt(dataSocket[i].productivity) === productiveType['productive']){
            productive.push(formatAr);
            console.log("in Productive",formatAr);

          }else if(parseInt(dataSocket[i].productivity)=== productiveType['non_productive']){
              nonproductive.push(formatAr);
                          console.log("in nonProductive",formatAr);
          }else if(parseInt(dataSocket[i].productivity)=== productiveType['less_productive']){
            lessproductive.push(formatAr);
                        console.log("in lessProductive",formatAr);
          }else{
                    console.log("in deafultProductive",formatAr);
              defaultProducive.push(formatAr)
          }
            

          }else{
          formatAr.name = mm && mm.name?mm.name+" ("+dataSocket[i].tag_id+")": dataSocket[i].tag_id
          let location = pos;
          formatAr.id= randomString(32);
          formatAr.tag_serial =dataSocket[i].tag_serial;          
        
          
          formatAr.time= moment().unix(); 
          formatAr.tag_serial= location.tag_serial;
          formatAr.loc_id= location.tag_serial;
          formatAr.position=[dataSocket[i].x,dataSocket[i].z]       

          console.log("formatAr live",formatAr);
          if(parseInt(dataSocket[i].productivity) === productiveType['productive']){
            productive.push(formatAr);
            console.log("in Productive",formatAr);

          }else if(parseInt(dataSocket[i].productivity)=== productiveType['non_productive']){
              nonproductive.push(formatAr);
                          console.log("in nonProductive",formatAr);
          }else if(parseInt(dataSocket[i].productivity)=== productiveType['less_productive']){
            lessproductive.push(formatAr);
                        console.log("in lessProductive",formatAr);
          }else{
                    console.log("in deafultProductive",formatAr);
              defaultProducive.push(formatAr)
          }
          
          }

        }else{
          console.log("employee skipped",dataSocket[i].tag_id)
        }


        }
        console.log("productiveType",productiveType);
        console.log("lessproductive",lessproductive);
        console.log("productive",productive);
        console.log("nonproductive",nonproductive);
        console.log("defaultProducive",defaultProducive);

        addPointDeafult(lessproductive,ManLessProductiveIcon)
        addPointDeafult(productive,ManProductiveIcon)
        addPointDeafult(nonproductive,ManNonProductiveIcon);
        addPointDeafult(defaultProducive,DefaultManIcon)
        
      }else{
        console.log("else data",data)
      }
}

 function addPointDeafult(points,icon){
                points.forEach(point => {


                  const el = document.createElement('div');
                    const width = 15;
                    const height =25;
                    el.className = 'marker';
                    el.style.backgroundImage = `url(${icon})`;
                    el.style.width = `${width}px`;
                    el.style.height = `${height}px`;
                    el.style.backgroundSize = '100%';
                    // new window.mapboxgl.Marker(el).setLngLat(point).addTo(mapRef.current);

                const popup = new window.mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false
                }).setText(`${point.name}`);

                const marker = new window.mapboxgl.Marker(el)
                    .setLngLat(point.position)
                    .setPopup(new window.mapboxgl.Popup().setText(`${point.name}`)) // Optional popup
                    .addTo(mapRef.current);
                marker.getElement().addEventListener('mouseenter', () => {
                    popup.setLngLat(point.position).addTo(mapRef.current);
                    activePopupRef.current = popup;
                });
                marker.getElement().addEventListener('mouseleave', () => {
                  
                    popup.remove();

                });

                    
                    
                markersRef.current.push(marker);
            });
  }


    useEffect(() => {
      let pagePerm= props.pages.filter(el=>el.page_name=="Facility Mapbox")
      
      
      SetPermission(pagePerm);
      SetPermissionAPICall(true)
      
    },[props.pages]);

  function AccordionHandler(val,type='secondary'){
    let ActivList = [...activeAccord];
    let index = ActivList.indexOf(val)
    if(index !== -1){  
        ActivList.splice(index, 1);
        SetActiveAccord(ActivList);      
    }
    else if(type=='primary'){
        SetActiveAccord([val]);
 }     
    else {  
        ActivList.push(val);
        SetActiveAccord(ActivList);      
    }
    
}

  useEffect(()=>{
    if(permission.length > 0){
      let geoLocation=[];
      let requestBody= {}
      requestBody.session = userSession;
      requestBody.org_id= org_id;

      getDepartment(userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){
          SetDeparment(res.data);  
        }
      });
      
      SetLoaderEmp(true);
      
    }else{
    }

  },[permission])   


useEffect(()=>{
    if(permission.length > 0){

   let requestBody={}
    requestBody.start_date= moment(selectedDate).subtract(addDay,'days').format('YYYY-MM-DD')
    requestBody.end_date=   moment(selectedDate).format('YYYY-MM-DD')
    
    requestBody.slug= 'employee';
    let cat=[];
    let catDaily=[];
    let empList= [];
    let dept=[];
          
     SetLoaderEmp(true);


     getOnBoardEmployee(requestBody,userSession,org_id,sub_org_id).then(res=>{
      
      if(res.status==200){
        if(res.data.daily){
      
        for(let i=0;i<res.data.daily.length;i++){
            res.data.daily[i].emp_name= res.data.daily[i].name;
            res.data.daily[i].department= res.data.daily[i].department_name;
            res.data.daily[i].dept_name= res.data.daily[i].department_name;
            res.data.daily[i].sub_dept_name= res.data.daily[i].sub_department_name;
            res.data.daily[i].sub_department= res.data.daily[i].sub_department_name;          
            res.data.daily[i].emp_name= res.data.daily[i].name;
            res.data.daily[i].emp_type= "daily_worker";
            empList.push(res.data.daily[i]);
            let indexDept = dept.findIndex(el=>el.name === res.data.daily[i].department_name);
            
            if(indexDept > -1){

              let subDept = dept[indexDept].sub_department.find(el=>el === res.data.daily[i].sub_department_name);
              
              if(!subDept){
                dept[indexDept].sub_department.push(res.data.daily[i].sub_department_name);
              } 
            }else{
              dept.push({name:res.data.daily[i].department_name,sub_department:[res.data.daily[i].sub_department_name]})
            }



        }

        }

                if(res.data.regular){

          for(let i=0;i<res.data.regular.length;i++){
            res.data.regular[i].emp_name= res.data.regular[i].name;
            res.data.regular[i].department= res.data.regular[i].department_name;
            res.data.regular[i].dept_name= res.data.regular[i].department_name;
            res.data.regular[i].sub_dept_name= res.data.regular[i].sub_department_name;
            res.data.regular[i].sub_department= res.data.regular[i].sub_department_name;          
            res.data.regular[i].emp_name= res.data.regular[i].name;
            res.data.regular[i].emp_type= "regular";
            empList.push(res.data.regular[i]);

             let indexDept = dept.findIndex(el=>el.name === res.data.regular[i].department_name);
            
            if(indexDept > -1){
              let subDept = dept[indexDept].sub_department.find(el=>el === res.data.regular[i].sub_department_name);
              if(!subDept){
                dept[indexDept].sub_department.push(res.data.regular[i].sub_department_name);
              } 
            }else{
              dept.push({name:res.data.regular[i].department_name,sub_department:[res.data.regular[i].sub_department_name]})
            }
        }
      }

      empList = empList.sort((a, b) => new Date(b.check_in) - new Date(a.check_in));
      
      
      getActiveEmployeeV2(userSession,org_id,moment(selectedDate).subtract(1,'days').format('YYYY-MM-DD')).then(res2=>{
         
         if(res2.status===200){
          res2.data= res2.data.map(el=>({...el,department:el.department_name,sub_department:el.sub_department_name}))
          for(let i= 0;i<res2.data.length;i++){
            if(empList.findIndex(el=> el.worker_id===res2.data[i].worker_id) === -1){
              empList.push(res2.data[i])
            }
          }

         }
        SetEmployeeAll(empList);
        SetDeparment(dept);
        SetEmployeeDefault(empList)
        SetLoaderEmp(false);
      
      })
     
        
      }
     })

    }
},[permission,selectedDate])

  useEffect(()=>{
  SetActiveDepartment([]);
  let type= slug==''?'':slug=='employee'?'regular':'daily_worker';
  let emp = employeeDefault.filter(item=>item.emp_type.toString().includes(type)); 
    SetEmployeeAll(emp);   
  },[slug])



useEffect(()=>{
  SetActiveSubDepartment([]);
  SetSubDeparment([]);     
  if(activeDepartment.length== 1){
    let sub = department.find(el=>el.name===activeDepartment[0])
    SetSubDeparment(sub.sub_department); 
}
},[activeDepartment])

function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}


useEffect(()=>{

  SetAPICall(false)
  removeFrame()

},[employeeALL])



  function isInt(value) {
    return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
  }



  useEffect(()=>{
      if(permission.length > 0){
        SetTime(moment().format('HH:mm'));

    }

  

    },[permission])
    
    function randomString(length) {
        let chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) {
            if(i==24){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==16){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==12){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }
            if(i==8){
              result += chars[Math.floor(Math.random() * chars.length)]+"-";
            }else{
              result += chars[Math.floor(Math.random() * chars.length)];
            }
          
        }
        return result;
      }  
    function handleDateSelect(date) {
      setDates((prev)=>({...prev,['start']:date}))
    updateSelectedDate(date);

    layerPublish++;


    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));
   
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  const removeFrame = () => {
    
    let data=[...dataSocket];

      if(data.length >0){
          for(let i =0; i < data.length; i++){
              let dt = data[i];
            
              
              dataSocket.splice(i,1)
          }
          
         }
        dataSocket=[];

  }
  
  function updateDataLayer(data,url){

   

  }


  function AddDataLayer(data){
   
}

useEffect(() => {
  
  let cinc=0;

  const id3 = setInterval(timerLive, 1000);

  const id4 =setInterval(function(){
      SetNextUpdate((prev)=>{
          let val=prev
          if(prev == 0){
            cinc++;
            val= 0;
          }else{
            cinc++;
            val = prev - 1
          }
          return val;
        })
  },1000)
  

return () =>{
  clearInterval(id3);
  clearInterval(id4);
  }   
},[employeeALL,selectedDate,playPosition]);

useEffect(()=>{
  
    const tOpt = setInterval( function(){
      pushLastSecond(timeOptions,selectedDate)
  },30000);
return () =>{
  clearInterval(tOpt)
  } 
},[timeOptions])


function pushLastSecond(timeOptions,selectedDate){
      if(moment(selectedDate).utc().format('YYYY-MM-DD') === moment().utc().format('YYYY-MM-DD') ){
          let tOption= [...timeOptions];
        
          var originalTime = tOption[tOption.length - 1];
          var date = new Date('2023-01-01 ' + originalTime);
          date.setSeconds(date.getSeconds() + 30);
          var updatedTime = date.toLocaleTimeString('en-US', { hour12: false });
          tOption.push(updatedTime)
          
          var utcDate = moment.utc('2023-01-01 ' + originalTime, 'YYYY-MM-DD HH:mm:ss');
          var localDate = moment(utcDate).local();
        
        }
}

    
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }


    function ModalFormatData(hourData,tag_serial){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea' key={"ModalFormatData"+tag_serial.toLowerCase()}> 
         <h4>{tag_serial.toLowerCase()} {displayTime}  <button className='alignRightFixed' onClick={()=>{SetModalView(false);SetAutoPlay(true)}}>x</button></h4>
        </div>
      )

      let inner =[];      
      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        let emp= employeeDefault.find(item=>item.tag_serial == hourData[i].tag_id );
        if(emp){
          inner.push(emp)   
        }
      
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
    }

    function ModalFormatcategory(hourData,categoryname){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea' key={"ModalFormatcategory"+categoryname.toLowerCase()}> 
         <h4>{categoryname.toLowerCase()}: {displayTime} <button className='alignRightFixed' onClick={()=>{SetModalView(false);SetAutoPlay(true)}}>x</button></h4>
        </div>
      )

      let inner =[];   
       
      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        
        let emp= [...employeeDefault].find(item=>item.tag_serial == hourData[i].tag_id );

        if(emp){inner.push(emp)  } 
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      

    }


    function ModalFormatLocation(hourData){
    
      let data = [];

      let arr =[];

      arr.push(
        <div className='titleArea' key={"ModalFormatLocationTitle"}> 
         <h4>Total employees: {displayTime}<button className='alignRightFixed' onClick={()=>{SetModalView(false);SetAutoPlay(true)}}>x</button></h4>
        </div>
      )

      let inner =[];   

      for(let i=0;i<hourData.length;i++){
        if(i==1){

        }
        let emp= employeeDefault.find(item=>item.tag_serial == hourData[i].tag_id );

      if(emp){inner.push(emp)  } 
      }
      inner = inner.map((item,index)=> {item.sno = index +1; return item })
      arr.push(AGTable(inner))
      return arr;
      

    }

    function onChangeDepartment(val){
    let nameVal=[]
      dataSocket=[]
      if(val.indexOf('all')> -1){
          let value =[];

          for(let i=0;i<department.length;i++){
            value.push(department[i].name)
            nameVal.push(department[i].name)
          }
          if((val.length -1) == value.length ){
            SetActiveDepartment([]);  
            SetDepartmentName([])
          }else{
            SetDepartmentName(nameVal)
            SetActiveDepartment(value);
            
          }
    
          
      }else{
        
        
        SetDepartmentName(val)
        SetActiveDepartment(val);
      }


    }
        function onChangeSubDepartment(val){
      
        dataSocket=[]
    let nameVal=[]
      if(val.indexOf('all')> -1){
        let value =[];
        for(let i=0;i<sub_department.length;i++){
          value.push(sub_department[i].name)
          nameVal.push(sub_department[i].name)
        }
        if((val.length -1) == value.length ){
        
          SetSubDepartmentName([])                    
          SetActiveSubDepartment([]);  
        }else{
        
          SetSubDepartmentName(nameVal)
          SetActiveSubDepartment(value);

        }

    
        
    }else{
      

      
       SetSubDepartmentName(val)
      SetActiveSubDepartment(val);
    }

    }
    
    function AGGridEMptyMEssage(key){
      let msg;
          msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
      return msg
    }
    
    function viewIconRenderer(params){
      var eGui = document.createElement('div');
      eGui.innerHTML = '<span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg></span>';
       return eGui;
    }

    function colsValueFormatter(params){
      var eGui = document.createElement('div');
        
      eGui.innerHTML = '<div><div class="titleFont">'+params.data.emp_name+'</div> <span class="smallerfont smallerfontDepartment">'+params.data.dept_name+'</span> <span class="smallerfont">'+params.data.sub_dept_name+'</span> </div>';
                     return eGui;          
      
    }

    function AGTable(data){

    
      if(data  && window.innerWidth > 767){
        
        let widthCal= 0;
    
        if( ((window.innerWidth * 80)/100) > 1100){
          widthCal=((1100 - 205)/4) ;
        }else{
          widthCal=(( (window.innerWidth * 80)/100) - 205)/4 ;
        }
        let arr;

        arr=<div className={"keyAGTable"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={35}
              autoGroupColumnDef={{
                  headerName: 'Name',minWidth: 200,field: 'emp_name',
      
              }}
              headerHeight={35}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
             
              rowData={data}
              key={'keyAGTable'}
                onCellClicked={ (event) =>
              {
               
                if(event.data.emp_type == 'daily_worker'){
                  window.open(`/productivity/daily_worker/:${event.data.worker_id}/${getDateFormat(selectedDate)}`, "_blank")
                }else{
                  window.open(`/productivity/employee/:${event.data.worker_id}/${getDateFormat(selectedDate)}`, "_blank")
                }
              }
                }

              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
            
          <AgGridColumn
              field="sno"
              headerName={"S.No"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
  
              width={75}
          />
            <AgGridColumn
              field="emp_name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
                      <AgGridColumn
              field="worker_id"
              headerName={"Emp ID"}
              valueFormatter={(params)=>params.value.toLowerCase()}
              cellClass={'textCapitalize textCap'}
          />
          <AgGridColumn 
              field="dept_name" 
              headerName="Department" 
              valueFormatter={(params) =>params.value.toLowerCase()}    
              cellClass={'textCapitalize textCap'}
             >
          </AgGridColumn> 
           <AgGridColumn
             field="sub_dept_name"
             headerName="Sub Department"
             cellClass={'textCapitalize textCap'}
             valueFormatter={(params) =>params.value!=''?params.value.toLowerCase():'-'}    headerComponentParams =  {{
          }} ></AgGridColumn> 
          <AgGridColumn
             field="sub_dept_name"
             headerName="View"
             width={80}
             cellClass={'textCapitalize textCap'}
             cellRenderer= {(params)=>viewIconRenderer(params)} >
            </AgGridColumn> 
      </AgGridReact>
      </div></div>
      
      return arr
    }else if(data  && window.innerWidth < 767){
      let widthCal= 0;
    
        widthCal=window.innerWidth - 200/1 ;
      let arr;

      arr=<div className={"keyAGTable keyAGTableResponsiveBiggerHeight"}><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + ((data.length > 0? data.length:1)*36),maxHeight:500 , width: "calc(100% - 1px)"}}>
        <AgGridReact
            rowHeight={45}
            autoGroupColumnDef={{
                headerName: 'Name',minWidth: 200,field: 'name',
    
            }}
  
         defaultColDef={{sortable: true,resizable: true,minWidth:45, width:widthCal , suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
           
            rowData={data}
            key={'keyAGTable'}
            onCellClicked={ (event) =>
              {
            
                if(event.data.emp_type == 'daily_worker'){
                window.open(`/productivity/daily_worker/:${event.data.worker_id}/${getDateFormat(selectedDate)}`, "_blank")
                }else{
                window.open(`/productivity/employee/:${event.data.worker_id}/${getDateFormat(selectedDate)}`, "_blank")

                }
              }
                }
            overlayNoRowsTemplate={
                AGGridEMptyMEssage('')
              }        
         >
          <AgGridColumn
            field="emp_name"
            headerName={"Name"}
            valueFormatter={(params)=>params.value.toLowerCase()}
            cellRenderer= {(params)=>colsValueFormatter(params)}
            cellClass={'textCapitalize textCap'}
        />
        <AgGridColumn
           field="sub_dept_name"
           headerName="View"
           width={80}
           cellClass={'textCapitalize textCap'}
           cellRenderer= {(params)=>viewIconRenderer(params)} >
          </AgGridColumn> 
    </AgGridReact>
    </div></div>
    
    return arr
    }else{
      return ;
    }
    
    }

    function modalViewShow(loc,playPosition){

      let data = currentData.filter(item=>item.loc_id==loc.tag_serial);
      let arr =[];
      arr = ModalFormatData(data,loc.location_name);
     SetAutoPlay(false);
     SetStartTime(timeOptions[playPosition]);
     SetDisplayTime(localTime[playPosition]);
     SetModalData(arr);
     SetModalView(true)
     
    }

    function categorymodalview(Category_data,playPosition){
     SetAutoPlay(false);
     SetStartTime(timeOptions[playPosition]);
      let arr =[];
      for(let i=0;i<Category_data.location.length;i++){
        
        let data = [...currentData].filter(item=>item.loc_id == Category_data.location[i].tag_serial);
       
        for(let j=0;j<data.length;j++){
          arr = [...arr, data[j]]
        } 
      }
      let arr1=[]
     arr1 = ModalFormatcategory(arr,Category_data.name);  
     SetDisplayTime(localTime[playPosition]);
       SetModalData(arr1);
       SetModalView(true)
    }


    function categorymodalview1(location,playPosition) {
    SetAutoPlay(false);
     SetStartTime(timeOptions[playPosition]);
      const arr = location.reduce((acc, loc) => {
        console.log("loc categorymodalview1",loc,currentData)
        const data = currentData.filter(item => item.contact_tag_id === loc.location_serial);
        return [...acc, ...data];
      }, []);
    
      const arr1 = ModalFormatLocation(arr);
      SetDisplayTime(localTime[playPosition]);
      SetModalData(arr1);
      SetModalView(true);
    }

     function locNameRender(params){
  
      var op =document.createElement('div');

      var eGui = document.createElement('div');
    
      eGui.innerHTML= '<div class="elementCell textCap">'+params.value.toLowerCase()+'</div>';

return eGui;

          
  }

  function ShowCardListLocation(){
    let data =[...locationList];   
    let arr=[];

    if(data.length>0){
      for (let i = 0; i < data.length; i++) {
      
        let index = i + 1;
       
              const plannedCount = data[i].location.reduce((acc, loc) => {

                 const locData = locationCordinates.find(
                  (cord) => cord.location_serial === loc.tag_serial
                );

                const planned = locData && locData.planned  && locData.planned!='-'? parseInt(locData.planned) : 0;
                return acc + planned;
              }, 0);

              const validPlannedCount = isNaN(plannedCount) ? 0 : plannedCount;

              arr.push(                 
          
                <Row key={"contentCategory" + data[i].name.toLowerCase()}className={`contentGroup${activeAccord.indexOf(data[i].name) !== -1 ? ' active' : ''}${data[i].location && data[i].location.length > 0 ? ' contentCategory' : ''}`}>
                  <div className={`Category-header group-header`}> 
                            <Col lg={3} className={`align-middle align-wrapper`}>
           
            {data[i].location && data[i].location.length > 0 && (
              <span onClick={() => AccordionHandler(data[i].name, 'primary')}>
                {activeAccord.indexOf(data[i].name) !== -1 ? (
                  <CircleArrowDown style={{ height: 15 }} />
                ) : (
                  <CircleArrow style={{ height: 15}} />
                )}
              </span>
            )}
           
            {data[i].location && data[i].location.length > 0 && (
              <h4 style={{ fontSize: "12px",color:"#908fab", margin: 0, marginLeft: 8 }}>
                {data[i].name.charAt(0).toUpperCase() + data[i].name.slice(1)}
              </h4>
            )}
          </Col>


              <Col lg={2} className="align-middle" style={{  fontSize: "9px",marginRight:30 }}>
              <span style={{ fontSize: "10px",color:"#908fab"}}>{data[i].location.length > 0 && validPlannedCount}</span>
            </Col>
          <Col lg={2} className="align-middle" style={{ textAlign: "center", fontSize: "10px",color:"#908fab" }}>
        {data[i].location.length > 0 && (
          <>
            <span style={{ fontSize: "10px", fontWeight: "bold" }}></span>
            {data[i].location.reduce((acc, loc) => {
              
              // const actual = currentData.filter(el=>el.loc_id === loc.tag_serial).length;
              const actual = currentData.filter((el) => el.contact_tag_id === loc.tag_serial).length;

              return acc + actual;
            }, 0)}
          </>
        )}
      </Col>

            <Col lg={3} className="align-middle" style={{textAlign:"center",fontSize:"10px"}}>
            {data[i].location.length > 0 ? (
                data[i].location.reduce((acc, loc) => {
                  
                  const actual = currentData.filter(el=>el.contact_tag_id === loc.tag_serial).length;
                  return acc + actual;
                }, 0) > 0 ? (
                  <React.Fragment key={data[i].id}>
                    <div className="legendRightAreaProd" onClick={() => categorymodalview(data[i],playPosition)}>
                      <Eye style={{ height: 13, cursor: "pointer" }} />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={data[i].id}>
                    <div className="legendRightAreaProd disabled nonCLickable">
                      <Eye style={{ height: 13 }} />
                    </div>
                  </React.Fragment>
                )
              ) : null
            }

    </Col>
            </div>
            <div  className={activeAccord.indexOf(data[i].name)!==-1 ?'Category-content active':'Category-content'}>
              <div className="ag-theme-alpine" style={{  width: '100%'}}>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    {/* Header cells */}
                  </tr>
                </thead>
                <tbody>
                  {data[i].location.map((loc) => {
                  const locData = locationCordinates.find(
                    (cord) => cord.tag_serial === loc.tag_serial && cord.name === loc.location_name
                  );
                  const planned = locData?.planned || "0";
                    const actual = currentData.filter(el=>el.loc_id === loc.tag_serial).length;
                    const eye =
                      currentData.filter(el=>el.loc_id == loc.tag_serial).length > 0 ? (
                        <div className="legendRightAreaProd" onClick={() => modalViewShow(loc,playPosition)}>
                          <Eye style={{ height: 13, cursor: "pointer",marginLeft:23}} />
                        </div>
                      ) : (
                        <div className="legendRightAreaProd disabled nonCLickable">
                          <Eye style={{ height: 13 ,marginLeft:23}} />
                        </div>
                      );
                    return (
                     <tr key={loc.tag_serial} style={{}}>
                      <td className="locationName">{loc.location_name}</td>
                      <td style={{ padding: 3, fontSize: "10px", color: planned <= actual ? "black" : "grey", width: "20%", textAlign: "right", }}>
                        {planned}
                      </td>
                      <td style={{ padding: 3, fontSize: "10px", color: actual <= planned ? "black" : "grey", width: "20%", textAlign: "right" }}>
                        {actual}
                      </td>
                      <td style={{ width: "30%"}}>{eye}</td>
                    </tr>
                    );
                  })}
                </tbody>
              </table>
              </div>                 
            </div>
          </Row>
        )
      }
    }
    return arr;
  }
  

const generateTimeOptions = (date) => {
  const utcTimeOptions = [];
  const localTimeOptions = [];
  
  const currentDate = moment().utc();
  const inputDate = moment(date).utc();
  let workday = props.workday
  let start = props.workday.start_hour.split(":")
  let end = props.workday.end_hour.split(":")
  
  let subtract =0;
  if(props.workday.same_day	 ==="no"){
    subtract =1;
  }

  let startOfDay = moment(date).subtract(1).set({hour:start[0],minute:start[1],second:start[3]}).utc();
  let endOfDay = moment(date).set({hour:end[0],minute:end[1],second:end[3]}).utc();

  if(startOfDay > endOfDay) {
        endOfDay = endOfDay.add(1,'day');
  }


  const currentHour = currentDate.hour();

  let endTime;

  if (inputDate.isSame(currentDate, 'day')) {
    endTime = moment(currentDate);
  } else if (inputDate.isBefore(currentDate, 'day')) {

    endTime = endOfDay;
  } else {

    endTime = endOfDay;
  }

  let currentTime = startOfDay;

  while (currentTime.isBefore(endTime) || currentTime.isSame(endTime)) {

    utcTimeOptions.push(currentTime.format('YYYY-MM-DD HH:mm:ss'));
    
    const localTime = moment.utc(currentTime).local();
    localTimeOptions.push(localTime.format('YYYY-MM-DD HH:mm:ss'));
    currentTime = currentTime.add(30, 'seconds');
  }

  return { utc: utcTimeOptions, local: localTimeOptions };
};


useEffect(()=>{
  let dateTime = timeOptions[playPosition]?timeOptions[playPosition]:timeOptions[playPosition - 1]?timeOptions[playPosition - 1]:timeOptions[timeOptions.length - 1] 
    if(moment().subtract(120,'seconds').utc() < moment.utc(dateTime,"YYYY-MM-DD HH:mm:ss")){
        SetLive(true);
    }else{
        SetLive(false);
    }
},[playPosition,timeOptions])

  const handleChange = (newValue,selectedDate,layerPublish) => {
    SetStartTime(timeOptions[newValue]);
    SetPlayPosition(newValue);
    SetDisplayTime(localTime[newValue]);
    dataSocket =[];
    SetCurrentData([]);

     if (activePopupRef.current) {
                activePopupRef.current.remove();
                activePopupRef.current = null; // Clear the reference
            }

        markersRef.current.forEach(marker => marker.remove());
        markersRef.current = [];

    layerPublish = 0;
    if(moment().subtract(60,'seconds').utc() < moment(getDateFormat(selectedDate)+" "+timeOptions[newValue])){
      // resetTime = 30
      resetTime = 5
      SetLive(true);
    }else{
      resetTime= 5
      SetLive(false);
    }
    SetNextUpdate(resetTime);
  
  };

   function filterEmployee(){
    if(departmentName.length > 1 ){
      let filterEmp = employeeDefault.filter(item => departmentName.indexOf(item.department) > -1 ); 
      dataSocket=[];
      SetEmployeeAll(filterEmp);
    }
    else if(activeSubDepartment=='all'){
      let filterEmp = employeeDefault.filter(item => departmentName.indexOf(item.department) > -1)  
      dataSocket=[];
      SetEmployeeAll(filterEmp);
    }else if(activeSubDepartment.length == 0){
    let filterEmp = employeeDefault.filter(item => departmentName.indexOf(item.department) > -1 ); 
    dataSocket=[];
    SetEmployeeAll(filterEmp);   
    }else{
      let filterEmp = employeeDefault.filter(item => subDepartmentName.indexOf(item.sub_department) > -1)  
      SetEmployeeAll(filterEmp);
    }
    SetCurrentData([])
    SetAutoPlay(true);
    removeFrame()
  }

useEffect(() => {

  if(!live || !autoplay){
    return 
  }
  let oldPosition = playPosition;
  let oldTimeOption = [...timeOptions];
  let oldlocalTimeOptions = [...localTime]

  const fetchLiveData = async () => {
    try {
      const res = await getLiveData(userSession, org_id, sub_org_id);

      if(res.status ==200){
       let newData = res.data.map(item=> JSON.parse(item.toString().replace(/'/g, '"') ));
        
      let data=[];
      for(let i=0;i<newData.length;i++){
        
        let single = employeeALL.find(el=>newData[i].tag_id == el.tag_serial )
         if(single){
          newData[i].loc_id= newData[i].contact_tag_id;
          newData[i].added_at = newData[i].contact_time;
          newData[i].tag_serial = newData[i].tag_id;
          
          data.push(newData[i])
          console.log("data pushing",data);
        }else{
        
        }
      }
          dataSocket = data;      
          if(data.length > 0){
             
              let originalTime = oldTimeOption[oldTimeOption.length - 1];
              let localTime = oldlocalTimeOptions[oldlocalTimeOptions.length - 1];
              
              
              //utc
              let date = new Date(originalTime);              
              date.setSeconds(date.getSeconds() + 5);
              let updatedTime = moment(date).format('YYYY-MM-DD HH:mm:ss');

              //local
              let localDate = new Date(localTime);
              localDate.setSeconds(localDate.getSeconds() + 5);

              oldPosition = oldPosition + 1;

              oldlocalTimeOptions.push(moment(localDate).format('YYYY-MM-DD HH:mm:ss'));
              oldTimeOption.push(updatedTime);

              SetLocalTime(oldlocalTimeOptions); 
              setTimeOptions(oldTimeOption);
              SetPlayPosition(oldPosition);
          }
          processFacilityMap(data,locationCordinates);
        }
      
    } catch (error) {
     
    }
  };

  fetchLiveData();

  const liveupdate = setInterval(function(){fetchLiveData()}, 5000);

  return () => {
    clearInterval(liveupdate);
  };
}, [live, employeeALL,locationCordinates]);


  return (<>
      {permissionAPICall?permission.length > 0? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
      {role? <Col lg={12} className="headerFontSpaceLess"> <CommonHeading title="Facility Tracker" /> </Col>:""}

        <Col lg={6} style={{display:role?'none':'block'}}>
          <CommonHeading title="Facility Map"/>     
    </Col>
        <Col lg={6} className="text-right" style={{display:role?'none':'block'}}>
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="commonHeadingDateMainDivParent">
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
                              weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
            />
          </div>
        </Col>
      </Row>

      <Row className={role?"":"m-t"} >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
            <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}>Filter</h5>
            </div>
        
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Select Department"
                optionFilterProp="children"
                onChange={(val)=>{onChangeDepartment(val)}}
                value={activeDepartment}
                key="departmentFilter"
                style={{width:250}}
                
                mode="multiple"
                dropdownClassName={'smallerDrop'}
            >
              <Option value='all' key={"Department All"}>All</Option>
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.name} key={"Department"+item.name}>{item.name}</Option>
                }):""
                }
            </Select>
            </div>
            <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:250}}
                    onChange={(val)=>{onChangeSubDepartment(val)}}
                    disabled={activeDepartment.length==0 || activeDepartment.length>1?true:false}
                    value={activeSubDepartment}
                    key="SubdepartmentFilter"
                    dropdownClassName={'smallerDrop'}
                    mode="multiple"
                >
                  <Option value='all' key={"Sub Department All"}>All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item} key={"Sub Department"+item}>{item}</Option>
                    }):""
                    }
                </Select>
            </div>

            <div className='inlineBlockFilter'>
                <button className={'button active btn-productive-filter'} onClick={()=>filterEmployee(employeeALL)} >Filter</button>
            </div>
            {<>
              {live?<div className={delayTimer?'greenLive disabledClick':'greenLive'} style={{textTransform:"capitalize",border:"0px",fontWeight:"normal",cursor:"not-allowed",pointerEvents:"none"}}>Next Update:  <strong style={{color:"#ef5e8c"}}>{nextUpdate}</strong></div>:""}
              <div className='timerLive' style={{marginBottom:"15px"}}><span className='currentDate'>{moment(selectedDate).format('DD-MM-YYYY')}</span><span className='toptime'>{localTime[playPosition]}</span></div></>}
            </div>
        </Col>
      </Row>

      <Row className="m-t">
        <Col lg={9} className='tabletfull' >

        {loaderEmp && !apiCall? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4>
                    </div>
                  </div>:""} 
                            <div className='manStyle' style={{maxWidth:"100%",margin:"0 auto"}}>



             {apiCall? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
                                
                                <>
                                            <div id="container" style={{ display: 'flex', height: "calc(100vh - 170px)", position: "relative" }}>
                <div ref={mapContainerRef} style={{ width: '100%' }} />
                {!loader ? <div className='absGreatStyle' style={{ width: widthBox }}>
                    <div onClick={() => SetWidthBox((prev) => (prev === 60 ? 480 : 60))} className='currentStyle boxStyle'>
                        <img src={currentModeImg} alt="Mode Icon" />
                        <small>{currentMode}</small>
                    </div>
                    <div className='expandMode'>
                        {mapBoxMode.map(el => (
                            <div key={el.title} className={el.title === currentMode ? ' boxStyle active' : 'boxStyle'}
                                onClick={() => changeMapStyle(el.function, el.title, el.image)}>
                                <img src={el.image} alt="Map Style Icon" />
                                <small>{el.title}</small>
                            </div>
                        ))}
                    </div>
                </div> : ""}
            </div>
                                    <div className='liveDataPlay' style={{height: "60px",background: "rgba(0, 0, 1, 0.08)",position: "relative"}}>
                                            <div className='playPauseThing' style={{background:autoplay?"#ef5e8c":"grey"}} onClick={()=>{SetAutoPlay(!autoplay);SetStartTime(timeOptions[playPosition])}  } >
                                        {autoplay?<PauseCircle/>:<PlayCircle/>}
                                      </div>
                                    <div className='liveDataRightArea'>
                                    <div className='liveDataPlayInner' style={{ position: 'relative', width: '96%',margin:"0 auto",borderRadius:"20px", height: '20px',top:"10px", backgroundColor:'rgba(71, 59, 59, 0.19)' }}>

      <Slider
        min={0}
        max={timeOptions.length - 1}
        step={1}
        value={playPosition}
        onChange={(val) => handleChange(val, selectedDate, layerPublish)}
        tipFormatter={(index) =>  moment(localTime[playPosition]).format('HH:mm:ss')}
        tooltipVisible
        tipProps={{ placement: 'top', visible: true }}
        trackStyle={{ background: 'linear-gradient(to right, #108ee9 0%, #108ee9 ' + (playPosition / (timeOptions.length - 1)) * 100 + '%, #ccc ' + (playPosition / (timeOptions.length - 1)) * 100 + '%, #ccc 100%)' }}
       
      />
            {/* <TimeSlider date={selectedDate} playPosition={playPosition} timeOptions={timeOptions} changeSlide={onChangePlotTime} SetPlayPosition={()=>SetPlayPosition} /> */}
            {/* {playPosition} */}

</div>
</div>
</div> 



                                
                                </>}
          </div>
        </Col>
        <Col lg={3} className="p-l-0 tabletfull">
          <div className="legendArea manStyle" style={{height:'100%',background:"#fff"}}>
            <div className='legendStart'>
              <div className={'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Plan Count</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Actual Count</strong></div>
                  <div className='legendLeftAreaProd legendLeftAreaProdSmall'><strong>Worker List</strong></div>
              </div>
              <div className='legendLeftAreaProd'>{ShowCardListLocation()}</div>
              <div className={'legendList legendHeader'}style={{borderBottom: "0px"}}>
              <div className="customContainer contentGroup contentCategory row" style={{borderBottom: "1px solid #ddd"}}>
                <div className="Category-header group-header">
  <div className="customLeftArea align-middle align-wrapper col-lg-3" style={{marginLeft:0}} ><strong>Total</strong></div>

  <div className="customPlanned align-middle col-lg-2" style={{fontSize:10}}>{isNaN(locationCordinates.filter(el=>el.planned!=='-').reduce((partialSum, a) => (parseInt(partialSum) +  parseInt(a.planned)) , 0))?0:locationCordinates.filter(el=>el.planned!=='-').reduce((partialSum, a) => (parseInt(partialSum) +  parseInt(a.planned)) , 0)  }</div>
  <div className="customDataLength align-middle col-lg-2" style={{fontSize:10,textAlign:'center'}}>{currentData.length}</div>
  <div className="customRightArea align-middle col-lg-3" style={{marginTop:3,textAlign:"center"}}>
    {dataSocket.length > 0 ? (
      <div className='legendRightAreaProd' onClick={() => categorymodalview1([...locationCordinates,{
  "coordinates": [],
  "id":"Out of Boundary",
  "location_serial": "Out of Boundary",
  "org_id": 36,
  "properties": {},
  "centroid": {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": [
      ]
    }
  },
  "planned": "-"
}],playPosition)} key={locationCordinates.tag_serial}>
        <Eye style={{height:13}} />
      </div>
    ) : (
      <div className='legendRightAreaProd disabled nonClickable' key="total-eye-icon">
        <Eye  style={{height:13}}/>
      </div>
    )}
  </div>
  </div>
</div>
</div>
  </div>

          </div>
        </Col>
      </Row>
    </Container>

    <ReactModal
                isOpen={modalView}
                style={customStyles}
                onRequestClose={()=>{SetModalView(false);SetAutoPlay(true)}}
                shouldCloseOnOverlayClick={false}
                
            >
                <div className={"modal-cyclecount"}>
                  {modalData}
                </div>
            </ReactModal>

  </div>:<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>
  )
}

export default FacilityMapBox